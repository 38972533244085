import {IntlShape} from "react-intl";
import {MTColumnsType} from "@utils/pagination";
import {GanttTableSchema, IntervalType, OrderType} from "@feature/commons/gantt/types";
import {customMTFilter} from "@utils/utils";
import moment from "moment";

type T = GanttTableSchema;

export const getColumns = (intl: IntlShape): MTColumnsType<T> => [
  {
    title: intl.formatMessage({id: "cod_machine"}),
    field: "cod_machine",
    type: "string"
  },
  {
    title: intl.formatMessage({id: "cod_production"}),
    field: "cod_production",
    type: "string",       // Numeric
  },
  {
    title: intl.formatMessage({id: "cod_type_interval"}),
    field: "cod_type_interval",
    lookup: Object.entries(
      IntervalType
    ).reduce(
      (acc, [key, value]) => ({...acc, [key]: intl.formatMessage({id: value})}), {}
    )
  },
  {
    title: intl.formatMessage({id: "start"}),
    field: "tms_start",
    type: "string",
    cellStyle: {minWidth: 200},
    filterCellStyle: {minWidth: 200},
    // filterComponent: (props) => <CustomFilterDatePicker {...props} showTimePicker/>,
    render: (rowData: T) => moment(rowData.tms_start).format('DD/MM/YYYY HH:mm'),
    customFilterAndSearch: (term, rowData) => {
      return moment(rowData.tms_start).format('DD/MM/YYYY HH:mm').includes(term);
    },
  },
  {
    title: intl.formatMessage({id: "end"}),
    field: "tms_end",
    type: "string",
    cellStyle: {minWidth: 200},
    filterCellStyle: {minWidth: 200},
    // filterComponent: (props) => <CustomFilterDatePicker {...props} showTimePicker/>,
    render: (rowData: T) => moment(rowData.tms_end).format('DD/MM/YYYY HH:mm'),
    customFilterAndSearch: (term, rowData) => {
      return moment(rowData.tms_end).format('DD/MM/YYYY HH:mm').includes(term);
    },
  },
  {
    title: intl.formatMessage({id: "cod_order"}),
    field: "cod_order",
  },
  {
    title: intl.formatMessage({id: "cod_customer"}),
    field: "cod_customer",
    render: (rowData: T) => [rowData.cod_customer, rowData.des_customer].filter(x => x).join(" - "),
    customFilterAndSearch: (filterValue, rowData) => customMTFilter(filterValue, rowData.des_customer, rowData.cod_customer),
  },
  {
    title: intl.formatMessage({id: "cod_article"}),
    field: "cod_item",
  },
  {
    title: intl.formatMessage({id: "dat_deadline"}),
    field: "dat_deadline",
    type: "string",
    cellStyle: {minWidth: 200},
    filterCellStyle: {minWidth: 200},
    // filterComponent: (props) => <CustomFilterDatePicker {...props} showTimePicker/>,
    render: (rowData: T) => rowData.dat_deadline
      ? moment(rowData.dat_deadline).format('DD/MM/YYYY')
      : null,
    customFilterAndSearch: (term, rowData) => {
      if (!rowData.dat_deadline) return false;
      return moment(rowData.dat_deadline).format('DD/MM/YYYY HH:mm').includes(term);
    },
  },
  {
    title: intl.formatMessage({id: "order_type"}),
    field: "cod_order_type",
    lookup: Object.entries(OrderType).reduce((acc, [key, value]) => ({...acc, [key]: value.replace("_", " ")}), {})
  },
  {
    title: intl.formatMessage({id: "equipment"}),
    field: "cod_equipment",
    customFilterAndSearch: (filterValue, rowData) => customMTFilter(filterValue, rowData.des_equipment, rowData.cod_equipment),
    render: (rowData: T) => [rowData.cod_equipment, rowData.des_equipment].filter(x => x).join(" - ")
  },
  {
    title: intl.formatMessage({id: "poses"}),
    field: "val_pose",
    type: "string",       // Numeric
  },
  {
    title: intl.formatMessage({id: "raw_material_code"}),
    field: "cod_materia_prima",
  },
  {
    title: intl.formatMessage({id: "raw_material_arrival_date"}),
    field: "dat_arrivo_materia_prima",
    type: "string",
    cellStyle: {minWidth: 200},
    filterCellStyle: {minWidth: 200},
    // filterComponent: (props) => <CustomFilterDatePicker {...props} showTimePicker/>,
    render: (rowData: T) => rowData.dat_arrivo_materia_prima
      ? moment(rowData.dat_arrivo_materia_prima).format('DD/MM/YYYY')
      : null,
    customFilterAndSearch: (term, rowData) => {
      if (!rowData.dat_arrivo_materia_prima) return false;
      return moment(rowData.dat_arrivo_materia_prima).format('DD/MM/YYYY HH:mm').includes(term);
    },
  },
  {
    title: intl.formatMessage({id: "quantity"}),
    field: "val_qty",
    type: "string",       // Numeric
  }
]