import React from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MenuLink = ({ name, href, Icon , clickable=true, setClickable , onTouchEnd= ()=>{}}) => {
  const { pathname } = useLocation();
  const active = pathname.indexOf(href) >= 0;

  return (
  <>
  { clickable ? (
    <Link
      key={name}
      to={href}
      className={classNames(
        active
          ? "bg-white text-black"
          : "text-white hover:bg-gray-100 hover:text-black",
        "group flex items-center px-2 py-2 text-base font-medium rounded-3xl"
      )}
      onTouchEnd={() => onTouchEnd()}
    >
      <Icon
        className={classNames(
          active ? "text-black" : "text-white group-hover:text-gray-500",
          "mr-4 h-6 w-6"
        )}
        aria-hidden="true"
      />
      <FormattedMessage id={name} />
    </Link> ) : (
      <div className={classNames( active && " bg-gray-100 hover:text-black",
        "group flex items-center px-2 py-2 text-base font-medium rounded-3xl hover:bg-gray-100")}>
        <button onClick={() => setClickable()}>
            <Icon
              className={classNames( active ? "bg-gray-50 text-black group-hover:text-gray-500" : "text-white group-hover:text-gray-500",
              "-ml-1 mr-4 h-6 w-6")}
              aria-hidden="true"
            />
        </button>
      </div>
    )}
    </>
  );
};

export default MenuLink;
