import {Dispatch, SetStateAction} from "react";
import {GanttBlocksType} from "@feature/commons/gantt/types";

export enum ManagePlanningVisualizations {
	new_planning = 'new_planning',
	scenarios = 'scenarios'
}

export enum RunDetailsVisualizationsPlan {
	plan_details = "plan_details",
	orders_not_planned = "orders_not_planned",
	heatmap = "heatmap"
}

export interface PlanDetailsGanttProps {
	open?: GanttBlocksType
	setOpen: Dispatch<SetStateAction<GanttBlocksType | undefined>>
}