import {useContext, useEffect, useState} from "react";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";
import {useAsyncError} from "@hooks/useAsyncError";
import {NotificationsContext} from "@ui-components/Notifications";
import {color} from "@amcharts/amcharts5";
import {colorBlindFriendlyPalette} from "@theme/themes";
import {GanttBlockSchema, GanttBlocksType, GanttPayloadSchema, IntervalType} from "@feature/commons/gantt/types";
import {EltType} from "@feature/commons/types";

type T = GanttBlockSchema[];
export const useFetchGanttData = (
  runId: number,
  eltType: EltType,
  payload: GanttPayloadSchema,
  refetch: boolean = false
) => {

  // context
  const {push} = useContext(NotificationsContext);

  // hooks
  const intl = useIntl();
  const throwError = useAsyncError();

  // state
  const [loading, setLoading] = useState<boolean>(false);
  const [blocks, setBlocks] = useState<GanttBlocksType[]>();

  useEffect(() => {
      (async () => {
        if (payload.dateLimitsFirstRender) return;
        else {
          setLoading(true);
          try {
            const {data} = await api.post<T>(`/gantt_blocks/${runId}/${eltType}/gantt_data`, payload);
            setBlocks(
              data.map(
                el => (
                  {
                    ...el,
                    des_block: el.cod_type_interval === IntervalType.production ? el.des_block : intl.formatMessage({id: el.des_block}),
                    columnSettings: {
                      fill: color(colorBlindFriendlyPalette[el.id_color]),
                      stroke: color("rgb(255, 255, 255)")
                    }
                  }
                )
              )
            )
          } catch (e) {
            push({type: 'error', title: intl.formatMessage({id: 'generic_error'})});
            throwError(e);
          } finally {
            setLoading(false);
          }
        }
      })();
    },
    [intl, eltType, payload.dateLimitsFirstRender, payload.group, payload.machines, payload.date_start, payload.date_end, payload.filters, push, runId, throwError, refetch] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {loading, blocks, setBlocks};
}