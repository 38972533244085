// Components
import { ChartBarSquareIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import Toggle from "@ui-components/Toggle";


function ChartOrTableToggle({
  booleanValue,
  setBooleanValue,
  customStyle = "",
}: {
  booleanValue?: boolean,
  setBooleanValue: () => void,
  customStyle?: string,
}) {
  return (
    <div className={`flex justify-center items-center gap-x-5 ${customStyle}`}>
      <ChartBarSquareIcon className={`w-7 h-7 ${booleanValue ? 'text-gray-400' : 'text-am-600'}`}/>
      <Toggle
        checked={booleanValue}
        onChange={() => setBooleanValue()}
        vertical={false}
        label={undefined}
        description={undefined}
      />
      <TableCellsIcon className={`w-7 h-7 ${booleanValue ? 'text-am-600' : 'text-gray-400'}`}/>
    </div>
  )
}

export default ChartOrTableToggle;