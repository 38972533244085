import {useContext, useEffect, useMemo, useRef, useState} from "react";
import MaterialTable from "@material-table/core";
import {useIntl} from "react-intl";
import {getCommonProps} from "@feature/commons/lib";
import TableStyle from "@ui-components/TableStyle";
import {getColumns} from "./params";
import {NotificationsContext} from "@ui-components/Notifications";
import {useFetchCombinations, useFetchMachineOptions, usePaginatedDataEntry} from "@feature/data-entry/hooks";
import {DataEntryTableProps, GenericDataEntrySchema} from "@feature/data-entry/types";
import {addRow, deleteRow, updateRow} from "@feature/data-entry/lib";
import {DataEntryErrors} from "@feature/data-entry";
import useDtnCustomersFetch from "@hooks/DtnCustomers/useDtnCustomersFetch";
// import useFetchTotalWarehousesNumber from "@hooks/Warehouse/useFetchTotalWarehousesNumber";


export function DataEntryTable<T extends GenericDataEntrySchema>({
  tab,
  errorAlert,
  schemaName,
  errorTrigger,
}: DataEntryTableProps) {

  const intl = useIntl();

  const notification = useContext(NotificationsContext);

  const didMount = useRef(false);

  const [refetch, setRefetch] = useState<number>(0);

  const commonProps = getCommonProps<T>(intl, {debounceInterval: 500});

  const refreshTrigger = () => setRefetch(p => p + 1);

  useEffect(() => {
    if (didMount.current)
      refreshTrigger();
    else didMount.current = true;
  }, [tab.table_name]);


  const {machineOptions} = useFetchMachineOptions();

  const {combinations} = useFetchCombinations(tab.table_name);

  const customersOptions = useDtnCustomersFetch();

  // const warehousesNumber = useFetchTotalWarehousesNumber();

  const columns = useMemo(
    () => getColumns<T>(tab.table_name, machineOptions, combinations, customersOptions, intl),
    [tab.table_name, machineOptions, combinations, customersOptions, intl]
  )

  const {fetch, loading} = usePaginatedDataEntry<T>(tab.table_name, schemaName, columns);

  const updateTrigger = () => {
    errorTrigger();
    refreshTrigger();
  }

  const editableOptions: Record<string, (newData: T) => Promise<any>> = {}

  if (tab.user_can_add)
    editableOptions["onRowAdd"] = (newData) => addRow<T>(tab.table_name, schemaName, newData, updateTrigger, notification.push, intl)

  if (tab.user_can_edit)
    editableOptions["onRowUpdate"] = (newData) => updateRow<T>(tab.table_name, schemaName, newData, updateTrigger, notification.push, intl)

  if (tab.user_can_delete)
    editableOptions["onRowDelete"] = (oldData) => deleteRow(tab.table_name, schemaName, oldData.id, updateTrigger, notification.push, intl)

  return (
    <div className="w-full flex flex-col gap-y-10">
      {errorAlert && <DataEntryErrors errors={errorAlert}/>}
      <TableStyle>
        <MaterialTable
          key={refetch}
          {...commonProps}
          columns={columns}
          data={fetch!}
          isLoading={loading}
          title=""
          editable={editableOptions}
        />
      </TableStyle>
    </div>
  )
}