import {ClientVsDeadlineType, EnergyVsWasteType} from "@feature/commons/types";

export interface ConfigurationSchema {
  /** An enumeration. */
  client_vs_deadline_param: ClientVsDeadlineType;
  /** An enumeration. */
  energy_vs_waste_param: EnergyVsWasteType;
}


export enum AgType {
  stg = "stg",
  dtn = "dtn"

}

export enum TipologiaMacchina {
  pinze = "pinze",
  catene = "catene",
}

export enum CodiceImpilatore {
  "A-B" = "A-B",
  "A-B-C" = "A-B-C",
  "Normale" = "Normale",
  "Manuale" = "Manuale",
  "Alla rinfusa" = "Alla rinfusa",
}

export interface GenericDataEntrySchema {
  id: number;
  cod_ag_type: AgType;
  flg_modified: boolean;
  flg_disabled: boolean;
}

export interface AccontiSchema extends GenericDataEntrySchema {
  cod_ordine: string;
  id_tranche: number;
  val_qt_tranche: number;
  dat_dl_tranche: string;
}

export interface AltoVendentiSchema extends GenericDataEntrySchema {
  cod_parte: string;
  des_cliente: string;
  cod_risorsa: string[];
  val_quantita_tot: number;
  val_quantita_prima_tranche: number;
  /* @format date */
  dat_prima_tranche: string;
  val_quantita_altre_tranche: number;
  num_work_days: number;
  /* @format date */
  dat_last_tranche?: string;
  flg_attivo: boolean;
}

export interface ArticoliApprovatiSchema extends GenericDataEntrySchema {
  cod_parte: string;
  flg_approvato: boolean;
}

export interface ArticoloPoseRisorseSchema extends GenericDataEntrySchema {
  cod_parte: string;
  val_pose: number;
  set_cod_risorse: string[];
}

export interface ClientiSchema extends GenericDataEntrySchema {
  des_cliente: string;
  priorita: number;
}

export interface ListaMacchineSchema extends GenericDataEntrySchema {
  cod_risorsa: string;
  flg_attiva: boolean;
  cod_tipologia: TipologiaMacchina;
  cod_matricola: string;
  val_anno_acquisto: string;
  cod_reparto: string;
  val_lunghezza_max: number;
  val_larghezza_max: number;
  flg_pallone: boolean;
  cod_impilatore: string;
  val_vel_min: number;
  val_vel_max: number;
  val_costo: number;
}

export interface ManutenzioniSchema extends GenericDataEntrySchema {
  id_utente: number;
  cod_risorsa: string
  /* Tms Inizio Manutenzione */
  /* @format date-time */
  tms_inizio_manutenzione: string;
  /* Tms Fine Manutenzione */
  /* @format date-time */
  tms_fine_manutenzione: string;
  category: string;
  user_mail: string;
}

export interface OrdiniSchema extends GenericDataEntrySchema {
  cod_ordine: string;
  cod_parte: string;
  val_pose_previsto: number;
  val_battute_ora_previste: number;
  des_cliente?: string;
  des_mtec?: string;
  /* Dat Inserimento */
  /* @format date-time */
  dat_inserimento: string;
  cod_materia_prima: string;
  cod_risorsa?: string;
  val_pezzi_ordinati: number;
  val_pezzi_buoni: number;
  flg_acconto: boolean;
  flg_terzista: boolean;
  flg_attivo: boolean;
  /* Dat Consegna */
  /* @format date */
  dat_consegna: string;
  flg_forzatura: boolean;
  qta_materia_prima_necessaria: number;
  dat_arrivo_materia_prima?: string;
  first_payment_tranche_date?: string;
  second_payment_tranche_date?: string;
  first_payment_tranche_quantity?: number;
  second_payment_tranche_quantity?: number;
}

export interface OrdiniSimulatiSchema extends GenericDataEntrySchema {
  id_utente: number;
  cod_ordine: string;
  cod_parte: string;
  val_pose_previsto: number;
  val_pezzi_ordinati: number;
  /* Dat Inserimento */
  /* @format date */
  dat_inserimento: string;
  /* Dat Consegna */
  /* @format date */
  dat_consegna: string;
  des_cliente: string;
  cod_materia_prima: string;
  qta_materia_prima_necessaria: number;
  dat_arrivo_materia_prima: string;
  flg_attivo: boolean;
  cod_risorsa_prevista?: string;
}

export interface ParametriSchema extends GenericDataEntrySchema {
  name_constraint: string;
  cod_reparto: string;
  val_constraint: number;
}

export interface ProduzioniSchema extends GenericDataEntrySchema {
  cod_parte: string;
  val_pose: number;
  cod_tipologia?: TipologiaMacchina
  flg_risorsa_3: boolean;
  val_lunghezza: number;
  val_larghezza: number;
  flg_pallone: boolean;
  cod_impilatore?: CodiceImpilatore;
}

export interface RepartiSchema extends GenericDataEntrySchema {
  cod_reparto: string;
  num_operatori: number;
}

export interface DtnErrorsSchema {
  /** Unprioritized Customers */
  unprioritized_customers: number;
  /** Articles Missing Approvation Status */
  articles_missing_approvation_status: number;
  /** Unapproved Articles */
  unapproved_articles: number;
  /** Missing Order Infos */
  missing_order_infos: number;
  /** Couples Missing Infos */
  couples_missing_infos: number;
  /** Couples No Revisions */
  couples_no_revisions: number;
}

export interface DataEntryTableProps {
  tab: DtnTableSchema;
  errorAlert?: DtnErrorsSchema;
  schemaName: string;
  errorTrigger: () => void;
}

export interface DataEntryUrlState {
  tab?: string,
  title?: string,
  description?: string
}

export interface DtnTableSchema {
  table_name: string;
  user_can_add: boolean;
  user_can_edit: boolean;
  user_can_delete: boolean;
}

export interface CombinationsSchema {
  cod_parte: string;
  set_cod_risorse: string[];
}

export interface PaginationSchemaAny<T> {
  total: number;
  items: T[];
  current_page?: number;
  page_size?: number;
}