import { api } from "@services/apiRequest";
import { useEffect, useState } from "react";
import { ClientiSchema, PaginationSchemaAny } from "@feature/data-entry/types";


function useDtnCustomersFetch() {
  const [customersOptions, setCustomersOptions] = useState<ClientiSchema[]>();

  useEffect(() => {
    (
      async () => {
        const customersResponse = await api.post<PaginationSchemaAny<ClientiSchema>>("/data_entry/dtn/clienti/paginated", {
          limit: 10000,           // High limit to get all the customers
          offset: 0,
          ordering: {             // Order by customer name
            fields: [
              {
                column: "des_cliente",
                orderDirection: "asc",
              }
            ]
          }
        });
        setCustomersOptions(customersResponse.data.items);
      }
    )()
  }, []);

  return customersOptions;
}

export default useDtnCustomersFetch;