import React, {useState} from "react";
import {CalendarIcon, ExclamationCircleIcon, XMarkIcon} from "@heroicons/react/24/solid";
import Label from "./Label";
import {useIntl} from "react-intl";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const validator = (type, value) => {
  switch (type) {
    case "email":
      return validateEmail(value);

    default:
      return true;
  }
};

const Input = ({
  value,
  label = "",
  name = "",
  type = "text",
  classNames = "",
  labelClassnames = "",
  validation = null,
  required = false,
  errorMessage = '',
  showCalendar = undefined,
  showErrorDialog = true,
  numberOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  },
  materialTable = false,
  onClear = () => {
  },
  ...rest
}) => {
  const [valid, setValid] = useState(true);
  const [focus, setFocus] = useState(false);
  const intl = useIntl();
  const containerClassMargin = !showErrorDialog || showCalendar || !valid ? 'mb-0' : 'mb-4';
  const containerClassAlignment =
    type === "hidden" ? "hidden" :
    `${containerClassMargin} flex shadow-sm items-center relative`;
  const containerClassBorder =
    type === "hidden" ? "" :
      materialTable ? "border-2 pl-2.5 pr-1 border-am-600 rounded-3xl bg-table-100" :
        "border rounded-md border-gray-300";

  let inputValue = value || "";

  if (inputValue && type === "number" && !focus) {
    inputValue = intl.formatNumber(inputValue, numberOptions);
  }

  const defaultProps = {
    onFocus: () => {
      if (type === "number") {
        setFocus(true);
      }
      setValid(true);
    },
    onBlur: (e) => {
      if (type === "number") {
        setFocus(false);
      }
      if (required) {
        if (!e.target.value) {
          setValid(false);
          return;
        }
      }
      if (validation) {
        const isvalid = validation(e.target.value);
        if(!isvalid){
          setValid(isvalid);
          return;
        }
      }
      
      setValid(validator(type, e.target.value));
    },
    type: type === "number" ? (focus ? type : "text") : type,
    name: type,
    className: `px-2 py-2 border-transparent focus:ring-transparent focus:border-transparent block w-full sm:text-sm disabled:opacity-50 ${materialTable ? "bg-table-100 rounded-3xl" : "rounded-md"}`,
    value: inputValue,
    readOnly: type === "number" && !focus,
    ...rest,
  };

  return (
    <div className={classNames}>
      {label ? <Label classNames={labelClassnames}>{label}</Label> : null}
      <div
        className={`${containerClassAlignment} ${containerClassBorder}`}>
        {typeof showCalendar !== "undefined" ? (
          <CalendarIcon
            className="h-6 w-6 text-gray-400 ml-1"
            aria-hidden="true"
          />
        ) : null}
        {type === "textarea" ? (
          <textarea {...defaultProps}/>
        ) : (
          <>
            <input {...defaultProps}/>
            {inputValue && onClear ?
              <XMarkIcon
                className="h-4 w-4 text-gray-400 cursor-pointer hover:text-gray-700"
                aria-hidden="true"
                onClick={onClear}
              /> : null
            }
          </>
        )}
        {!valid ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : null}
      </div>
      {!valid && errorMessage && (
        <p className="mt-1 text-xs text-red-600" id="email-error">
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default Input;
