export const tecnoform_en = {
  unprioritized_customers: "Customers to be prioritized",
  articles_missing_approvation_status: "Articles for which the approval status has yet to be defined",
  unapproved_articles: 'Articles with approval status: "Not yet approved"',
  missing_order_infos: "Orders for which further information is required",
  couples_missing_infos: "Couples (article, pose) for which information is missing",
  couples_no_revisions: "Couples (article, pose) for which resources have not yet been revised",
  last_run: "Last run",
  approved: "Approved",
  to_be_approved: "To be approved",
  active: "Active",
  inactive: "Inactive",
  cod_article: "Article Code",
  production_start_date: "Production start date",
  production_end_date: "Production end date",
  reordered_pieces_amount: "Reordered pieces",
  cod_resource: "Resource code",
  status: "Status",
  resources: "Resources",
  customer: "Customer",
  priority: "Priority",
  type: "Type",
  year: "Year",
  machine_serial_number: "Serial number",
  department: "Department",
  max_length: "Maximum length",
  max_width: "Maximum width",
  balloon: "Balloon",
  stacker: "Stacker",
  min_speed: "Minimum speed",
  max_speed: "Maximum speed",
  cost: "Cost",
  maintenance_start_date: "Maintenance start date",
  maintenance_end_date: "Maintenance end date",
  category: "Category",
  cod_order: "Order code",
  num_expected_poses: "Number of expected poses",
  expected_cycles_per_hour: "Expected cycles per hour",
  order_insertion_date: "Order insertion date",
  raw_material_code: "Raw material code",
  necessary_raw_material: "Necessary raw material",
  raw_material_arrival_date: "Raw material arrival date",
  assigned_resource: "Assigned resource",
  ordered_pieces: "Ordered pieces",
  valid_pieces: "Valid pieces",
  third_party_contractor: "Third party contractor",
  delivery_date: "Delivery date",
  is_order_mandatory: "Order mandatory",
  deposit: "Deposit",
  compiled: "Compiled",
  revised: "Revised",
  first_tranche_quantity: "First tranche quantity",
  first_tranche_date: "First tranche date",
  second_tranche_quantity: "Second tranche quantity",
  second_tranche_date: "Second tranche date",
  cod_part: "Part code",
  yes: "Yes",
  value: "Value",
  constraint_name: "Constraint name",
  poses: "Poses",
  allowed_on_resource_3: "Allowed on resource 3",
  mold_bell_length: "Mold bell length",
  mold_bell_width: "Mold bell width",
  chain: "Chain",
  pincher: "Pincher",
  back_to_schedule: "Back to schedule",
  alerts: "Alerts",
  user: "User",
  required: "Required",
  parameters: "Parameters",
  workers: "Workers",
  setups_max_number: "Maximum number of simultaneous setups",
  val_quantita_tot: "Total quantity",
  other_tranche_quantity: "Other tranche quantity",
  work_days: "Work Days Distance",
  last_tranche_date: "Last tranche date",
  last_tranche_date_tooltip: "The date of the last tranche will be automatically calculated during the next Anagram processing. It will then be manually editable.",
  equipment: "Attrezzatura",
  quantity: "Quantity",
  input_placeholder: "Filter ...",
  data_registry: "Data Registry",
  logging: "Logging",
  reparti: "Departments and setups number",
  production: "Production",
  setup: "Setup",
  maintenance: "Planned Downtime",
  product_value: "Product Value",
  daily_value_target: "Daily value target (€/gg)",
  work_days_in_month: "Work days in the month",
  from_day: "From day",
  to_day: "To day",
  total_in_period_selected: "Total in the selected period",
  total_target_in_period_selected: "Target in the selected period",
  day: "Day",
  machines_total: "Total machines",
  product_value_data_not_present: "Product value data not present",
  must_be_positive_number: "Must be a positive number",
  priority_range: "Must be between 1 and 5",
  must_be_empty_if_deposit_is_false: "Must be empty if it is not a deposit",
  sum_val_quantity_tot_is_less_than_sum_val_quantity_tranche: "The total quantity specified is less than the sum of the tranche quantity",
  flg_acconto_true_but_payment_tranches_not_filled: "Deposit marked, but payment tranche fields are not fully filled in (First Tranche Quantity, First Tranche Date, Second Tranche Quantity, Second Tranche Date)",
  flg_acconto_false_but_payment_tranches_filled: "Deposit not marked, but at least one payment tranche field is filled in (First Tranche Quantity, First Tranche Date, Second Tranche Quantity, Second Tranche Date)",
  rep_has_machines_associated: "The department has machines associated with it",

  // Warehouse Monitoring
  warehouses_monitoring: "Warehouse Monitoring",
  warehouse_perc_saturation_tooltip: "The saturation percentage is calculated by averaging the saturation of each lane in the warehouse. If the saturation of a lane is greater than 100%, it is considered as if it were 100%.",
  accatastamento: "Accumulation",
  saturazione: "Saturation",
  priorita_magazzini: "Warehouse Priorities",
  capacita: "Capacity",
  conversioni: "Conversions",
  cod_family: "Family",
  des_family: "Family Description",
  cod_sub_family: "Sub-Family Code",
  des_sub_family: "Sub-Family Description",
  flg_active: "Active",
  des_accatastamento_default: "Default Accumulation",
  des_accatastamento_manuale: "Manual Accumulation",
  cod_warehouse: "Warehouse Code",
  des_warehouse: "Warehouse Description",
  cod_lane: "Lane Code",
  des_lane: "Lane Description",
  num_max_pallet: "Pallets max number",
  id_preference: "Preference ID",
  perc_saturazione: "Saturation percentage (%)",
  des_um: "Unit of measure",
  cod_item: "Item Code",
  des_item: "Item Description",
  num_max_um_pallet: "Max number of UM per pallet",
  are_you_sure_to_delete: "Are you sure you want to delete this row?",
  max_length_exceeded: "Maximum length exceeded",
  must_be_less_than: "Must be less than",
  must_be_integer: "Must be an integer",
  perc_saturazione_tooltip: "Please insert a value between 0 and 100",
  current_state: "Current state",
  forecast: "Forecast",
  data_updated_on: "Data updated on",
  warehouse_monitoring_data_not_present: "Warehouse monitoring data not present",
  visualization_date: "Visualization date",
  visualization_kpi: "Visualization KPI",
  perc_saturation: "Saturation (%)",
  val_pallet: "Pallet",
  amt_stock: "Value (€)",
  item_type: "Item type",
  warehouse: "Warehouse",
  lane: "Lane",
  no_data_available: "No data available",
  no_gauge_chart_data_available: "No data available for gauge charts",
  aggregation_period: "Periodo di aggregazione",
  month: "Month",
  week: "Week",
  filter_warehouse: "Filter warehouse",
  filter_lane: "Filter lane",
  filter_item: "Filter item",
  item: "Item",
  total: "Total",
  cod_lane_type: "Lane Type",
  cambio_tassello: "Tile Change",
  cambio_ordine: "Order Change",
  cambio_stampo: "Stamp Change",
  cambio_materiale: "Material Change",
  all: "All",
  choose_a_machine: "Please choose a machine",

  // Roles translation
  admin: "admin",
  manager: "manager",
  warehouse_user: "warehouse_user",
  anagram_user: "anagram_user",
}