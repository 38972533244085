import React, {useReducer} from "react";
import {initialState, reducer} from "@feature/commons/lib";
import {AnagramParams, TitleAndDescription, ValidateDataEntry} from "@feature/commons";
import {EltType} from "@feature/commons/types";

export function NewPlanningRun() {
	
	const [state, dispatch] = useReducer(reducer, initialState);
	
	return (
		<>
			<TitleAndDescription dispatch={dispatch} state={state} eltType={EltType.plan}/>
			{state.title && state.description && <ValidateDataEntry state={state} dispatch={dispatch}/>}
			{
				state.DEValidation.every(e => e.valid) && (
					<AnagramParams eltType={EltType.plan} allowLaunch runParams={state}/>
				)
			}
		</>
	)
}