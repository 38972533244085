import Counter from "../Counter";

function SmallNotification({counter, classStyle = "ml-2 -mt-4", enableAnimation = false}: {
  counter: number,
  classStyle?: string,
  enableAnimation?: boolean
}) {
  return (
    <div
      key={counter}
      className={`flex font-medium justify-center items-center text-sm text-white text-content-semi-fit p-1.5 h-4 rounded-full bg-red-500 ${classStyle}`}>
      {enableAnimation ? <Counter number={counter} duration={1}/> : counter}
    </div>
  )
}

export default SmallNotification;