import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { api } from "@services/apiRequest";
import createFrontendLog from "@utils/loggingFrontendDetail";

export function usePageViews() {
  const location = useLocation();
  useEffect(() => {
    /**
     * user info that gets written on db:
     * requested path, browser, browser's version, device is mobile, operating system, hashed user email, screen width(in pixels), screen height(in pixels), device is touch, userAgent string
     * the above parameters are committed to db independently from userAgentData or userAgent use; consult db for further details
     */
    const logging = createFrontendLog(location);

    const auditNavigationPost = async () =>
      api.post("/audit/navigation", logging, {
        optionalAuth: true,
      });

    auditNavigationPost();
  }, [location]);
}

export default usePageViews;
