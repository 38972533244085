import { Parser, ENode } from "./ast";
import type {
  Operator,
  FilterItem,
  FilterSchema,
  FilterBuilderSchema,
  FilterBuilderField,
} from "./types";

function parseExpression(expression: string) {
  const parser = new Parser(expression);
  return parser.parse();
}

/**
 * Build a FilterItem from an AST and a list of fields
 * FilterItem describes a filter that can be applied to a backend request
 * the AST is built from a string expression
 * the fields are the columns we want to filter on
 */
export function buildFilterSchema(
  ast: ENode,
  fields: FilterBuilderField[]
): FilterItem | null {
  if (ast.node.value === "and" || ast.node.value === "or") {
    if (!ast.left || !ast.right) {
      throw new Error(`Invalid expression ${ast.node.value}`);
    }

    const filters: FilterItem[] = [
      buildFilterSchema(ast.left, fields),
      buildFilterSchema(ast.right, fields),
    ].filter((x) => x !== null) as FilterItem[];

    if (filters.length === 0) {
      return null;
    }
    if (filters.length === 1) {
      return filters[0];
    }
    return {
      logic: ast.node.value.toLowerCase() as "and" | "or",
      filters,
    };
  }

  const field = fields.find((f) => f.column === ast.node.value);
  if (!field) {
    return null;
  }
  return {
    column: field.column,
    operator: field.operator.toLowerCase() as Operator,
    value: field.value,
  };
}

/**
 * Main function to build a FilterSchema from a FilterBuilderSchema
 * FilterBuilderSchema is a schema that contains the fields we want to filter on
 * and an optional expression that defines the logical operators between the fields
 * overriding the default logic
 * Note that if specifying an expression it must include all the fields we want to filter on
 * 
 * FilterSchema could then be translated for a backend request with
 * ./request/buildBodyParams or ./request/buildQueryParams
 * 
 * @param schema FilterBuilderSchema that specifies the fields and the optional expression
 * @param defaultLogic default logic to override the default logic
 * @returns FilterSchema or null if the schema is invalid
 */
export function buildFilter(
  schema: FilterBuilderSchema,
  defaultLogic = "and"
): FilterSchema | null {
  const { expression, fields } = schema;
  if (!fields || fields.length === 0) {
    return null;
  }
  if (!expression) {
    return {
      logic: defaultLogic as "and" | "or",
      filters: fields,
    };
  }
  // Expression is a string with column names surrounded by parenthesis
  // and interleaved with logical operators AND and OR
  // we build an AST to parse the expression
  const ast = parseExpression(expression);
  // we use the AST to build a FilterSchema substituting the column names
  // with the columns from the fields
  const filters = buildFilterSchema(ast, fields);
  if (!filters) {
    return null;
  }
  if (typeof filters !== "object" || !("logic" in filters)) {
    return {
      logic: defaultLogic as "and" | "or",
      filters: [filters],
    };
  }
  return filters;
}


export function timestampStringConverter(
  value: string,
  isTimestamp: boolean = true,
) {
  /*
    Takes a string in the format "DD/MM/YYYY HH:mm:ss"
    and returns a string that can be used in a LIKE query in a timestamp column in a database.
  */
  const date = value.split(' ')[0];
  const time = value.split(' ')[1] ?? '';

  const datePart = date.split('/').reverse().join('-');

  return `%${datePart}${time === '' || !isTimestamp ? '' : ` ${time}`}%`;
}