import {Dispatch, SetStateAction} from "react";
import {api} from "@services/apiRequest";
import {FilterParamsType, GanttBlocksType, GanttUpdateSchema, LabelsType} from "@feature/commons/gantt/types";
import {EltType} from "@feature/commons/types";

export const getLabelsAndColors = (data: GanttBlocksType[]) => {

  const labelsAndColors = data.reduce<LabelsType>(
    (acc, el) => {
      if (!Object.keys(acc).includes(el.des_block))
        acc[el.des_block] = {des_block: el.des_block, color: el.columnSettings.fill, cod_type_interval: el.cod_type_interval}
      return acc
    }, {})

  return Object.values(labelsAndColors)
}

export const getUniqueYValues = (data: GanttBlocksType[]) =>
  data.reduce<{ cod_machine: string }[]>((acc, el) => {
    if (!acc.find(x => x.cod_machine === el.cod_machine))
      acc.push({cod_machine: el.cod_machine})
    return acc
  }, [])

export const saveChanges = (
  categoriesData: GanttBlocksType[],
  filterParams: FilterParamsType,
  setRefetchBlocks: Dispatch<SetStateAction<boolean>>,
  eltType: EltType
) => api.patch<void>(`/gantt_blocks/${filterParams.idRun}/${eltType}/`, {
  new_values: categoriesData
} as GanttUpdateSchema).then(
  () => setRefetchBlocks(p => !p)
)


export const computeChartHeight = (data: GanttBlocksType[], rowSize: number): number => new Set(data.map(b => b.cod_machine)).size * rowSize * 2

