import XLSX from "xlsx";
import {MTColumnsType} from "./pagination";

interface downloadExcelProps<T extends object> {
  exportData: T[];
  columns: MTColumnsType<T>;
  _titles?: string[];
  name?: string;
}

export const downloadExcel = <T extends object>({
  exportData,
  columns,
  _titles,
  name = "table_export"
}: downloadExcelProps<T>) => {
  const titles = _titles ?? columns.filter((i) => !i.hidden).map((i) => i.title);

  const workSheet = XLSX.utils.json_to_sheet([]);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(workSheet, [titles]);
  XLSX.utils.sheet_add_json(workSheet, exportData, {
    origin: "A2",
    skipHeader: true,
  });

  XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet1");
  XLSX.write(workBook, {bookType: "xlsx", type: "buffer"});
  XLSX.write(workBook, {bookType: "xlsx", type: "binary"});
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  XLSX.writeFile(workBook, `${name}_${yyyy}${mm}${dd}.xlsx`);
};