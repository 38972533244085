import type { Query, Filter } from "@material-table/core";
import type {
  FilterBuilderField,
  OrderingSchema,
  MTColumnSpech,
  FilterSchema,
  MTColumnsType,
  MTAdditionalColumnsMapping,
} from "./types";

import { buildFilter } from "./filters";
import {dateToString} from "@utils/utils";


/**
 * Get the default value for a filter based on expected column types from material-table
 * for example we know that we want to filter with a like operator on a string column
 * or we need to convert a date to a string in ISO format
 * 
 * @param f Filter<T> from material-table T is the type of the row data
 */
function getDefaultValue<T extends object>(f: Filter<T>) {
  // is array
  if (Array.isArray(f.value)) {
    // for the case of partial matches on arrays
    return f.value.map((v: string) => `%${v}%`);
  }

  const kind = f.column?.type;
  switch (kind) {
    case "string":
      return `%${f.value}%`;
    case "boolean":
      return f.value === "checked";
    case "numeric":
      return parseFloat(f.value);
    case "date":
      return dateToString(f.value);
    case "datetime":
      return f.value.toISOString();
    default:
      return f.value;
  }
}

/**
 * Get the default operator for a filter based on expected column types from material-table
 * 
 * @param f Filter<T> from material-table  sT is the type of the row data
 */
function getDefaultOperator<T extends object>(f: Filter<T>) {
  if (Array.isArray(f.value)) {
    return "ilike any";
  }
  const kind = f.column?.type;
  switch (kind) {
    case "string":
      return "ilike";
    case "boolean":
      return "bool";
    default:
      return f.operator;
  }
}

function addParenthesis(expression: string) {
  if (expression.includes(" ")) {
    return `(${expression})`;
  }
  return expression;
}

/**
 *
 * Converts the query object from material-table to a filter object
 * It support and additional column specification to override the default
 * column behaviour regarding filter operator and grouping
 *
 * @param query material-table query
 * @param spechLookup a lookup object for column specifications
 * @param additionalFields additional fields to be added to the filter
 */
export function filterBuilderFromQuery<T extends object>(
  query: Query<T>,
  spechLookup: { [key: string]: MTColumnSpech<T> } = {},
  additionalFields: FilterBuilderField[] = []
): { filterBuilder: FilterBuilderField[]; defaultexpression: string } {
  let defaultexpression = "";
  const filterBuilder = query.filters
    .reduce((acc, f) => {
      const spech = spechLookup[f.column.field as string];
      const columns = !!spech
        ? Array.isArray(spech.column_name)
          ? spech.column_name
          : [spech.column_name]
        : [f.column.field];
      const currentexpression = addParenthesis(
        columns.map((c) => c).join(" or ")
      );
      defaultexpression = defaultexpression
        ? `${defaultexpression} and ${currentexpression}`
        : currentexpression;
      const operator = spech?.filter_operator || getDefaultOperator(f);
      const value = spech?.value_parser
        ? spech.value_parser(f.value)
        : getDefaultValue<T>(f);
      return [
        ...acc,
        ...columns.map(
          (column) =>
            ({
              column: column,
              operator,
              value,
            } as FilterBuilderField)
        ),
      ];
    }, [] as FilterBuilderField[])
    .concat(additionalFields);
  defaultexpression = defaultexpression.trim();
  return { filterBuilder, defaultexpression };
}

function forceString(value: any) {
  if (typeof value === "string") {
    return value;
  }
  return JSON.stringify(value);
}

export function orderingFromQuery<T extends object>(
  query: Query<T>
): OrderingSchema | null {
  if (!query?.orderBy || !query?.orderBy?.field) {
    return null;
  }
  const order = {
    orderDirection: query.orderDirection,
    column: forceString(query.orderBy.field),
  };
  return { fields: [order] };
}

/**
 * Filter out empty fields from a FilterBuilderField[]
 * @param fbuilder FilterBuilderField[]
 * @returns FilterBuilderField[] without empty fieldss
 */
function filterEmptyBuilder(fbuilder: FilterBuilderField[]) {
  return fbuilder.filter((f) => {
    if (Array.isArray(f.value)) {
      return f.value.length > 0;
    }
    return f.value !== undefined && f.value !== null;
  });
}

/**
 * Main function to build a FilterSchema from a material table query and column specification
 * additionally we can specify an expression, additional columns that were not in the original table query
 * and a default logic for the resulting FilterSchema
 *
 * @param query material-table query
 * @param columns column specification
 * @param additionalColumnsMapping additional columns to be added to the filter
 * @param expression expression to override the default expression
 * @param defaultLogic default logic to override the default logic
 * @returns FilterSchema
 */
export function buildFilterFromQuery<T extends object>(
  query: Query<T>,
  columns: MTColumnsType<T>,
  additionalColumnsMapping: MTAdditionalColumnsMapping<T> = {},
  expression = "",
  defaultLogic = "and"
): FilterSchema | null {
  const spechLookup = columns
    .filter((c) => !!c.pagination?.spech)
    .reduce(
      (acc, curr) => ({
        ...acc,
        [curr.field as string]: curr.pagination!.spech,
      }),
      {}
    ) as { [key: string]: MTColumnSpech<T> };

  const additional_columns = additionalColumnsMapping
    ? generateStringAdditionalColumns(query, additionalColumnsMapping)
    : [];
  const { filterBuilder, defaultexpression } = filterBuilderFromQuery(
    query,
    spechLookup,
    additional_columns
  );
  const filter = {
    expression: expression || defaultexpression,
    fields: filterEmptyBuilder(filterBuilder),
  };

  const filters = buildFilter(filter, defaultLogic);
  return filters;
}


/**
 * Generate additional columns FilterBuilderField specification from a material table query and a mapping
 * the mapping describes how the additional columns should be generated
 * We can specify a custom value for the additional column by specifying a functino for the `value` 
 * or we can *copy* the value from antoher column by specifying the column name as a string
 * 
 * the operator is optional and it will be the default operator we specified with getDefaultOperator
 * 
 * for example
 * additional_columns = { double: { value: "number", operator: "<=" }}
 * 
 * this will generate a filter for the column `double` with the value of the column `number` and the operator `<=`
 * 
 * additional_columns = { double: { value: (query) => query.filters.find((f) => f.column.field === "number").value, operator: "<=" }}
 * 
 * this is equivalent to the previous example, but notice that you can place any value you want in the `value` function
 *
 * The supported operators are any Operator from ./types.ts
 * 
 * @param query material-table query
 * @param mappings additional columns mapping
 * @returns FilterBuilderField[]
 */
export const generateStringAdditionalColumns = <T extends object>(
  query: Query<T>,
  mappings: MTAdditionalColumnsMapping<T>
): FilterBuilderField[] => {
  const filters = Object.entries(mappings)
    .map(([extraColumn, filterColumn]) => {
      // if is string
      if (typeof filterColumn.value === "string") {
        const filter = query.filters.find(
          (f: Filter<T>) => f.column.field === filterColumn.value
        );
        if (!filter) {
          return undefined;
        }
        const operator = filterColumn.operator ?? getDefaultOperator(filter);
        const value = getDefaultValue(filter!);
        const ret = {
          column: extraColumn,
          value: value,
          operator: operator,
        };
        return filterEmptyBuilder([ret])[0];
      }
      const value = filterColumn.value(query);
      const operator = filterColumn.operator;
      const ret = {
        column: extraColumn,
        value: value,
        operator: operator!,
      };
      return filterEmptyBuilder([ret])[0];
    })
    .filter((filter) => filter !== undefined) as FilterBuilderField[];
  return filters;
};
