import {useState} from "react";
import {useParams} from "react-router";
import {MultiDatePicker} from "@ui-components/MultiDatePicker";
import {GanttTable} from "@feature/commons/gantt";
import {useFetchGanttData} from "@feature/commons/hooks";
import {DateFiltersType, EltType} from "@feature/commons/types";
import {BlurredPlaceholder} from "@feature/commons";
import {dateToString, subtractMonths} from "@utils/utils";
import {GanttBlocksType} from "@feature/commons/gantt/types";
import {PlanDetailsGantt} from "./plan-details-gantt";
import {useIntl} from "react-intl";

export function PlanDetails() {
	
	const [dateRange, setDateRange] = useState<DateFiltersType>([subtractMonths(new Date(), 1), new Date()]);
	const [showGantt, setShowGantt] = useState<GanttBlocksType>()
	
	const intl = useIntl();
	
	const {id_run} = useParams<{ id_run: string }>();
	
	const {loading, blocks} = useFetchGanttData(
		parseInt(id_run),
		EltType.plan,
		{
			date_start: dateToString(dateRange[0]),
			date_end: dateToString(dateRange[1]),
			machines: [], // FIXME: machines and group needs to be passed from searchselect like in sched
			group: "",
			filters: [], // FIXME: add toggles like in sched
			dateLimitsFirstRender: false,
		},
	);
	
	const msg = (id: string) => intl.formatMessage({id});
	
	return (
		<>
			<MultiDatePicker
				datePeriod={dateRange}
				setDatePeriod={setDateRange}
				labels={[msg('from'), msg('to')]}
				classNames="w-1/2"/>
			<BlurredPlaceholder loading={loading}>
				<GanttTable
					categoriesData={blocks!}
					idRun={parseInt(id_run)}
					eltType={EltType.plan}
					setShowGantt={setShowGantt}/>
			</BlurredPlaceholder>
			<PlanDetailsGantt open={showGantt} setOpen={setShowGantt}/>
		</>
	)
}