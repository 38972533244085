import React from "react";
import Slider from "@material-ui/core/Slider";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";

const sliderTheme = createTheme({
    palette: {
        primary: {main: "#00475C"},
        secondary: {main: "#002731FF"},
    },
    overrides: {
        MuiSlider: {
            root: {
                display: "flex",
                alignItems: "center"
            },
            thumb: {
                height: '15px',
                width: '15px',
                marginTop: '0.2px',
            },
            rail: {
                height: '10px',
                borderRadius: '10px'
            },
            track: {
                height: '10px',
                borderRadius: '10px'
            },
            valueLabel: {
                left: "auto",
                display: "none",
            },
            mark: {
                display: "none"
            },
            markLabel: {
                textTransform: "capitalize"
            }
        },
        PrivateValueLabel: {
            circle: {
                backgroundColor: "#00475C",
                width: 'auto',
                transform: 'rotate(0)',
                borderRadius: '10px',
                padding: '6px'
            },
            offset: {
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',

            },
            label: {
                transform: 'rotate(0)',
            }
        }
    }
});

const SliderSelect = ({value, onChange, label = '', ...rest}) => {
    return (
        <ThemeProvider theme={sliderTheme}>
            <Slider
                aria-label={label}
                valueLabelDisplay="on"
                value={value}
                onChange={onChange}
                {...rest}
            />
        </ThemeProvider>
    );
};

export default SliderSelect;
