import React from "react";
import {IntlShape} from "react-intl";
import {MTColumnsType} from "@utils/pagination";
import {CustomEditDatePicker, CustomFilterDatePicker} from "@components/Tables/Params/CustomDatePicker";
import {RunFullSchema} from "../types";
import LoadingTooltip from "@ui-components/LoadingTooltip";
import {EltType, Status} from "@feature/commons/types";

type T = RunFullSchema;


export const getColumns = (intl: IntlShape, eltType: EltType): MTColumnsType<T> => [
	{
		title: "id",
		field: "id",
	},
	{
		title: intl.formatMessage({id: "title"}),
		field: "title",
		type: "string",
	},
	{
		title: intl.formatMessage({id: "description"}),
		field: "description",
		type: "string",
	},
	{
		title: "Data",
		field: "date",
		type: "date",
		render: rowData => <>{new Intl.DateTimeFormat("it-IT").format(new Date(rowData.date))}</>,
		editComponent: props => <CustomEditDatePicker {...props}/>,
		filterComponent: (props: any) => <CustomFilterDatePicker {...props}/>
	},
	{
		title: "Stato",
		field: "status",
		lookup: Status,
		render: rowData => <LoadingTooltip status={rowData.status as Status}/>,
		filterCellStyle: {minWidth: '450px', maxWidth: '450px'},
		cellStyle: {padding: '0 !important'},
		pagination: {
			spech: {
				column_name: ["status"],
				filter_operator: "in",
				value_parser(value: string | string[]) {
					if (typeof(value) === "object") {
					  return value.map((v: string) => Status[v as keyof typeof Status])
					} else {
					  return `[${Status[value as keyof typeof Status]}]`
					}
				  },
			}
		},
	},
	...[
		eltType === EltType.plan
			? {
				title: "Default Run",
				field: "flg_default_run",
				type: "boolean"
			}
			: ({} as any)
	],
]

export const getRunMapping = (eltType: EltType, intl: IntlShape) => {
	const msg = (msgId: string, translation_value?: Record<string, any>) => intl.formatMessage({id: msgId}, translation_value);
	
	return {
		[msg("title")]: "title",
		[msg("description")]: "description",
		[msg("status")]: "status",
		...(eltType === EltType.plan ? {[msg("default_run")]: "flg_default_run"} : {}),
		[msg("date")]: "date",
	}
}