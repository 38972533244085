import {useContext, useEffect, useState} from "react";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";
import {useAsyncError} from "@hooks/useAsyncError";
import {NotificationsContext} from "@ui-components/Notifications";
import {EltType, SelectOptionsSchema} from "@feature/commons/types";

type T = SelectOptionsSchema[];
export const useFetchGroups = (runId: number, eltType: EltType) => {
	
	// context
	const {push} = useContext(NotificationsContext);
	
	// hooks
	const intl = useIntl();
	const throwError = useAsyncError();
	
	// state
	const [loading, setLoading] = useState<boolean>(false);
	const [groups, setGroups] = useState<T>();
	
	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				const {data} = await api.get<T>(`/gantt_blocks/${runId}/${eltType}/groups`);
				setGroups(data)
			} catch (e) {
				push({type: 'error', title: intl.formatMessage({id: 'generic_error'})});
				throwError(e);
			} finally {
				setLoading(false);
			}
		})();
	}, [eltType, intl, push, runId, throwError]);
	
	return {loading, groups};
}