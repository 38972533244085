import React from "react";
import Spinner from "./Spinner";

const buttonStyles = {
  default: "text-white bg-am-600 hover:bg-am-700",
  white: "text-gray-700 bg-white border border-am-600 hover:bg-gray-50",
  secondary: "text-am-700 bg-gray-300 hover:bg-gray-500",
  danger: "text-white bg-red-600 hover:bg-red-700",
};

const Button = ({
  full = false,
  classNames = "",
  children,
  styleType = "default",
  submitting = false,
  disabled = false,
  ...rest
}) => {
  let defaultClasses =
    "relative overflow-hidden uppercase justify-center inline-flex items-center px-4 py-2 border text-sm font-bold rounded-3xl shadow-sm focus:outline-none active:opacity-70 ";

  if (buttonStyles[styleType]) {
    defaultClasses += buttonStyles[styleType];
  } else {
    defaultClasses += buttonStyles.default;
  }

  return (
    <button
      disabled={disabled || submitting}
      className={`${defaultClasses} ${classNames} ${full ? "w-full" : ""} ${disabled ? "opacity-20 pointer-events-none" : ""}`}
      {...rest}
    >
      {children}
      {submitting ? (
        <Spinner classes={buttonStyles[styleType]} styleType={styleType} />
      ) : null}
    </button>
  );
};

export default Button;
