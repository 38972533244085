import React from "react";
import * as HIcons from '@heroicons/react/24/outline';

const Icon = ({iconName}) => {
  const {...icons} = HIcons
  const SelectedIcon = icons[iconName]
  return (
    <>
      <SelectedIcon/>
    </>
  );
};

export default Icon;
