import React from 'react';
import {TimePickerPropsType} from "../types";

export function TimePicker({label, value, onHourChange, onMinuteChange}: TimePickerPropsType) {


  const handleInputChange = (event: string,
                             type: "hour" | "minute",
                             onChange: (newValue: number) => void) => {
    const maxLimit = type === 'hour' ? 23 : 59
    const input = event.trim();
    const intValue = parseInt(input);
    if (!Number.isNaN(intValue) && intValue >= 0 && intValue <= maxLimit)
      onChange(intValue);
  }

  return (
    <div className="flex flex-col w-36">
      <label className="capitalize text-sm font-bold text-am-600 mx-auto">{label}</label>
      <div className="flex justify-center">
        <input
          className="rounded-l-2xl w-1/3 border-am-600 focus:ring-transparent focus:border-current border-r-0"
          type="text"
          value={value.hour.toString().padStart(2, "0")}
          onChange={(e) => handleInputChange(e.target.value, "hour", onHourChange)}/>
        <span className="flex border-t border-b border-am-600 items-center bg-white">:</span>
        <input
          className="rounded-r-2xl w-1/3 border-am-600 focus:ring-transparent focus:border-current border-l-0"
          type="text"
          value={value.minute.toString().padStart(2, "0")}
          onChange={(e) => handleInputChange(e.target.value, "minute", onMinuteChange)}/>
      </div>
    </div>
  );
}