import React, {useMemo} from "react";
import {useIntl} from "react-intl";
import MaterialTable from "@material-table/core";
import {GanttTableProps, GanttTableSchema, IntervalType} from "@feature/commons/gantt/types";
import {getCommonProps} from "@feature/commons/lib";
import {getColumns} from "./params";
import TableStyle from "@ui-components/TableStyle";
import {downloadExcel} from "@utils/downloadExcel";
import {EltType} from "@feature/commons/types";
import {EyeIcon} from "@heroicons/react/24/outline";
import {dateToString} from "@utils/utils";

type T = GanttTableSchema;

export function GanttTable({idRun, eltType, categoriesData, setShowGantt}: GanttTableProps) {
  const intl = useIntl()

  const commonProps = getCommonProps<T>(intl);

  // Flattening data for gantt table
  const data: T[] = useMemo(
    () => categoriesData.flatMap(
      c_d => {
        if (c_d.f_orders.length)
          return c_d.f_orders.flatMap(
            f_o =>
              c_d.equipments.flatMap(
                eq => {
                  const newData = {...c_d, ...f_o, ...eq, ...c_d.dict_block_information, ...f_o.dict_order_information}
                  const {f_orders, equipments, dict_block_information, ...rest} = newData
                  return rest
                }
              )
          )
        else {
          const newData = {...c_d, ...c_d.dict_block_information}
          const {f_orders, equipments, dict_block_information, ...rest} = newData
          return rest
        }
      }
    ), [categoriesData])


  const columns = getColumns(intl);

  // hash data and use it to force rerender
  const _hash = JSON.stringify(data)

  return (
    <TableStyle>
      <MaterialTable
        key={_hash}
        columns={columns}
        {...commonProps}
        data={data}
        title=""
        actions={[
          ...
            eltType === EltType.plan && !!setShowGantt ?
              [{
                icon: () => <EyeIcon
                  className="rounded-full text-am-600 hover:bg-am-600 hover:text-white p-1.5 h-8 w-8"
                />,
                onClick: (rowData: T) => setShowGantt(
                  categoriesData.find(
                    c_d => c_d.cod_production === rowData.cod_production
                  )
                ),
                isFreeAction: false,
              }] :
              [],
          {
            ...commonProps.downloadAction,
            onClick: () => {

              return downloadExcel<T>({
                exportData: data.map(
                  el => ({
                    ...el,
                    cod_customer: [el.cod_customer, el.des_customer].join(" - "),
                    cod_equipment: [el.cod_equipment, el.des_equipment].join(" - "),
                    dat_deadline: el.dat_deadline ? dateToString(new Date(el.dat_deadline)) : undefined,
                    tms_start: dateToString(new Date(el.tms_start)),
                    tms_end: dateToString(new Date(el.tms_end)),
                    cod_type_interval: intl.formatMessage({id: el.cod_type_interval}) as IntervalType,
                  })
                ),
                columns: columns,
                name: `sched_data_${idRun}`
              })
            }
          },
        ]}
      />
    </TableStyle>
  )
}
