// ##### DATE FUNCTIONALITIES ######
export const getDateOfISOWeek = (weekNumber, year) => {
  // given week number and year, return a Date object
  // with day as the first day of the week
  let simple = new Date(year, 0, 1 + (weekNumber - 1) * 7);
  let dow = simple.getDay();
  let ISOweekStart = simple;
  if (dow <= 4)
    ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else
    ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
}

export const getDateOfISOYearMonth = (year) => {
  return new Date(year, 0, 1);
}

export const getWeekFromDate = (inputDate) => {
  // given a date, returns the week number of that day
  const firstMonday = getNextMonday(new Date(inputDate.getFullYear(), 0, 1));
  const numberOfDays = Math.floor((inputDate - firstMonday) / (24 * 60 * 60 * 1000));      // milliseconds in one day
  if (numberOfDays < 0)
    return 52;
  return Math.floor(numberOfDays / 7) + 1;
}

const getCurrentMondayDate = (inputDate) => {
  let result = new Date(inputDate);
  const dayNumber = (result.getDay() || 7) - 1;        // Get current day number, converting Sun. to 7
  result.setDate(result.getDate() - dayNumber);
  return result;
};

const getNextMonday = (inputDate) => {
  const lastMonday = getCurrentMondayDate(inputDate);

  let result = new Date(lastMonday);
  result.setDate(result.getDate() + 7);
  return result;
};

export const getYesterday = (inputDate) => {
  return new Date(inputDate.setDate(inputDate.getDate() - 1));
}

export const getFirstOfNextMonth = (inputDate) => {
  return toUTC(new Date(inputDate.getFullYear(), inputDate.getMonth() + 1, 1));
}

export const getDistanceWithLastDayOfMonth = (inputDate) => {
  const year = inputDate.getFullYear();
  const month = inputDate.getMonth();
  const day = inputDate.getDate();

  const lastDayOfMonth = new Date(year, month + 1, 0);

  return lastDayOfMonth.getDate() - day;
}

export const addOrSubtractDays = (inputDate, days) => {
  return new Date(inputDate.setDate(inputDate.getDate() + days));
}

export const dateIsInCurrentMonth = (inputDate) => {
  const today = new Date();
  return today.getMonth() === inputDate.getMonth() && today.getFullYear() === inputDate.getFullYear();
}

export const monthIsInMinSelectableDate = (inputDate, minSelectableDate) => {
  return inputDate.getMonth() === minSelectableDate.getMonth() && inputDate.getFullYear() === minSelectableDate.getFullYear();
}

// very important functions that casts every local date to UTC date and viceversa
export const toUTC = (d) => new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000);
export const toLocalTime = (d) => new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000);


/*
* Given a string, capitalize the first letter of each word
* @param {string} inputString
* @return {string}
* */
export const capitalize = (inputString) => {
  return inputString.split(' ').map(
    word => word.charAt(0).toUpperCase() + word.slice(1)
  ).join(' ');
}