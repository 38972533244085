import Modal from "@ui-components/Modal";
import {PlanDetailsGanttProps} from "@feature/plan/types";


export function PlanDetailsGantt({open, setOpen}: PlanDetailsGanttProps) {
	return (
		<Modal opened={Boolean(open)} onExit={() => setOpen(undefined)}>
			Have Fun Simo!
		</Modal>
	)
}
