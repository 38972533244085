import React, { useEffect, useState } from "react";
import AuthenticationService from "../../../services/AuthenticationService";
import { Redirect } from "react-router-dom";
import { getLoginUrl } from "../Login";

function useQuery() {
  return new URLSearchParams(window.location.search);
}

export default function OAuth2() {
  const [authenticated, setAuthentication] = useState(false);
  const [authorized, setAuthorization] = useState(true);
  const query = useQuery();

  useEffect(() => {
    const token = query.get("access_token");
    const refresh_token = query.get("refresh_token");
    const userData = query.get("user_data");

    if (token && userData) {
      AuthenticationService.setAuthToken(
        token,
        refresh_token,
        JSON.parse(userData)
      );
    } else {
      setAuthorization(false);
    }

    if (AuthenticationService.isUserAuthenticated()) {
      console.log("User authenticated with OAuth2. Back to home");
      setAuthentication(true);
    }
  }, [authenticated, authorized, query]);

  if (authenticated) {
    window.location = getLoginUrl(AuthenticationService.getUserScopes());
    return <div />;
  }

  if (!authorized) {
    return <Redirect to={"/login?unauthorized_user"} />;
  }

  return <div />;
}
