import {DataEntryErrors} from "@feature/data-entry";
import {DtnErrorsSchema} from "@feature/data-entry/types";
import {LastRun} from "@feature/commons";

export function DataEntryAlert({errors}: { errors?: DtnErrorsSchema }) {

  return (
    <div className="flex flex-col h-full gap-y-10">
      <DataEntryErrors errors={errors}/>
      <LastRun/>
    </div>
  )
}