import { IntlShape } from "react-intl";
// Model
import { AccatastamentoOptions, AccatastamentoSchema, CapacitaSchema, ConversioniSchema, LaneTypeOptions, PrioritaMagazziniSchema, SaturazioneSchema } from "../types";
import { MTColumnsType } from "@utils/pagination";
import { SelectOptionsSchema } from "@feature/commons/types";
// String Utils
import { checkIfStringIsPositiveNumber, checkIfStringNumberIsLessThan, checkStringLength } from "@feature/data-entry/lib";
// Components
import SearchSelect from "@ui-components/SearchSelect";


export const getAccatastamentoColumns = (
  intl: IntlShape,
): MTColumnsType<AccatastamentoSchema> => [
  {
    title: intl.formatMessage({ id: "cod_item" }),
    field: "cod_item",
    primary_key: true,
    editable: "never",
    type: "string",
  },
  {
    title: intl.formatMessage({ id: "flg_active" }),
    field: "flg_active",
    type: "boolean",
  },
  {
    title: intl.formatMessage({ id: "des_item" }),
    field: "des_item",
    editable: "never",
    type: "string",
  },
  {
    title: intl.formatMessage({ id: "des_accatastamento_default" }),
    field: "des_accatastamento_default",
    editable: "never",
    type: "string",
    lookup: AccatastamentoOptions,
    pagination: {
      spech: {
        column_name: ["des_accatastamento_default"],
        filter_operator: "in",
        value_parser: (value: string[]) => value
      }
    }
  },
  {
    title: intl.formatMessage({ id: "des_accatastamento_manuale" }),
    field: "des_accatastamento_manuale",
    type: "string",
    lookup: AccatastamentoOptions,
    pagination: {
      spech: {
        column_name: ["des_accatastamento_manuale"],
        filter_operator: "in",
        value_parser: (value: string[]) => value
      }
    },
    editComponent: (props) => (
      <SearchSelect
        value={props.value ? {value: props.value, label: props.value} : null}
        onChange={(value: SelectOptionsSchema) => props.onChange(value.value)}
        options={Object.keys(AccatastamentoOptions).map((key) => ({value: key, label: key}))}
      />
    ),
  },
]

export const getCapacitaColumns = (
  intl: IntlShape,
): MTColumnsType<CapacitaSchema> => [
  {
    title: intl.formatMessage({ id: "cod_warehouse" }),
    field: "cod_warehouse",
    primary_key: true,
    editable: "never",
    type: "string",
  },
  {
    title: intl.formatMessage({ id: "des_warehouse" }),
    field: "des_warehouse",
    editable: "never",
    type: "string",
  },
  {
    title: intl.formatMessage({ id: "cod_lane" }),
    field: "cod_lane",
    primary_key: true,
    editable: "never",
    type: "string",
  },
  {
    title: intl.formatMessage({ id: "des_lane" }),
    field: "des_lane",
    editable: "never",
    type: "string",
  },
  {
    title: intl.formatMessage({ id: "num_max_pallet" }),
    field: "num_max_pallet",
    editable: "onUpdate",
    type: "string",     // Numeric
    validate: (rowData: CapacitaSchema) => {
      if ((rowData.num_max_pallet?.toString() ?? "").includes('.')) {
        return intl.formatMessage({id: "must_be_integer"});
      }
      return checkIfStringIsPositiveNumber(rowData.num_max_pallet?.toString() ?? "", intl);
    },
  },
  {
    title: intl.formatMessage({ id: "cod_lane_type" }),
    field: "cod_lane_type",
    type: "string",
    lookup: LaneTypeOptions,
    pagination: {
      spech: {
        column_name: ["cod_lane_type"],
        filter_operator: "in",
        value_parser: (value: string[]) => value
      }
    },
    editComponent: (props) => (
      <SearchSelect
        value={props.value ? {value: props.value, label: props.value} : null}
        onChange={(value: SelectOptionsSchema) => props.onChange(value.value)}
        options={Object.keys(LaneTypeOptions).map((key) => ({value: key, label: key}))}
      />
    ),
  }
]

export const getConversioniColumns = (
  intl: IntlShape,
): MTColumnsType<ConversioniSchema> => [
  {
    title: intl.formatMessage({ id: "cod_item" }),
    field: "cod_item",
    primary_key: true,
    editable: "never",
    type: "string",
  },
  {
    title: intl.formatMessage({ id: "des_item" }),
    field: "des_item",
    editable: "never",
    type: "string",
  },
  {
    title: intl.formatMessage({ id: "des_um" }),
    field: "des_um",
    editable: "never",
    type: "string",
  },
  {
    title: intl.formatMessage({ id: "num_max_um_pallet" }),
    field: "num_max_um_pallet",
    editable: "onUpdate",
    type: "string",     // Numeric
    validate: (rowData: ConversioniSchema) => {
      return checkIfStringIsPositiveNumber(rowData.num_max_um_pallet?.toString() ?? "", intl);
    },
  }
]

export const getPrioritaMagazziniColumns = (
  intl: IntlShape,
  warehousesNumber?: number,
): MTColumnsType<PrioritaMagazziniSchema> => [
  {
    title: intl.formatMessage({ id: "cod_warehouse" }),
    field: "cod_warehouse",
    primary_key: true,
    editable: "never",
    type: "string",
  },
  {
    title: intl.formatMessage({ id: "des_warehouse" }),
    field: "des_warehouse",
    editable: "never",
    type: "string",
  },
  {
    title: intl.formatMessage({ id: "cod_family" }),
    field: "cod_family",
    primary_key: true,
    editable: "never",
    type: "string",
  },
  {
    title: intl.formatMessage({ id: "des_family" }),
    field: "des_family",
    editable: "never",
    type: "string",
  },
  {
    title: intl.formatMessage({ id: "cod_sub_family" }),
    field: "cod_sub_family",
    primary_key: true,
    editable: "never",
    type: "string",
  },
  {
    title: intl.formatMessage({ id: "des_sub_family" }),
    field: "des_sub_family",
    editable: "never",
    type: "string",
  },
  {
    title: intl.formatMessage({ id: "id_preference" }),
    field: "id_preference",
    editable: "onUpdate",
    type: "string",     // Numeric
    validate: (rowData: PrioritaMagazziniSchema) => {
      return checkIfStringIsPositiveNumber(rowData.id_preference?.toString() ?? "", intl);
    },
    editComponent: (props) => (
      <SearchSelect 
        value={props.value ? {value: props.value, label: props.value} : null}
        onChange={(value: SelectOptionsSchema) => props.onChange(value ? value.value : null)}
        options={
          warehousesNumber ? 
            Array.from({length: warehousesNumber}, (_, i) => i + 1).map((i) => ({value: i, label: i.toString()})) : // Array from 1 to warehousesNumber
            []
        }
        isClearable
      />
    ),
  }
]

export const getSaturazioneColumns = (
  intl: IntlShape,
): MTColumnsType<SaturazioneSchema> => [
  {
    title: intl.formatMessage({ id: "cod_warehouse" }),
    field: "cod_warehouse",
    primary_key: true,
    editable: "onAdd",
    type: "string",
    validate: (rowData: SaturazioneSchema) => {
      if (rowData.cod_warehouse === undefined || rowData.cod_warehouse === "") {
        return intl.formatMessage({id: "required"});
      }
      return checkStringLength(rowData.cod_warehouse, 10, intl);
    }
  },
  {
    title: intl.formatMessage({ id: "des_warehouse" }),
    field: "des_warehouse",
    editable: "onAdd",
    type: "string",
    validate: (rowData: SaturazioneSchema) => {
      if (rowData.des_warehouse === undefined || rowData.des_warehouse === "") {
        return intl.formatMessage({id: "required"});
      }
      return checkStringLength(rowData.des_warehouse, 50, intl);
    }
  },
  {
    title: intl.formatMessage({ id: "perc_saturazione" }),
    field: "perc_saturazione",
    type: "string",     // Numeric
    tooltip: intl.formatMessage({ id: "perc_saturazione_tooltip" }),
    validate: (rowData: SaturazioneSchema) => {
      if (rowData.perc_saturazione === undefined || rowData.perc_saturazione.toString() === "") {
        return intl.formatMessage({id: "required"});
      }

      const positive = checkIfStringIsPositiveNumber(rowData.perc_saturazione.toString(), intl);
      if (positive !== true) {
        return positive;
      }
      return checkIfStringNumberIsLessThan(rowData.perc_saturazione.toString(), 100, intl);
    },
  }
]