import {MTColumnsType} from "@utils/pagination";
import {BrokenRecordType, BrokenTablesTypesWithListSchema} from "@feature/commons/broken-tables/types";


export const getColumns = (BTtypes: BrokenTablesTypesWithListSchema['dict_types']): MTColumnsType<BrokenRecordType> =>
  Object.entries(BTtypes).map(([field, _type]) => ({
    title: field,
    field: field,
    type: _type
  }))

