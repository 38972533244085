import {useContext, useState} from "react";
import {NotificationsContext} from "@ui-components/Notifications";
import {useAsyncError} from "@hooks/useAsyncError";
import {Query} from "@material-table/core";
import {MTColumnsType} from "@utils/pagination";
import {DateFiltersType, EltType} from "@feature/commons/types";
import {ExcelDownload, fetchPagination} from "@feature/commons/lib";
import {dateToString} from "@utils/utils";

export function usePaginatedRuns<T extends object>(
	eltType: EltType,
	dateFilters: DateFiltersType,
	columns: MTColumnsType<T>,
) {
	// hooks
	const {push} = useContext(NotificationsContext);
	const throwError = useAsyncError();
	
	
	// state
	const [loading, setLoading] = useState<boolean>(false);
	
	const baseUrl = `/runs/${eltType}/paginated`;
	
	const additionalFilters = {
		date_start: dateToString(dateFilters[0]),
		date_end: dateToString(dateFilters[1])
	}
	
	const fetch = async (query: Query<T>) => fetchPagination(
		baseUrl,
		query,
		columns,
		undefined,
		additionalFilters,
		push,
		setLoading,
		throwError,
	)
	
	const download = async (name: string, query?: Query<T>, columnMappings?: { [key: string]: string }) => ExcelDownload(
		baseUrl,
		columns,
		name,
		query,
		columnMappings,
		setLoading,
		push,
		throwError,
		additionalFilters
	);
	
	return {fetch, download, loading};
}