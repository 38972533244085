import React from "react";
import {TypesLogoProps} from "./logo.types";

const Logo = ({height = "h-24", width = "w-auto", imagePath = "/ANAGRAM_CREAM.svg", classNames = ""}: TypesLogoProps) =>
  <div className="flex-shrink-0 flex items-center px-4">
    <img
      className={`${height} ${width} mx-auto fill-white ${classNames}`}
      src={process.env.PUBLIC_URL + imagePath}
      alt="ammagamma logo"
    />
  </div>;

export default Logo;
