import {useEffect, useState} from "react";

function Counter({number, duration}: { number: number, duration: number }) {

  //*
  // number: number to count to
  // duration: total duration of animation in seconds
  //*

  // number displayed by component
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    // if zero, return
    if (start === number) return;

    // find duration per increment
    const incrementTime = (duration / number) * 1000;

    // timer increments start counter
    // then updates count
    // ends if start reaches end
    const timer = setInterval(() => {
      start += 1;
      setCount(start)
      if (start === number) clearInterval(timer)
    }, incrementTime);

    // cleanup
    return () => clearInterval(timer);

    // dependency array
  }, [number, duration]);

  return (
    <span>{count}</span>
  );
}

export default Counter;