import {useContext, useEffect, useState} from "react";
import {KpiBodySchema, KpiWithIdSchema} from "../types";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";
import {useAsyncError} from "@hooks/useAsyncError";
import {NotificationsContext} from "@ui-components/Notifications";
import {EltType} from "@feature/commons/types";

type T = KpiWithIdSchema[];
export const useFetchKPIs = (
	runId: number,
	eltType: EltType,
	kpi_payload: KpiBodySchema
) => {
	
	// context
	const {push} = useContext(NotificationsContext);
	
	// hooks
	const intl = useIntl();
	const throwError = useAsyncError();
	
	// state
	const [loading, setLoading] = useState<boolean>(false);
	const [kpi, setKpi] = useState<T>();
	
	useEffect(() => {
		(async () => {
			if (kpi_payload.dateLimitsFirstRender) return;
			else {
				setLoading(true);
				try {
					const {data} = await api.post<T>(`/kpis/${runId}/${eltType}/kpis`, kpi_payload);
					setKpi(data)
				} catch (e) {
					push({type: 'error', title: intl.formatMessage({id: 'generic_error'})});
					throwError(e);
				} finally {
					setLoading(false);
				}
			}
		})();
	}, [intl, eltType, JSON.stringify(kpi_payload), push, runId, throwError]); // eslint-disable-line react-hooks/exhaustive-deps
	
	return {loading, kpi};
}