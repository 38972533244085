import {useParams} from "react-router";
import {useIntl} from "react-intl";
import {useState} from "react";
import {PageHeading} from "@ui-components/Container";
import Button from "@ui-components/Button";
import {BrokenTable} from "@feature/commons/broken-tables";
import {SchedulationDetails} from "@feature/sched";
import {RunDetailsVisualizationsSched as RDVS, SchedulingSummaryProps} from "@feature/sched/types";
import {EltType} from "@feature/commons/types";
// import ProductValue from "../product-value/product-value";
// import AuthenticationService from "@services/AuthenticationService";


export function SchedSummary({
	title,
	homeRunId,
	client_vs_deadline_param,
	energy_vs_waste_param,
}: SchedulingSummaryProps) {
	
	const {id_run} = useParams<{ id_run?: string }>();
	
	const intl = useIntl();
	
	const schedulingSummaryTitle = intl.formatMessage({id: "schedule_details"})
	
	const [currentVisualization, setCurrentVisualization] = useState<RDVS>(RDVS.schedule_details);
	
	const renderVisualization = (currentViz: RDVS) => {
		switch (currentViz) {
			case RDVS.schedule_details:
				return <SchedulationDetails
					homeRunId={homeRunId}
					client_vs_deadline_param={client_vs_deadline_param}
					energy_vs_waste_param={energy_vs_waste_param}
				/>
			case RDVS.orders_not_scheduled:
				return <BrokenTable 
					elt_type={EltType.sched}
					homeRunId={homeRunId}
				/>
			// case RDVS.product_value:
			// 	return <ProductValue
			// 		homeRunId={homeRunId}
			// 	/>
		}
	}

	const _renderContentActions = () => {
		return (
			<div className="flex flex-row h-16 gap-x-4 items-center">
				{
					Object.keys(RDVS).map(b => {
						/* Valore Prodotto Button is hidden for roles that do not have the right scopes (only "warehouse_user" role at the moment) */
						// if (b === RDVS.product_value && !AuthenticationService.getUserScopes().includes("task_control:read")) {
						// 	return null;
						// }
						return (
							<Button
								key={b}
								styleType={b === currentVisualization ? "default" : "white"}
								onClick={() => setCurrentVisualization(b as RDVS)}
								children={intl.formatMessage({id: b})}
							/>
						)
					})
				}
			</div>
		)
	}
	
	return (
		<div className="flex flex-col gap-y-4">
			<PageHeading 
				title={`${ id_run ? `${schedulingSummaryTitle}: ${id_run}` : title }`}
				contentActions={_renderContentActions()} 
				historyBackPath={id_run ? "/runs_scheduling" : undefined}
			/>
			{
				renderVisualization(currentVisualization)
			}
		</div>
	)
}