const anagram_params = {
  CLIENT: "customer priority",
  DEADLINE: "deadline priority",
  ENERGY: "machineries amortization",
  WASTE: "waste reduction",
  BALANCED: "balanced",
}


const data_entry_tabs = {
  alto_vendenti: "High Sellers",
  articoli_approvati: "Approved Articles",
  articolo_pose_risorse: "Resource Choice",
  clienti: "Customers",
  lista_macchine: "Machine List",
  manutenzioni: "Scheduled Stops",
  ordini: "Orders",
  ordini_simulati: "Simulated Orders",
  parametri: "Parameters",
  produzioni: "Productions",
}

export const anagram_en = {
  launch_plan: "Launch New Planning Run",
  launch_sched: "Launch New Scheduling Run",
  plan_title: "Planning Title",
  sched_title: "Scheduling Title",
  add_plan: "Add Planning",
  add_sched: "Add Scheduling",
  anagram_params: "Anagram Parameters",
  users_displayed_record: "{from}-{to} of {count} record",
  empty_table: "No records to display",
  title: "Title",
  success: "Operation Completed Successfully",
  generic_error: "An Error Occurred, please contact support",
  working_center: "Working Center",
  apply: "Apply",
  show_inserted_params: "Deadlines & Waste Priorities",
  select_working_center: "Please select a working center",
  role_read_not_enabled: "Roles read not enabled",
  entity_task_control: "Management of control tasks in the system",
  executing: "Executing",
  new_start_time: "New Start Time",
  new_end_time: "New End Time",
  ...anagram_params,
    ...data_entry_tabs
}