import {IntlShape} from "react-intl";
import {MTColumnsType} from "@utils/pagination";
import {
  AltoVendentiSchema,
  ArticoliApprovatiSchema,
  ArticoloPoseRisorseSchema,
  ClientiSchema,
  CodiceImpilatore,
  CombinationsSchema,
  GenericDataEntrySchema,
  ListaMacchineSchema,
  ManutenzioniSchema,
  OrdiniSchema,
  OrdiniSimulatiSchema,
  ProduzioniSchema,
  RepartiSchema,
  TipologiaMacchina
} from "@feature/data-entry/types";
import Badge from "@ui-components/Badge";
import SearchSelect from "@ui-components/SearchSelect";
import {checkIfNotEmpty, checkIfStringIsPositiveNumber} from "@feature/data-entry/lib";
import {CustomEditDatePicker, CustomFilterDatePicker} from "@components/Tables/Params/CustomDatePicker";
import {timestampStringConverter} from "@utils/pagination/filters";
import {SelectOptionsSchema} from "@feature/commons/types";
import Tooltip from "@ui-components/Tooltip";
import moment from "moment";
import Placeholder from "@ui-components/Placeholder";
import { getAccatastamentoColumns, getCapacitaColumns, getConversioniColumns, getSaturazioneColumns } from "@feature/warehouse/data-entry/params";

/*
  Some columns (es. "numeric") are set to "string" type because to uniform the alignment and filtering system of all columns.
  Every of these columns will have a comment with the correct type.
*/


const getCommonColumns = <T extends GenericDataEntrySchema>(intl: IntlShape): MTColumnsType<T> => [
  {
    title: "id",
    field: "id",
    hidden: true
  },
  {
    title: intl.formatMessage({id: "revised"}),
    field: "flg_modified",
    type: "boolean",
    editable: "never",
  }
]

const getAltoVendentiColumns = (
  intl: IntlShape,
  combinations: CombinationsSchema[] | undefined,
  customersOptions: ClientiSchema[] | undefined,
): MTColumnsType<AltoVendentiSchema> => [
  {
    title: intl.formatMessage({id: "cod_article"}),
    field: "cod_parte",
    editComponent: props => (
      <SearchSelect
        classNames="w-64"
        value={props.value ? {label: props.value, value: props.value} : null}
        onChange={(value: SelectOptionsSchema) => props.onChange(value.value)}
        options={combinations?.map(c => ({label: c.cod_parte, value: c.cod_parte})) ?? []}
      />
    ),
    validate: (rowData: AltoVendentiSchema) => checkIfNotEmpty(rowData.cod_parte, intl),
    primary_key: true,
    pagination: {
      spech: {
        column_name: ["cod_parte"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    },
  },
  {
    title: intl.formatMessage({id: "customer"}),
    field: "des_cliente",
    pagination: {
      spech: {
        column_name: ["des_cliente"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    },
    editComponent: props => {
      return (
        <>
          {
            customersOptions ?
              <SearchSelect
                classNames="w-64"
                value={props.value ? {label: props.value, value: props.value} : null}
                onChange={(value: SelectOptionsSchema) => props.onChange(value.value)}
                options={customersOptions.map(c => ({label: c.des_cliente, value: c.des_cliente}))}
              /> :
              <Placeholder
                width="w-full"
                height="h-10"
              />
          }
        </>
      )
    },
    validate: (rowData: AltoVendentiSchema) => checkIfNotEmpty(rowData.des_cliente, intl),
    primary_key: true,
  },
  {
    title: intl.formatMessage({id: "first_tranche_date"}),
    field: "dat_prima_tranche",
    type: "date",
    filterComponent: (props) => <CustomFilterDatePicker {...props}/>,
    editComponent: (props) => <CustomEditDatePicker {...props}/>,
    render: rowData => moment(rowData.dat_prima_tranche).format("DD/MM/YYYY"),
    primary_key: true,
  },
  {
    title: intl.formatMessage({id: "cod_resource"}),
    field: "cod_risorsa",
    render: rowData => rowData.cod_risorsa.map(
      r => <Badge text={r}/>
    ),
    editComponent: props => (
      <SearchSelect
        classNames="w-64"
        value={props.value?.map((el: string) => ({label: el, value: el}))}
        onChange={(value: SelectOptionsSchema[]) => props.onChange(value.map((el: {
          label: string,
          value: string
        }) => el.value))}
        isMulti
        options={
          combinations?.find(c => c.cod_parte === props.rowData.cod_parte)?.set_cod_risorse.map(
            r => ({label: r, value: r})
          ) ?? []
        }
      />
    )
  },
  {
    title: intl.formatMessage({id: "val_quantita_tot"}),
    field: "val_quantita_tot",
    type: "string",     // Numeric
    validate: (rowData: AltoVendentiSchema) => {
      if (rowData.val_quantita_tot?.toString() === "") {
        return intl.formatMessage({id: "required"});
      }
      return checkIfStringIsPositiveNumber(rowData.val_quantita_tot?.toString(), intl);
    },
  },
  {
    title: intl.formatMessage({id: "first_tranche_quantity"}),
    field: "val_quantita_prima_tranche",
    type: "string",     // Numeric
    validate: (rowData: AltoVendentiSchema) => {
      if (rowData.val_quantita_prima_tranche?.toString() === "") {
        return intl.formatMessage({id: "required"});
      }
      return checkIfStringIsPositiveNumber(rowData.val_quantita_prima_tranche?.toString(), intl);
    },
  },
  {
    title: intl.formatMessage({id: "other_tranche_quantity"}),
    field: "val_quantita_altre_tranche",
    type: "string",     // Numeric
    validate: (rowData: AltoVendentiSchema) => {
      if (rowData.val_quantita_altre_tranche?.toString() === "") {
        return intl.formatMessage({id: "required"});
      }
      return checkIfStringIsPositiveNumber(rowData.val_quantita_altre_tranche?.toString(), intl);
    },
  },
  {
    title: intl.formatMessage({id: "work_days"}),
    field: "num_work_days",
    type: "string",     // Numeric
    validate: (rowData: AltoVendentiSchema) => {
      if (rowData.num_work_days?.toString() === "") {
        return intl.formatMessage({id: "required"});
      }
      return checkIfStringIsPositiveNumber(rowData.num_work_days?.toString(), intl);
    }
  },
  {
    title: intl.formatMessage({id: "last_tranche_date"}),
    field: "dat_last_tranche",
    type: "string",     // Date
    filterComponent: (props) => <CustomFilterDatePicker {...props}/>,
    editComponent: (props) => <CustomEditDatePicker {...props}/>,
    editable: "onUpdate",
    cellStyle: {textAlign: "center"},
    render: rowData => <div className="flex items-start">
      {
        rowData.dat_last_tranche ?
          new Intl.DateTimeFormat(intl.locale).format(new Date(rowData.dat_last_tranche))
          : <Tooltip
              text={
                intl.formatMessage({id: "last_tranche_date_tooltip"})
              }
              size="small"
              placement="top"
            />
      }
    </div>
  },
  {
    title: intl.formatMessage({id: "status"}),
    field: "flg_attivo",
    type: "boolean",
    editable: "never",
    initialEditValue: true,
  }
]

const getArticoliApprovatiColumns = (intl: IntlShape): MTColumnsType<ArticoliApprovatiSchema> => [
  {
    title: intl.formatMessage({id: "cod_article"}),
    field: "cod_parte",
    editable: "never",
    pagination: {
      spech: {
        column_name: ["cod_parte"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    },
    primary_key: true,
  },
  {
    title: intl.formatMessage({id: "approved"}),
    field: "flg_approvato",
    type: "boolean",
  }
]

const getArticoloPoseRisorseColumns = (intl: IntlShape, machineOptions: SelectOptionsSchema[] | undefined): MTColumnsType<ArticoloPoseRisorseSchema> => [
  {
    title: intl.formatMessage({id: "cod_article"}),
    field: "cod_parte",
    editable: "never",
    primary_key: true,
    pagination: {
      spech: {
        column_name: ["cod_parte"],
        filter_operator: "ilike",
        value_parser (value) {
          return `%${value}%`
        },
      }
    },
  },
  {
    title: "pose",
    field: "val_pose",
    type: "string",     // Numeric,
    editable: "never",
    primary_key: true,
  },
  {
    title: intl.formatMessage({id: "resources"}),
    field: "set_cod_risorse",
    render: rowData => rowData.set_cod_risorse.map(
      r => <Badge text={r}/>
    ),
    editComponent: props => (
      <SearchSelect
        classNames="w-64"
        value={props.value?.map((el: string) => ({label: el, value: el}))}
        isMulti
        onChange={(value: SelectOptionsSchema[]) => props.onChange(value.map((el: {
          label: string,
          value: string
        }) => el.value))}
        options={machineOptions ?? []}
      />
    ),
    pagination: {
      spech: {
        column_name: "set_cod_risorse",
        filter_operator: "in_array",
      },
    },
  },
]

const getClientiColumns = (intl: IntlShape): MTColumnsType<ClientiSchema> => [
  {
    title: intl.formatMessage({id: "customer"}),
    field: "des_cliente",
    pagination: {
      spech: {
        column_name: ["des_cliente"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    },
    primary_key: true,
  },
  {
    title: intl.formatMessage({id: "priority"}),
    field: "priorita",
    type: "string",     // Numeric,
    validate: (rowData: ClientiSchema) => {
      const checkValue = checkIfStringIsPositiveNumber(rowData.priorita?.toString(), intl);
      if (checkValue !== true) {
        return checkValue;
      }

      return rowData.priorita < 1 || rowData.priorita > 5 ?
        intl.formatMessage({id: "priority_range"}) :
        true;
    },
  }
]


const getListaMacchineColumns = (intl: IntlShape): MTColumnsType<ListaMacchineSchema> => [
  {
    title: intl.formatMessage({id: "cod_resource"}),
    field: "cod_risorsa",
    pagination: {
      spech: {
        column_name: ["cod_risorsa"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    },
    primary_key: true,
  },
  {
    title: intl.formatMessage({id: "status"}),
    field: "flg_attiva",
    type: "boolean",
  },
  {
    title: intl.formatMessage({id: "type"}),
    field: "cod_tipologia",
    lookup: TipologiaMacchina,
    pagination: {
      spech: {
        column_name: ["cod_tipologia"],
        filter_operator: "in",
        value_parser: (value: string[]) => value
      }
    }
  },
  {
    title: intl.formatMessage({id: "machine_serial_number"}),
    field: "cod_matricola",
    pagination: {
      spech: {
        column_name: ["cod_matricola"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    }
  },
  {
    title: intl.formatMessage({id: "year"}),
    field: "val_anno_acquisto",
    type: "string",     // Numeric,
  },
  {
    title: intl.formatMessage({id: "department"}),
    field: "cod_reparto",
    pagination: {
      spech: {
        column_name: ["cod_reparto"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    }
  },
  {
    title: intl.formatMessage({id: "max_length"}),
    field: "val_lunghezza_max",
    type: "string",     // Numeric,
  },
  {
    title: intl.formatMessage({id: "max_width"}),
    field: "val_larghezza_max",
    type: "string",     // Numeric,
  },
  {
    title: intl.formatMessage({id: "balloon"}),
    field: "flg_pallone",
    type: "boolean",
  },
  {
    title: intl.formatMessage({id: "stacker"}),
    field: "cod_impilatore",
    render: rowData => rowData.cod_impilatore ? <Badge type="success" text={rowData.cod_impilatore}/> : null,
    pagination: {
      spech: {
        column_name: ["cod_impilatore"],
        filter_operator: "in",
        value_parser(value: string | string[]) {
          if (typeof(value) === "object") {
            return value.map((v: string) => CodiceImpilatore[v as keyof typeof CodiceImpilatore])
          } else {
            return `[${CodiceImpilatore[value as keyof typeof CodiceImpilatore]}]`
          }
        },
      }
    },
    lookup: CodiceImpilatore,
  },
  {
    title: intl.formatMessage({id: "min_speed"}),
    field: "val_vel_min",
    type: "string",     // Numeric,
  },
  {
    title: intl.formatMessage({id: "max_speed"}),
    field: "val_vel_max",
    type: "string",     // Numeric,
  },
  {
    title: intl.formatMessage({id: "cost"}),
    field: "val_costo",
    type: "string",     // Numeric,
  }
]

const getManutenzioniColumns = (
  intl: IntlShape,
  machineOptions: SelectOptionsSchema[] | undefined,
): MTColumnsType<ManutenzioniSchema> => [
  {
    title: intl.formatMessage({id: "cod_resource"}),
    field: "cod_risorsa",
    pagination: {
      spech: {
        column_name: ["cod_risorsa"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    },
    editComponent: props => (
      <SearchSelect
        classNames="w-64"
        value={props.value ? {label: props.value, value: props.value} : null}
        onChange={(value: SelectOptionsSchema) => props.onChange(value.value)}
        options={machineOptions ?? []}
      />
    ),
    primary_key: true,
  },
  {
    title: intl.formatMessage({id: "maintenance_start_date"}),
    field: "tms_inizio_manutenzione",
    type: "string",
    editComponent: (props) => <CustomEditDatePicker {...props} showTimePicker/>,
    render: rowData => moment(rowData.tms_inizio_manutenzione).format("DD/MM/YYYY HH:mm:ss"),
    pagination: {
      spech: {
        column_name: ["tms_inizio_manutenzione"],
        filter_operator: "ilike",
        value_parser: (value) => timestampStringConverter(value),
      }
    },
    primary_key: true,
  },
  {
    title: intl.formatMessage({id: "user"}),
    field: "user_mail",
    editable: "never",
    sorting: false,
    pagination: {
      spech: {
        column_name: ["utente.email"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    }
  },
  {
    title: intl.formatMessage({id: "maintenance_end_date"}),
    field: "tms_fine_manutenzione",
    type: "string",
    editComponent: (props) => <CustomEditDatePicker {...props} showTimePicker/>,
    render: rowData => moment(rowData.tms_fine_manutenzione).format("DD/MM/YYYY HH:mm:ss"),
    pagination: {
      spech: {
        column_name: ["tms_fine_manutenzione"],
        filter_operator: "ilike",
        value_parser: (value) => timestampStringConverter(value),
      }
    },
  },
  {
    title: intl.formatMessage({id: "category"}),
    field: "category",
    pagination: {
      spech: {
        column_name: ["category"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    }
  }
]

const getOrdiniColumns = (intl: IntlShape): MTColumnsType<OrdiniSchema> => [
  {
    title: intl.formatMessage({id: "cod_order"}),
    field: "cod_ordine",
    editable: "never",
    pagination: {
      spech: {
        column_name: ["cod_ordine"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    },
    primary_key: true,
  },
  {
    title: intl.formatMessage({id: "cod_article"}),
    field: "cod_parte",
    editable: "never",
    pagination: {
      spech: {
        column_name: ["cod_parte"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    }
  },
  {
    title: intl.formatMessage({id: "num_expected_poses"}),
    field: "val_pose_previsto",
    type: "string",     // Numeric
    editable: "never"
  },
  {
    title: intl.formatMessage({id: "expected_cycles_per_hour"}),
    field: "val_battute_ora_previste",
    type: "string",     // Numeric
    editable: "never"
  },
  {
    title: intl.formatMessage({id: "customer"}),
    field: "des_cliente",
    editable: "never",
    pagination: {
      spech: {
        column_name: ["des_cliente"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    }
  },
  {
    title: "MTEC",
    field: "des_mtec",
    editable: "never",
    pagination: {
      spech: {
        column_name: ["des_mtec"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    }
  },
  {
    title: intl.formatMessage({id: "order_insertion_date"}),
    field: "dat_inserimento",
    type: "date",
    filterComponent: (props) => <CustomFilterDatePicker {...props}/>,
    editComponent: (props) => <CustomEditDatePicker {...props}/>,
    editable: "never",
    render: rowData => moment(rowData.dat_inserimento).format("DD/MM/YYYY"),
  },
  {
    title: intl.formatMessage({id: "raw_material_code"}),
    field: "cod_materia_prima",
    editable: "never",
    pagination: {
      spech: {
        column_name: ["cod_materia_prima"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    }
  },
  {
    title: intl.formatMessage({id: "cod_resource"}),
    field: "cod_risorsa",
    editable: "never",
    pagination: {
      spech: {
        column_name: ["cod_risorsa"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    }
  },
  {
    title: intl.formatMessage({id: "ordered_pieces"}),
    field: "val_pezzi_ordinati",
    type: "string",     // Numeric
    editable: "never",
  },
  {
    title: intl.formatMessage({id: "valid_pieces"}),
    field: "val_pezzi_buoni",
    type: "string",     // Numeric
    editable: "never"
  },
  {
    title: intl.formatMessage({id: "deposit"}),
    field: "flg_acconto",
    type: "boolean"
  },
  {
    title: intl.formatMessage({id: "third_party_contractor"}),
    field: "flg_terzista",
    type: "boolean"
  },
  {
    title: intl.formatMessage({id: "status"}),
    field: "flg_attivo",
    type: "boolean",
  },
  {
    title: intl.formatMessage({id: "delivery_date"}),
    field: "dat_consegna",
    type: "date",
    filterComponent: (props) => <CustomFilterDatePicker {...props}/>,
    editComponent: (props) => <CustomEditDatePicker {...props}/>,
    render: rowData => moment(rowData.dat_consegna).format("DD/MM/YYYY"),
  },
  {
    title: intl.formatMessage({id: "is_order_mandatory"}),
    field: "flg_forzatura",
    type: "boolean"
  },
  {
    title: intl.formatMessage({id: "necessary_raw_material"}),
    field: "qta_materia_prima_necessaria",
    type: "string",     // Numeric
    validate: rowData => {
      if (rowData.qta_materia_prima_necessaria?.toString() === "") {
        return intl.formatMessage({id: "required"});
      }
      return checkIfStringIsPositiveNumber(rowData.qta_materia_prima_necessaria?.toString(), intl);
    },
  },
  {
    title: intl.formatMessage({id: "raw_material_arrival_date"}),
    field: "dat_arrivo_materia_prima",
    type: "date",
    filterComponent: (props) => <CustomFilterDatePicker {...props}/>,
    editComponent: (props) => <CustomEditDatePicker {...props} clearable/>,
    render: rowData => rowData.dat_arrivo_materia_prima ? moment(rowData.dat_arrivo_materia_prima).format("DD/MM/YYYY") : null,
  },
  {
    title: intl.formatMessage({id: "first_tranche_quantity"}),
    field: "first_payment_tranche_quantity",
    type: "string",     // Numeric
    filtering: false,
    sorting: false,
    validate: rowData => {
      if (rowData.first_payment_tranche_quantity?.toString() === "") {
        rowData.first_payment_tranche_quantity = undefined;
        return true;
      }
      return checkIfStringIsPositiveNumber(rowData.first_payment_tranche_quantity?.toString() ?? "", intl);
    },
  },
  {
    title: intl.formatMessage({id: "first_tranche_date"}),
    field: "first_payment_tranche_date",
    type: "date",
    filtering: false,
    sorting: false,
    filterComponent: (props) => <CustomFilterDatePicker {...props}/>,
    editComponent: (props) => <CustomEditDatePicker {...props} clearable/>,
    render: rowData => rowData.first_payment_tranche_date ? moment(rowData.first_payment_tranche_date).format("DD/MM/YYYY") : null,
  },
  {
    title: intl.formatMessage({id: "second_tranche_quantity"}),
    field: "second_payment_tranche_quantity",
    filtering: false,
    sorting: false,
    type: "string",     // Numeric
    validate: rowData => {
      if (rowData.second_payment_tranche_quantity?.toString() === "") {
        rowData.second_payment_tranche_quantity = undefined;
        return true;
      }
      return checkIfStringIsPositiveNumber(rowData.second_payment_tranche_quantity?.toString() ?? "", intl);
    }
  },
  {
    title: intl.formatMessage({id: "second_tranche_date"}),
    field: "second_payment_tranche_date",
    type: "date",
    filtering: false,
    sorting: false,
    filterComponent: (props) => <CustomFilterDatePicker {...props}/>,
    editComponent: (props) => <CustomEditDatePicker {...props} clearable/>,
    render: rowData => rowData.second_payment_tranche_date ? moment(rowData.second_payment_tranche_date).format("DD/MM/YYYY") : null,
  }
]

const getOrdiniSimulatiColumns = (
  intl: IntlShape,
  combinations: CombinationsSchema[] | undefined,
  customersOptions: ClientiSchema[] | undefined,
): MTColumnsType<OrdiniSimulatiSchema> => [
  {
    title: intl.formatMessage({id: "cod_order"}),
    field: "cod_ordine",
    editable: "onAdd",
    validate: rowData => checkIfNotEmpty(rowData.cod_ordine, intl),
    pagination: {
      spech: {
        column_name: ["cod_ordine"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    },
    primary_key: true,
  },
  {
    title: `${intl.formatMessage({id: "cod_article"})} / MTEC`,
    field: "cod_parte",
    validate: rowData => checkIfNotEmpty(rowData.cod_parte, intl),
    pagination: {
      spech: {
        column_name: ["cod_parte"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    },
    editComponent: props => (
      <SearchSelect
        classNames="w-64"
        value={props.value ? {label: props.value, value: props.value} : null}
        onChange={(value: SelectOptionsSchema) => props.onChange(value.value)}
        options={combinations?.map(c => ({label: c.cod_parte, value: c.cod_parte})) ?? []}
      />
    )
  },
  {
    title: intl.formatMessage({id: "num_expected_poses"}),
    field: "val_pose_previsto",
    type: "string",     // Numeric
    validate: rowData =>  {
      if (rowData.val_pose_previsto?.toString() === "") {
        return intl.formatMessage({id: "required"});
      }
      return checkIfStringIsPositiveNumber(rowData.val_pose_previsto?.toString(), intl);
    }
  },
  {
    title: intl.formatMessage({id: "ordered_pieces"}),
    field: "val_pezzi_ordinati",
    type: "string",     // Numeric
    editable: "onAdd",
    validate: rowData => {
      if (rowData.val_pezzi_ordinati?.toString() === "") {
        return intl.formatMessage({id: "required"});
      }
      return checkIfStringIsPositiveNumber(rowData.val_pezzi_ordinati?.toString(), intl);
    }
  },
  {
    title: intl.formatMessage({id: "order_insertion_date"}),
    field: "dat_inserimento",
    type: "date",
    filterComponent: (props) => <CustomFilterDatePicker {...props}/>,
    editComponent: (props) => <CustomEditDatePicker {...props}/>,
    editable: "never",
    render: rowData => moment(rowData.dat_inserimento).format("DD/MM/YYYY"),
  },
  {
    title: intl.formatMessage({id: "delivery_date"}),
    field: "dat_consegna",
    type: "date",
    filterComponent: (props) => <CustomFilterDatePicker {...props}/>,
    editComponent: (props) => <CustomEditDatePicker {...props}/>,
    editable: "onAdd",
    validate: rowData => checkIfNotEmpty(rowData.dat_consegna, intl),
    render: rowData => moment(rowData.dat_consegna).format("DD/MM/YYYY"),
  },
  {
    title: intl.formatMessage({id: "customer"}),
    field: "des_cliente",
    editable: "onAdd",
    validate: rowData => checkIfNotEmpty(rowData.des_cliente, intl),
    pagination: {
      spech: {
        column_name: ["des_cliente"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    },
    editComponent: props => {
      return (
        <>
          {
            customersOptions ?
              <SearchSelect
                classNames="w-64"
                value={props.value ? {label: props.value, value: props.value} : null}
                onChange={(value: SelectOptionsSchema) => props.onChange(value.value)}
                options={customersOptions.map(c => ({label: c.des_cliente, value: c.des_cliente}))}
              /> :
              <Placeholder
                width="w-full"
                height="h-10"
              />
          }
        </>
      )
    }
  },
  {
    title: intl.formatMessage({id: "raw_material_code"}),
    field: "cod_materia_prima",
    validate: rowData => checkIfNotEmpty(rowData.cod_materia_prima, intl),
    pagination: {
      spech: {
        column_name: ["cod_materia_prima"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    }
  },
  {
    title: intl.formatMessage({id: "necessary_raw_material"}),
    field: "qta_materia_prima_necessaria",
    type: "string",     // Numeric
    validate: rowData => {
      if (rowData.qta_materia_prima_necessaria?.toString() === "") {
        return intl.formatMessage({id: "required"});
      }
      return checkIfStringIsPositiveNumber(rowData.qta_materia_prima_necessaria?.toString(), intl);
    }
  },
  {
    title: intl.formatMessage({id: "raw_material_arrival_date"}),
    field: "dat_arrivo_materia_prima",
    type: "date",
    filterComponent: (props) => <CustomFilterDatePicker {...props}/>,
    editComponent: (props) => <CustomEditDatePicker {...props}/>,
    render: rowData => moment(rowData.dat_arrivo_materia_prima).format("DD/MM/YYYY"),
  },
  {
    title: intl.formatMessage({id: "status"}),
    field: "flg_attivo",
    type: "boolean",
    validate: rowData => checkIfNotEmpty(rowData.flg_attivo, intl),
    editable: "never",
    initialEditValue: true,
  },
]


const getProduzioniColumns = (intl: IntlShape): MTColumnsType<ProduzioniSchema> => [
  {
    title: intl.formatMessage({id: "cod_article"}),
    field: "cod_parte",
    editable: "never",
    pagination: {
      spech: {
        column_name: ["cod_parte"],
        filter_operator: "ilike",
        value_parser: value => `%${value}%`
      }
    },
    primary_key: true,
  },
  {
    title: intl.formatMessage({id: "poses"}),
    field: "val_pose",
    type: "string",     // Numeric,
    editable: "never",
    primary_key: true,
  },
  {
    title: intl.formatMessage({id: "category"}),
    field: "cod_tipologia",
    lookup: TipologiaMacchina,
    pagination: {
      spech: {
        column_name: ["cod_tipologia"],
        filter_operator: "in",
        value_parser: (value: string[]) => value
      }
    }
  },
  {
    title: intl.formatMessage({id: "allowed_on_resource_3"}),
    field: "flg_risorsa_3",
    type: "boolean"
  },
  {
    title: intl.formatMessage({id: "mold_bell_length"}),
    field: "val_lunghezza",
    type: "string",     // Numeric
    validate: (rowData: ProduzioniSchema) => {
      if (rowData.val_lunghezza?.toString() === "") {
        return intl.formatMessage({id: "required"});
      }
      return checkIfStringIsPositiveNumber(rowData.val_lunghezza?.toString(), intl);
    }
  },
  {
    title: intl.formatMessage({id: "mold_bell_width"}),
    field: "val_larghezza",
    type: "string",     // Numeric
    validate: (rowData: ProduzioniSchema) => {
      if (rowData.val_larghezza?.toString() === "") {
        return intl.formatMessage({id: "required"});
      }
      return checkIfStringIsPositiveNumber(rowData.val_larghezza?.toString(), intl);
    }
  },
  {
    title: intl.formatMessage({id: "balloon"}),
    field: "flg_pallone",
    type: "boolean"
  },
  {
    title: intl.formatMessage({id: "stacker"}),
    field: "cod_impilatore",
    render: rowData => rowData.cod_impilatore ? <Badge type="success" text={rowData.cod_impilatore}/> : null,
    pagination: {
      spech: {
        column_name: ["cod_impilatore"],
        filter_operator: "in",
        value_parser(value: string | string[]) {
          if (typeof(value) === "object") {
            return value.map((v: string) => CodiceImpilatore[v as keyof typeof CodiceImpilatore])
          } else {
            return `[${CodiceImpilatore[value as keyof typeof CodiceImpilatore]}]`
          }
        },
      }
    },
    lookup: CodiceImpilatore,
  }
]


const reorderCommonColumnsAfterPrimaryKeys = <T extends GenericDataEntrySchema>(
  customColumns: MTColumnsType<T>,
  commonColumns: MTColumnsType<T>,
): MTColumnsType<T> => {
  /*
    RM request: for every DTN table, reorder FE columns putting PK columns first,
    common columns "Stato" and/or "Revisionato" second, other custom columns after
  */
  const primaryKeys = customColumns.filter(c => c.primary_key);
  const otherColumns = customColumns.filter(c => !c.primary_key);
  return primaryKeys.concat(commonColumns).concat(otherColumns);
}

const getRepartiColumns = (intl: IntlShape): MTColumnsType<RepartiSchema> => [
  {
    title: intl.formatMessage({id: "department"}),
    field: "cod_reparto",
    pagination: {
      spech: {
        column_name: ["cod_reparto"],
        filter_operator: "ilike",
        value_parser(value) {
          return `%${value}%`
        },
      }
    },
    primary_key: true,
  },
  {
    title: intl.formatMessage({id: "setups_max_number"}),
    field: "num_operatori",
    type: "string",     // Numeric
  }
]


export const getColumns = <T extends GenericDataEntrySchema>(
  table_name: string,
  machineOptions: SelectOptionsSchema[] | undefined,
  combinations: CombinationsSchema[] | undefined,
  customersOptions: ClientiSchema[] | undefined,
  intl: IntlShape,
): MTColumnsType<T> => {
  let columns: MTColumnsType<T> = []

  switch (table_name) {
    case "alto_vendenti":
      columns = getAltoVendentiColumns(intl, combinations, customersOptions) as MTColumnsType<T>;
      break;
    case "articoli_approvati":
      columns = getArticoliApprovatiColumns(intl) as MTColumnsType<T>;
      break;
    case "articolo_pose_risorse":
      columns = getArticoloPoseRisorseColumns(intl, machineOptions) as MTColumnsType<T>;
      break;
    case "clienti":
      columns = getClientiColumns(intl) as MTColumnsType<T>;
      break;
    case "lista_macchine":
      columns = getListaMacchineColumns(intl) as MTColumnsType<T>;
      break;
    case "manutenzioni":
      columns = getManutenzioniColumns(intl, machineOptions) as MTColumnsType<T>;
      break;
    case "ordini":
      columns = getOrdiniColumns(intl) as MTColumnsType<T>;
      break;
    case "ordini_simulati":
      columns = getOrdiniSimulatiColumns(intl, combinations, customersOptions) as MTColumnsType<T>;
      break;
    case "produzioni":
      columns = getProduzioniColumns(intl) as MTColumnsType<T>;
      break;
    case "reparti":
      columns = getRepartiColumns(intl) as MTColumnsType<T>;
      break;
    
    // DataEntry Magazzino
    case "accatastamento":
      columns = getAccatastamentoColumns(intl) as MTColumnsType<T>;
      break;
    case "capacita":
      columns = getCapacitaColumns(intl) as MTColumnsType<T>;
      break;
    case "conversioni":
      columns = getConversioniColumns(intl) as MTColumnsType<T>;
      break;
    // case "priorita_magazzini":
    //   columns = getPrioritaMagazziniColumns(intl, warehousesNumber) as MTColumnsType<T>;
    //   break;
    case "saturazione":
      columns = getSaturazioneColumns(intl) as MTColumnsType<T>;
      break;

    default:
      throw new Error(`Table ${table_name} not found`);
  }

  return reorderCommonColumnsAfterPrimaryKeys(columns, getCommonColumns<T>(intl));
}