import {
  Action,
  ClientVsDeadlineType,
  EltType,
  EnergyVsWasteType,
  FormAction,
  NotificationType
} from "@feature/commons/types";
import api from "@services/apiRequest";
import {AgType, ConfigurationSchema, GenericDataEntrySchema} from "@feature/data-entry/types";
import {IntlShape} from "react-intl";

type T = ConfigurationSchema;

export const handleParamsSave = (
  configuration: T,
  eltType: EltType,
  push: (arg0: NotificationType) => void,
  intl: IntlShape
) => {
  api.post<void>(`/configurations/${eltType}/new_configuration`, configuration).then(
    () => push({
      title: intl.formatMessage({id: 'success'}),
      type: "success"
    }))
    .catch(e => {
      console.error(e);
      push({
        title: intl.formatMessage({id: 'server_error'}),
        type: "error"
      })
    })
}

export function runParamsReducer(state: T, action: Action): T {
  const {type, payload} = action;

  switch (type) {
    case FormAction.addClientVsDeadline:
      return {
        ...state,
        client_vs_deadline_param: payload as ClientVsDeadlineType,
      };
    case FormAction.addEnergyVsWaste:
      return {
        ...state,
        energy_vs_waste_param: payload as EnergyVsWasteType,
      };
    case FormAction.massiveParameterUpdate:
      return payload as T
    default:
      throw Error('Unknown action');
  }
}

export const checkIfNotEmpty = (fieldData: any, intl: IntlShape) => (
  // casting to string to allow 0 and false values
  !fieldData?.toString().trim()
    ? intl.formatMessage({id: "required"})
    : true
)

export const checkIfStringIsPositiveNumber = (fieldData: string, intl: IntlShape) => (
  isNaN(Number(fieldData)) || Number(fieldData) < 0
    ? intl.formatMessage({id: "must_be_positive_number"})
    : true
)

export const checkStringLength = (
  fieldData: string,
  value: number,
  intl: IntlShape
) => (
  fieldData.length > value
    ? intl.formatMessage({id: "max_length_exceeded"}, {value})
    : true
)

export const checkIfStringNumberIsInteger = (fieldData: string, intl: IntlShape) => (
  !Number.isInteger(Number(fieldData))
    ? intl.formatMessage({id: "must_be_integer"})
    : true
)

export const checkIfStringNumberIsLessThan = (
  fieldData: string,
  value: number,
  intl: IntlShape
) => (
  Number(fieldData) > value
    ? intl.formatMessage({id: "must_be_less_than"}) + ` ${value}`
    : true
)

export const updateRow = async <T extends GenericDataEntrySchema>(
  tableName: string,
  schema_name: string,
  row: T,
  refetch: () => void,
  notificationPush: (arg0: NotificationType) => void,
  intl: IntlShape
) => {
  row['flg_modified'] = true;
  row['cod_ag_type'] = AgType.dtn;

  try {
    await api.put(`/data_entry/${schema_name}/${tableName}/update`, row);
    refetch();
    notificationPush({
      title: intl.formatMessage({id: 'success'}),
      type: "success"
    });
  } catch (e: any) {
    console.error(e);
    notificationPush({
      title: intl.formatMessage({id: e.response.data?.detail ? e.response.data.detail[0].msg : 'server_error'}),
      type: "error"
    });
  }
}

export const deleteRow = (
  tableName: string,
  schema_name: string,
  id: number,
  refetch: () => void,
  notificationPush: (arg0: NotificationType) => void,
  intl: IntlShape
) =>
  api.delete(`/data_entry/${schema_name}/${tableName}/delete/${id}`)
    .then(() => {
      refetch();
      notificationPush({
        title: intl.formatMessage({id: 'success'}),
        type: "success"
      })
    })
    .catch((e: any) => {
      console.error(e);
      notificationPush({
        title: intl.formatMessage({id: e.response.data?.detail ? e.response.data.detail : 'server_error'}),
        type: "error"
      })
    })

export const addRow = async <T extends GenericDataEntrySchema>(
  tableName: string,
  schema_name: string,
  row: T,
  refetch: () => void,
  notificationPush: (arg0: NotificationType) => void,
  intl: IntlShape
) => {

  row['flg_modified'] = true;
  row['cod_ag_type'] = AgType.dtn;

  try {
    await api.post(`/data_entry/${schema_name}/${tableName}/add`, row);
    refetch();
    notificationPush({
      title: intl.formatMessage({id: 'success'}),
      type: "success"
    });
  } catch (e: any) {
    console.error(e);
    notificationPush({
      title: intl.formatMessage({id: e.response.data?.detail ? e.response.data.detail[0].msg : 'server_error'}),
      type: "error"
    });
  }
}