import React from "react";
import {withRouter} from "react-router-dom";
import { api } from "@services/apiRequest";
import ErrorPage from "./pages/ErrorPage";

import { AuthException } from "@services/AuthenticationService/exception";
import AuthenticationService from "@services/AuthenticationService";
import { useNavigationContext } from "@services/NavigationContext";


const withNavigationContext = (WrappedComponent) => {
  return (props) => {
    const navigationContext = useNavigationContext();
    return <WrappedComponent navigationContext={navigationContext} {...props} />;
  };
};

export class ErrorBoundary extends React.Component {

  static getDerivedStateFromError(error, info) {
    if (!(error instanceof AuthException)) {
      return {hasError: true, error, info};
    }
    return {hasError: false, error, info: null};
  }

  componentDidCatch(error, info) {
    if (error instanceof AuthException) {
      return;
    }

    const errorMessage = error?.message || ""
    const errorStack = (error?.stack || "")
    .replace(errorMessage, "")
    .split("\n    ")
    
    const asyncErrorPost = async () => api.post(
      "/audit/error_frontend",
      {
        stack: errorStack,
        error: errorMessage,
        from_: this.props.from || "main",
        path: `${window.location.pathname}${window.location.search}`,
        agent:
        window.navigator && window.navigator.userAgent
        ? window.navigator.userAgent
        : "",
      },
      {optionalAuth: true}
      );
      
    // send api to server if not errorMessage is Redirect to login
    try {
      asyncErrorPost();
    } catch (error) {
      // error client error
    }
  };

  render() {
    const {children, component, hide} = this.props;

    const {hasError, error} = this.state || {};

    if (error instanceof AuthException) {
      // this code path is reached only if the user rethrows the exception
      // and normally it is handled by the api interceptor
      AuthenticationService.logout_and_redirect((path) => {
        this.props.history.push(path);
      });
      return null;
    }

    if (hasError) {
      if (component) {
        return component;
      }
      if (hide) {
        return null;
      }

      return <ErrorPage/>;
    }

    return children;
  }
}

export default withNavigationContext(withRouter(ErrorBoundary));