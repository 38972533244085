import {useIntl} from "react-intl";
import _ from "lodash";
// Model & Hooks
import {GanttAndKpiFiltersProps} from "./types";
import {GanttFilters} from "@feature/commons/gantt/types";
import {SelectOptionsSchema} from "@feature/commons/types";
// Components
import SearchSelect from "@ui-components/SearchSelect";
import CheckboxGroup from "@ui-components/CheckboxGroup";
import RunParamsPopup from "@feature/commons/run-params-popup/run-params-popup";


export function GanttAndKpiFilters({
  ganttAndKpiFilters,
  setGanttAndKpiFilters,
  machineOptions,
  runSchedParams,
}: GanttAndKpiFiltersProps) {

  const labelStyle = "font-semibold text-am-600"

  const intl = useIntl();

  return (
    <div className="mt-4 mb-10 flex flex-col items-center">
      <div className="grid grid-cols-2 gap-x-4 place-items-center px-5 mb-2">
        <SearchSelect
          options={machineOptions}
          label={intl.formatMessage({id: "machines"})}
          labelStyle={labelStyle}
          classNames="w-full"
          onChange={(m: SelectOptionsSchema[]) => setGanttAndKpiFilters(p => (
            {...p, machines: m.map(e => e.value)}
          ))}
          value={machineOptions.filter(x => ganttAndKpiFilters.machines.includes(x.value))}
          isMulti
        />
        <CheckboxGroup
          values={ganttAndKpiFilters.filters}
          options={
            Object.entries(GanttFilters).map(
              ([key, value]) => (
                {name: _.capitalize(key.replaceAll('_', ' ')), value}
              )
            )
          }
          onChange={
            (filter: GanttFilters) => setGanttAndKpiFilters(p => {
              const filters = p.filters.includes(filter)
                ? p.filters.filter(f => f !== filter)
                : [...p.filters, filter]
              return {...p, filters}
            })
          }
          horizontal
        />
      </div>
      
      <RunParamsPopup state={runSchedParams}/>
    </div>
  )
}
