import {useState} from "react";
import {StatusBadge} from "@feature/commons";
import {Status} from "@feature/commons/types";

function LoadingTooltip({status}: { status: Status }) {
	
	const [showTooltip, setShowTooltip] = useState<boolean>(false);
	
	return (
		<div className="group relative p-1 flex justify-center mx-auto max-w-xs">
			<StatusBadge
				className="w-full"
				status={status}
				onMouseOver={() => setShowTooltip(true)}
				onMouseOut={() => setShowTooltip(false)}
			/>
			{
				showTooltip && !status.endsWith("ERROR") && !(status.startsWith('PRS') && status.endsWith("SUCCESS")) &&
				<span className="absolute bottom-14 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
					{status}
				</span>
			}
		</div>
	)
}

export default LoadingTooltip;