export const tecnoform_it = {
  unprioritized_customers: "Clienti a cui dare ancora priorità",
  articles_missing_approvation_status: "Articoli per i quali bisogna ancora definire lo stato di approvazione",
  unapproved_articles: 'Articoli con stato di approvazione: "Non ancora approvato"',
  missing_order_infos: "Ordini per i quali sono richieste informazioni ulteriori",
  couples_missing_infos: "Coppie (articolo, pose) per le quali mancano informazioni",
  couples_no_revisions: "Coppie (articolo, pose) per le quali non sono ancora state revisionate le risorse",
  last_run: "Ultima esecuzione",
  approved: "Approvato",
  to_be_approved: "Da approvare",
  active: "Attivo",
  inactive: "Inattivo",
  cod_article: "Codice Articolo",
  production_start_date: "Data inizio produzione",
  production_end_date: "Data fine produzione",
  reordered_pieces_amount: "Pezzi riordinati",
  cod_resource: "Codice Risorsa",
  status: "Stato",
  resources: "Risorse",
  customer: "Cliente",
  priority: "Priorità",
  type: "Tipologia",
  year: "Anno",
  machine_serial_number: "matricola",
  department: "Reparto",
  max_length: "Lunghezza massima",
  max_width: "Larghezza massima",
  balloon: "Pallone",
  stacker: "Impilatore",
  min_speed: "Velocità minima",
  max_speed: "Velocità massima",
  cost: "Costo",
  maintenance_start_date: "Data inizio manutenzione",
  maintenance_end_date: "Data fine manutenzione",
  category: "Categoria",
  cod_order: "Codice Ordine",
  num_expected_poses: "Numero pose previste",
  expected_cycles_per_hour: "Battute ora previste",
  order_insertion_date: "Data inserimento ordine",
  raw_material_code: "Codice materia prima",
  necessary_raw_material: "Materia prima necessaria",
  raw_material_arrival_date: "Data arrivo materia prima",
  assigned_resource: "Risorsa assegnata",
  ordered_pieces: "Pezzi ordinati",
  valid_pieces: "Pezzi buoni prodotti",
  third_party_contractor: "Terzista",
  delivery_date: "Data consegna",
  is_order_mandatory: "Tassatività ordine",
  deposit: "Acconto",
  compiled: "Compilato",
  revised: "Revisionato",
  first_tranche_quantity: "Quantità prima tranche",
  first_tranche_date: "Data prima tranche",
  second_tranche_quantity: "Quantità seconda tranche",
  second_tranche_date: "Data seconda tranche",
  cod_part: "Codice Parte",
  yes: "Sì",
  value: "Valore",
  constraint_name: "Nome vincolo",
  poses: "Pose",
  allowed_on_resource_3: "Consentito su risorsa 3",
  mold_bell_length: "Lunghezza campana",
  mold_bell_width: "Larghezza campana",
  chain: "Catena",
  pincher: "Pinza",
  back_to_schedule: "Torna alla schedulazione",
  alerts: "Allarmi",
  user: "Utente",
  required: "Obbligatorio",
  parameters: "Parametri",
  workers: "Operatori",
  setups_max_number: "Numero massimo di montaggi contemporanei",
  val_quantita_tot: "Quantità totale",
  other_tranche_quantity: "Quantità altre tranche",
  work_days: "Distanza in Giorni lavorativi",
  last_tranche_date: "Data ultima tranche",
  last_tranche_date_tooltip: "La data dell'ultima tranche verrà automaticamente calcolata durante la prossima elaborazione di Anagram. In seguito sarà modificabile manualmente.",
  equipment: "Attrezzatura",
  quantity: "Quantità",
  input_placeholder: "Filtra ...",
  data_registry: "Registro Dati",
  logging: "Logging",
  reparti: "Reparti e Numero Montaggi",
  production: "Produzione",
  setup: "Attrezzaggio",
  maintenance: "Fermo Programmato",
  product_value: "Valore Prodotto",
  daily_value_target: "Obiettivo valore giornaliero (€/gg)",
  work_days_in_month: "Giorni lavorativi nel mese",
  from_day: "Dal giorno",
  to_day: "Al giorno",
  total_in_period_selected: "Totale nel periodo selezionato",
  total_target_in_period_selected: "Target nel periodo selezionato",
  day: "Giorno",
  machines_total: "Totale macchine",
  product_value_data_not_present: "Dati sul Valore Prodotto non presenti",
  must_be_positive_number: "Deve essere un numero positivo",
  priority_range: "Deve essere compreso tra 1 e 5",
  must_be_empty_if_deposit_is_false: "Deve essere vuoto se non è acconto",
  sum_val_quantity_tot_is_less_than_sum_val_quantity_tranche: "La quantità totale specificata è inferiore alla somma delle quantità delle altre tranche",
  flg_acconto_true_but_payment_tranches_not_filled: "Acconto contrassegnato, ma non sono stati compilati tutti i campi delle tranche di pagamento (Quantità Prima Tranche, Data Prima Tranche, Quantità Seconda Tranche, Data Seconda Tranche)",
  flg_acconto_false_but_payment_tranches_filled: "Acconto non contrassegnato, ma è stato compilato almeno un campo delle tranche di pagamento (Quantità Prima Tranche, Data Prima Tranche, Quantità Seconda Tranche, Data Seconda Tranche)",
  rep_has_machines_associated: "Il reparto ha delle macchine associate ad esso. Non è possibile eliminarlo.",

  // Monitoraggio Magazzini
  warehouses_monitoring: "Monitoraggio Magazzini",
  warehouse_perc_saturation_tooltip: "La percentuale di saturazione è calcolata facendo la media della saturazione di ciascuna corsia del magazzino. Se la saturazione di una corsia è superiore al 100%, questa viene considerata come se fosse al 100%.",
  accatastamento: "Accatastamento",
  saturazione: "Saturazione",
  priorita_magazzini: "Priorità Magazzini",
  capacita: "Capacità",
  conversioni: "Conversioni",
  cod_family: "Codice Famiglia",
  des_family: "Descrizione Famiglia",
  cod_sub_family: "Codice Sottofamiglia",
  des_sub_family: "Descrizione Sottofamiglia",
  flg_active: "Attivo",
  des_accatastamento_default: "Accatastamento di default",
  des_accatastamento_manuale: "Accatastamento Manuale",
  cod_warehouse: "Codice Magazzino",
  des_warehouse: "Descrizione Magazzino",
  cod_lane: "Codice Corsia",
  des_lane: "Descrizione Corsia",
  num_max_pallet: "Numero massimo di pallet",
  id_preference: "ID Preferenza",
  perc_saturazione: "Percentuale di saturazione (%)",
  des_um: "Unità di misura",
  cod_item: "Codice Articolo",
  des_item: "Descrizione Articolo",
  num_max_um_pallet: "Numero massimo di UM per pallet",
  are_you_sure_to_delete: "Sei sicuro/a di voler eliminare questa riga?",
  max_length_exceeded: "Lunghezza massima superata",
  must_be_less_than: "Deve essere minore di",
  must_be_integer: "Deve essere un numero intero",
  perc_saturazione_tooltip: "Inserire un valore compreso tra 0 e 100",
  current_state: "Stato attuale",
  forecast: "Previsione",
  data_updated_on: "Dati aggiornati al",
  warehouse_monitoring_data_not_present: "Dati di monitoring magazzino non presenti",
  visualization_date: "Data di visualizzazione",
  visualization_kpi: "KPI di visualizzazione",
  perc_saturation: "Saturazione (%)",
  val_pallet: "Pallet",
  amt_stock: "Valore (€)",
  item_type: "Tipo di Articolo",
  warehouse: "Magazzino",
  lane: "Corsia",
  no_data_available: "Nessun dato disponibile",
  no_gauge_chart_data_available: "Nessun dato disponibile per i grafici a tachimetro",
  aggregation_period: "Periodo di aggregazione",
  month: "Mese",
  week: "Settimana",
  filter_warehouse: "Filtra magazzino",
  filter_lane: "Filtra corsia",
  filter_item: "Filtra articolo",
  item: "Articolo",
  total: "Totale",
  cod_lane_type: "Tipo Corsia",
  cambio_tassello: "Cambio Tassello",
  cambio_ordine: "Cambio Ordine",
  cambio_stampo: "Cambio Stampo",
  cambio_materiale: "Cambio Materiale",
  all: "Tutti",
  choose_a_machine: "Scegliere una macchina",

  // Roles translation
  admin: "admin",
  manager: "manager",
  warehouse_user: "utente_magazzino",
  anagram_user: "utente_anagram",
}