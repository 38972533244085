import React from "react";

const LogoAmmagamma = ({ height = "20", width = "auto" }) => (
  <div className="shrink-0 flex items-center px-4">
    <img
      type="image/xml+svg"
      className={`h-${height} w-${width}`}
      src={process.env.PUBLIC_URL + "/am-logo.svg"}
      alt="ammagamma logo"
    />
  </div>
);

export default LogoAmmagamma;
