import React, {useLayoutEffect, useState} from "react";
import {renderToString} from "react-dom/server";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import {useIntl} from "react-intl";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {GanttBlocksType, GanttChartProps, IntervalType} from "@feature/commons/gantt/types";
import {addLicense} from "@components/Charts/commons";
import {
  computeChartHeight,
  getLabelsAndColors,
  getUniqueYValues,
  saveChanges
} from "@feature/commons/gantt/gantt-chart/lib";
import Button from "@ui-components/Button";
import {EditModal} from "@feature/commons/gantt/edit-modal";
import {EltType} from "@feature/commons/types";
import { addMonths } from "@utils/utils";
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT";

const ganttRowSize = 20;
const machinesOrder: string[] = [
  /*
    Call with customer on 25th september,
    the order of the gantt data must follow the order of the physical machines
    in the departments.
  */

  // Department 1
  "011",
  "07",
  "010",
  "09",
  "012",
  "015",
  "014",
  "013",
  // Department 3
  "03",
  "04",
  "05",
  "06",
  "08",
];

const _getOffsetByMachinesNumber = (machinesNumber: number): number => {
  /*
    Unfair function that returns a different offset to add to chart height.
    The offset is based on machines number in chart.
  */
  if (machinesNumber === 5) {
    return machinesNumber * 40;
  } else if (machinesNumber <= 8) {
    return machinesNumber * 30;
  }

  return machinesNumber * 20;
}

export function GanttChart({
  id,
  categoriesData,
  setCategoriesData,
  editable = false,
  machineOptions,
  setRefetchBlocks,
  filterParams,
  eltType
}: GanttChartProps) {

  const [editBlock, setEditBlock] = useState<GanttBlocksType>();
  const [editGantt, setEditGantt] = useState<boolean>(false);

  const intl = useIntl();
  const machinesNumber: number = new Set(categoriesData.map(b => b.cod_machine)).size;

  useLayoutEffect(() => {

    addLicense();

    const chartHeight: number = computeChartHeight(categoriesData, ganttRowSize);

    const categoriesLabels = getLabelsAndColors(categoriesData);

    // Sort categories data by machines order hardcoded above.
    const reorderedCategoriesData = categoriesData.sort((a, b) => {
      return machinesOrder.indexOf(a.cod_machine) - machinesOrder.indexOf(b.cod_machine);
    });

    // Get min date of categoriesData, used to calculate default Zoom in chart.appear Promise.
    const minDate = Math.min(...categoriesData.map(b => new Date(b.tms_start).getTime()));

    const legendLabels = categoriesLabels.filter(l => l.cod_type_interval !== IntervalType.production);

    const root = am5.Root.new(id);

    // Theme
    root.setThemes([am5themes_Animated.new(root)]);

    root.dateFormatter.setAll({
      dateFormat: "yyyy-MM-dd",
      dateFields: ["valueX", "openValueX"],
    });
    // Set Chart locale
    root.locale = am5locales_it_IT;

    // Init chart
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
        paddingLeft: 5,
        paddingRight: 30,
      })
    );

    /**
     * Chart scrollable container
     */
    const scrollableContainer = chart.chartContainer.children.push(
      am5.Container.new(root, {
        width: am5.p100,
        height: am5.p100,
        verticalScrollbar: am5.Scrollbar.new(root, {
          orientation: "vertical",
          dx: 10,
        }),
      })
    );
    // Container settings
    chart.yAxesAndPlotContainer.set("height", chartHeight);
    chart.yAxesAndPlotContainer.set("paddingBottom", 10);
    chart.zoomOutButton.set("forceHidden", true);

    // Wheel handler
    scrollableContainer.events.on("wheel", (ev) => {
      if (ev.originalEvent.ctrlKey) {
        ev.originalEvent.preventDefault();
        chart.set("wheelX", "panX");
        chart.set("wheelY", "zoomX");
      } else {
        chart.set("wheelX", "none");
        chart.set("wheelY", "none");
      }
    });
    // Push chart in container
    scrollableContainer.children.push(chart.yAxesAndPlotContainer);

    const oneDay = 1000 * 60 * 60 * 24;

    /**
      X axis
    */
    let xRenderer = am5xy.AxisRendererX.new(root, {});
    xRenderer.labels.template.setAll({
      fontWeight: "bold",
      rotation: -60,
      fontSize: 12,
    });
  
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: {
          timeUnit: "minute",
          count: 1,
        },
        gridIntervals: [
          {timeUnit: "day", count: 1},
        ],
        renderer: xRenderer,
        min: Math.min(...categoriesData.map(b => new Date(b.tms_start).getTime())),
        // Add 1 day to max date to avoid the last block to be cut off
        max: Math.max(...categoriesData.map(b => new Date(b.tms_end).getTime())) + oneDay,
      })
    );

    /**
      Y axis
    */
    let yRenderer = am5xy.AxisRendererY.new(root, {inversed: true});
    yRenderer.labels.template.setAll({
      fontSize: 20,
      fontWeight: "bold",
    });

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "cod_machine",
        paddingRight: 15,
        renderer: yRenderer,
      })
    );
    yAxis.set("minHeight", chartHeight);

    yAxis.data.setAll(getUniqueYValues(categoriesData));

    // Add time scrollbar
    const scrollbarX = am5.Scrollbar.new(
      root, {
        orientation: "horizontal",
      }
    );
    chart.set("scrollbarX", scrollbarX);

    // Add scrollbar on top of the chart
    chart.chartContainer.children.unshift(scrollbarX);

    /**
     * Legend
     */

    const legend = chart.children.push(am5.Legend.new(root, {
      nameField: "des_block",
      fillField: "color",
      strokeField: "color",
      centerX: am5.percent(50),
      x: am5.percent(50),
      layout: am5.GridLayout.new(root, {
        maxColumns: 5,
        fixedWidthGrid: true
      }),
      marginBottom: 25
    }));

    /**
     * Series (Column)
     */
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        openValueXField: "tms_start",
        valueXField: "tms_end",
        categoryYField: "cod_machine",
        sequencedInterpolation: true,
      })
    );
    // Series settings (with tooltip)
    series.columns.template.setAll({
      templateField: "columnSettings",
      tooltipX: am5.percent(50),
      tooltipY: am5.percent(50),
    });
    series.data.processor = am5.DataProcessor.new(root, {
      dateFields: ["tms_start", "tms_end"],
      dateFormat: "yyyy-MM-dd HH:mm",
    });
    // Open  Modal
    series.columns.template.events.on("click", (ev) => {
      const blockData = ev.target.dataItem?.dataContext as GanttBlocksType
      setEditBlock(blockData)
    })

    // Hide/Show bullets on width changes
    series.columns.template.onPrivate("width", (width, target) =>
      am5.array.each(target!.dataItem!.bullets!, (bullet) =>
        !width || width < 25
          ? bullet.get("sprite").hide()
          : bullet.get("sprite").show()
      )
    );

    // Bullets
    series.bullets.push((root) => am5.Bullet.new(root, {
      locationX: 0.5,
      locationY: 0.5,
      dynamic: true,
      sprite: am5.Label.new(root, {
        text: "{des_block}",
        fill: am5.color(0xffffff),
        fontWeight: "600",
        centerX: am5.percent(50),
        centerY: am5.percent(50),
        populateText: true,
        breakWords: true,
        oversizedBehavior: "truncate",
        ellipsis: "...",
        visible: false,
      }),
    }));

    // tooltipHTML
    series.columns.template.adapters.add("tooltipHTML", (_, target) => {
        const t = target.dataItem?.dataContext as GanttBlocksType;
        return renderToString(
          <div style={{maxWidth: 450}} className="flex flex-col items-center gap-y-2 text-white">
            <div>
              {
                t.cod_type_interval === IntervalType.production ?
                  t.f_orders.map(
                    o => (
                      <ul key={o.cod_order}>
                        <ul><strong>Cliente: </strong>{o.des_customer}</ul>
                        <ul><strong>Codice Ordine: </strong>{o.cod_order}</ul>
                        <ul><strong>Tipo: </strong>{o.cod_order_type}</ul>
                        <ul><strong>Codice Articolo: </strong>{o.cod_item}</ul>
                        <ul><strong>Quantità: </strong>{o.dict_order_information.val_qty}</ul>
                        <ul><strong>Numero Pose: </strong>{o.dict_order_information.val_pose}</ul>
                      </ul>
                    )
                  ) :
                t.des_block
              }
            </div>
          </div>
        )
      }
    );
    series.data.setAll(reorderedCategoriesData);
    legend.data.setAll(legendLabels);

    chart.appear(500, 100).then(() => {
      // Apply default zoom to 1 month.
      xAxis.zoomToDates(new Date(minDate), addMonths(new Date(minDate), 1));
    });

    return () => root.dispose()

  }, [categoriesData, id, editGantt])

  return (
    <div style={{border: "1px solid black", borderRadius: "20px"}}>
      {
        editable &&
        <div className="flex justify-between mx-10 gap-x-3">
          {
            editGantt &&
            <>
              <Button onClick={() => saveChanges(categoriesData, filterParams, setRefetchBlocks, EltType.sched)}>
                {intl.formatMessage({id: "confirm_changes"})}
              </Button>
              <Button onClick={() => setRefetchBlocks(p => !p)}>
                {intl.formatMessage({id: "discard_changes"})}
              </Button>
            </>
          }
          <Button classNames="ml-auto" onClick={() => setEditGantt(p => !p)}>
            {intl.formatMessage({id: editGantt ? 'exit_edit' : 'edit'})}
          </Button>
        </div>
      }
      {/* Given hardcoded minHeight to avoid the chart to collapse */}
      <div 
        id={id}
        style={{
          width: '100%',
          maxWidth: '100%',
          height: computeChartHeight(categoriesData, ganttRowSize) + _getOffsetByMachinesNumber(machinesNumber),
          minHeight: 350,
        }}
      />
      {
        editBlock &&
        <EditModal
          eltType={eltType}
          block={editBlock}
          editGantt={editGantt}
          setBlock={setEditBlock}
          machineOptions={machineOptions}
          categoriesData={categoriesData}
          setCategoriesData={setCategoriesData}
        />
      }
    </div>
  )
}
