import {DtnErrorsSchema} from "@feature/data-entry/types";
import SmallNotification from "@ui-components/SmallNotification";
import {useIntl} from "react-intl";
import {Fragment} from "react";
import {ArrowTopRightOnSquareIcon, CheckIcon} from "@heroicons/react/24/outline";
import {useHistory} from "react-router";
import {getTabFromErrors} from "@feature/data-entry/lib/constants";
import {NewRunType} from "@feature/commons/types";

export function DataEntryErrors({state, errors, showRedirect}: {
  state?: NewRunType,
  errors?: DtnErrorsSchema,
  showRedirect?: boolean
}) {

  const intl = useIntl();

  const history = useHistory();

  return errors && Object.keys(errors).length > 0 ?
    (
      <div
        className={`grid border-2 border-am-200 rounded-md ${showRedirect ? 'grid-cols-4' : 'grid-cols-3'} font-semibold mx-10`}>
        {
          errors && Object.entries(errors).map(([key, value]) => (
              <Fragment key={key}>
              <span className="col-span-2 px-4 py-1">
                {intl.formatMessage({id: key})}
              </span>
                {
                  value && value > 0
                    ? <SmallNotification counter={value} enableAnimation classStyle="w-fit my-auto mx-auto"/>
                    : <CheckIcon className="h-4 w-7 rounded-xl stroke-2 p-0.5 my-auto mx-auto bg-green-600 text-white"/>
                }
                {
                  showRedirect && value && value > 0
                    ? (
                      <ArrowTopRightOnSquareIcon
                        className="h-7 w-7 text-gray-500 my-auto mx-auto"
                        onClick={
                          () => history.push("/data_entry", {
                            tab: getTabFromErrors(key as keyof DtnErrorsSchema),
                            title: state?.title,
                            description: state?.description,
                          })
                        }/>
                    )
                    : <></>
                }
              </Fragment>
            )
          )
        }
      </div>
    )
    : <></>
}