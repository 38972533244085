const anagram_params = {
  CLIENT: "priorità cliente",
  DEADLINE: "priorità scadenze",
  ENERGY: "ammortamento macchine",
  WASTE: "riduzione scarti",
  BALANCED: "bilanciato",
}

const data_entry_tabs = {
  alto_vendenti: "Altovendenti",
  articoli_approvati: "Articoli Approvati",
  articolo_pose_risorse: "Scelta Risorse",
  clienti: "Clienti",
  lista_macchine: "Lista Macchine",
  manutenzioni: "Fermi Programmati",
  ordini: "Ordini",
  ordini_simulati: "Ordini Simulati",
  parametri: "Parametri",
  produzioni: "Produzioni",
}

export const anagram_it = {
  launch_plan: "Lancia nuova pianificazione",
  launch_sched: "Lancia nuova schedulazione",
  plan_title: "titolo pianificazione",
  sched_title: "titolo schedulazione",
  add_plan: "aggiungi pianificazione",
  add_sched: "aggiungi schedulazione",
  anagram_params: "parametri anagram",
  users_displayed_record: "{from}-{to} di {count} record",
  empty_table: "Nessun risultato disponibile",
  title: "Titolo",
  success: "Operazione Completata con Successo",
  generic_error: "Si è verificato un errore; contatta l'assistenza",
  working_center: "Centro di Lavorazione",
  apply: "Applica",
  show_inserted_params: "Priorità Scadenze & Scarti",
  select_working_center: "Seleziona un centro di lavorazione",
  role_read_not_enabled: "Lettura dei ruoli non abilitata",
  entity_task_control: "Gestione dei task di controllo in sistema",
  executing: "In esecuzione",
  new_start_time: "Nuovo Orario Inizio",
  new_end_time: "Nuovo Orario Fine",
  ...anagram_params,
    ...data_entry_tabs
}