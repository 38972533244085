import React, {useEffect, useState, ChangeEvent} from "react";
import {useLocation, useParams} from "react-router";
import {useIntl} from "react-intl";
// Model & Hooks
import {DateLimits, RunSchedParams, SchedulingDetailsProps} from "@feature/sched/types";
import {EltType, SelectOptionsSchema} from "@feature/commons/types";
import {useFetchGroups} from "@feature/commons/hooks";
import { GanttKpiPayloadType } from "./gantt-and-kpi/types";
// Components
import SearchSelect from "@ui-components/SearchSelect";
import Placeholder from "@ui-components/Placeholder";
import {GanttAndKpi} from "@feature/sched/scheduling-details/gantt-and-kpi";
import {SearchPlaceholder} from "@feature/commons";
import DatePicker from "@ui-components/DatePicker";
import Input from "@ui-components/Input";
import ChartOrTableToggle from "./gantt-and-kpi/ChartOrTableToggle";
// Date Services
import {addMonths, dateToString, subtractMonths} from "@utils/utils";


const labelStyle = "font-semibold text-am-600";


export function SchedulationDetails({
	homeRunId,
	client_vs_deadline_param,
	energy_vs_waste_param,
}: SchedulingDetailsProps) {
	
	const intl = useIntl();

	/* Get definitiveIdRun from URL Location (default) or props (passed in home-page) */
	const {id_run} = useParams<{ id_run?: string }>();
	if (id_run === undefined && homeRunId === undefined) {
		throw new Error("id_run is undefined");
	}
	const definitiveIdRun = id_run ?? homeRunId!;
	
	/* Get definitiveState from URL Location (default) or props (passed in home-page) */
	const {state} = useLocation<RunSchedParams>();
	if (state === undefined && (client_vs_deadline_param === undefined || energy_vs_waste_param === undefined)) {
		throw new Error("state is undefined");
	}
	const definitiveState = state ?? {client_vs_deadline_param, energy_vs_waste_param};

	const {loading: groupLoading, groups: groupOptions} = useFetchGroups(parseInt(definitiveIdRun), EltType.sched);

	const [costCenter, setCostCenter] = useState<SelectOptionsSchema>();
	const [datesLimits, setDatesLimits] = useState<DateLimits>({
		/* At first, take a large interval to fetch all blockData related to the run and calculate effective dateLimits (GanttAndKpi component) */
		dateStart: subtractMonths(new Date(), 3),
		dateEnd: addMonths(new Date(), 12),
		firstRender: true,
	});

  const [ganttAndKpiFilters, setGanttAndKpiFilters] = useState<GanttKpiPayloadType>({
		machines: [],
		date_start: dateToString(datesLimits.dateStart),
		date_end: dateToString(datesLimits.dateEnd),
		toggleTable: false,
		filters: [],
		search_keyword: undefined,
		dateLimitsFirstRender: datesLimits.firstRender,
  });

	useEffect(() => {
		// Set costCenter to the first option by default.
		if (!costCenter && groupOptions && groupOptions.length) {
			setCostCenter(groupOptions[0]);
		}
	}, [costCenter, groupOptions])
	
	return (
		<>
			<div className="flex justify-between items-end">
				{
					!groupLoading && groupOptions ? 
						<div className="grid grid-cols-3 gap-y-2 xl:grid-cols-6 gap-x-4">
							<SearchSelect
								label={intl.formatMessage({id: "working_center"})}
								labelStyle="font-semibold text-am-600"
								// Concat "all" option to fetch machines from all departments.
								options={groupOptions.concat({label: intl.formatMessage({id: "all"}), value: "all"})}
								value={costCenter}
								onChange={(e: SelectOptionsSchema) => setCostCenter(e)}
							/>

							{
								datesLimits ?
									<div className="flex items-center gap-x-2 col-span-2">
										<DatePicker
											value={new Date(ganttAndKpiFilters.date_start)}
											classNames="w-32"
											label={intl.formatMessage({id: "from"})}
											labelClassNames={labelStyle}
											onChange={(d: Date) => setGanttAndKpiFilters({...ganttAndKpiFilters, date_start: dateToString(d)})}
											minDate={datesLimits.dateStart}
											maxDate={new Date(ganttAndKpiFilters.date_end)}
										/>
										<DatePicker
											value={new Date(ganttAndKpiFilters.date_end)}
											classNames="w-32"
											label={intl.formatMessage({id: "to"})}
											labelClassNames={labelStyle}
											onChange={(d: Date) => setGanttAndKpiFilters({...ganttAndKpiFilters, date_end: dateToString(d)})}
											minDate={new Date(ganttAndKpiFilters.date_start)}
											maxDate={datesLimits.dateEnd}
										/>
									</div> :
									null
							}
							<Input
								value={ganttAndKpiFilters.search_keyword}
								classNames="col-span-2 mt-auto"
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									setGanttAndKpiFilters({...ganttAndKpiFilters, search_keyword: e.target.value})
								}}
								onClear={() => setGanttAndKpiFilters({...ganttAndKpiFilters, search_keyword: undefined})}
								placeholder={intl.formatMessage({id: "input_placeholder"})}
								showErrorDialog={false}
							/>
							<ChartOrTableToggle
								customStyle="lg:mt-auto"
								booleanValue={ganttAndKpiFilters.toggleTable}
								setBooleanValue={() => setGanttAndKpiFilters({...ganttAndKpiFilters, toggleTable: !ganttAndKpiFilters.toggleTable})}
							/>
						</div>
						: <Placeholder height="h-42"/>
				}
			</div>
			{
				costCenter ?
					<GanttAndKpi
						idRun={parseInt(definitiveIdRun)}
						group={costCenter.value}
						datesLimits={datesLimits}
						setDateLimits={setDatesLimits}
						ganttAndKpiFilters={ganttAndKpiFilters}
						setGanttAndKpiFilters={setGanttAndKpiFilters}
						runSchedParams={definitiveState}
					/>
					: <SearchPlaceholder/>
			}
		</>
	)
}
