import * as am5 from "@amcharts/amcharts5";
import {RangeType} from "@feature/commons/gauge/types";

export const createRange = ({axis, start, end, color, seriesLabel}: RangeType): void => {
	
	const rangeDataItem = axis.makeDataItem({
		value: start,
		endValue: end
	});
	
	axis.createAxisRange(rangeDataItem);
	
	// ! = trust me bro is not undefined
	
	rangeDataItem.get("axisFill")!.setAll({
		visible: true,
		fill: color,
		fillOpacity: 0.8
	});
	
	rangeDataItem.get("tick")!.setAll({
		visible: false
	});
	
	rangeDataItem.get("label")!.setAll({
		text: seriesLabel,
		inside: true,
		fontSize: "0.9em",
		fontWeight: "bold",
		fill: am5.color(0xffffff)
	});
	
}