import {useIntl} from "react-intl";
import SearchSelect from "@ui-components/SearchSelect";
import {useState} from "react";
import Modal from "@ui-components/Modal";
import {EltType, ModalProps, SelectOptionsSchema} from "@feature/commons/types";
import DatePicker from "@ui-components/DatePicker";
import {addMonths, dateToString, subtractMonths} from "@utils/utils";
import {EditModalProps} from "@feature/commons/gantt/types";
import Tabs from "@ui-components/Tabs";
import {IntervalInfo} from "@feature/commons/interval-info/interval-info";
import {TimePicker} from "@feature/commons";

export function EditModal({
                            block,
                            setBlock,
                            machineOptions,
                            categoriesData,
                            setCategoriesData,
                            eltType,
                            editGantt,
                          }: EditModalProps) {
  const [localChanges, setLocalChanges] = useState(block);
  const [tabIndex, setTabIndex] = useState<0 | 1>(0);
  const intl = useIntl();
  const labelClassNames = "text-am-600 font-semibold mb-2 capitalize"

  const msg = (id: string) => intl.formatMessage({id})

  const handleLocalUpdate = () => {
    const categoriesDataCopy = [...categoriesData];
    categoriesDataCopy[categoriesData.findIndex(
      e => e.cod_production === block.cod_production
    )] = localChanges;
    setCategoriesData(categoriesDataCopy);
    setBlock(undefined);
  }


  let modalProps: ModalProps = {
    opened: !!block, onExit: () => setBlock(undefined),
  }

  if (editGantt) modalProps = {
    ...modalProps,
    onConfirm: () => handleLocalUpdate(),
    confirmText: intl.formatMessage({id: "edit"}),
    dismissText: intl.formatMessage({id: "back"})
  }


  return (
    <Modal {...modalProps}>
      <div className="flex flex-col items-center justify-evenly gap-y-10">
        {editGantt && <Tabs setActiveTab={setTabIndex} active={tabIndex} tabs={[{name: "INFO"}, {name: "MODIFICA"}]}/>}
        {!tabIndex
          ? <IntervalInfo block={block}/>
          : (
            <div className="flex flex-col gap-y-6 items-center my-10">
              <h1
                className="font-bold text-2xl uppercase">
                {`${msg("edit")} ${msg(eltType === EltType.plan ? "planning" : "scheduling")}`}
              </h1>
              <span><b>Attività: </b>{block.cod_type_interval}</span>
              <SearchSelect
                label={intl.formatMessage({id: "new_machine"})}
                labelStyle={labelClassNames}
                options={machineOptions}
                onChange={(e: SelectOptionsSchema) => setLocalChanges({...localChanges, cod_machine: e.value})}
                value={{label: localChanges.cod_machine, value: localChanges.cod_machine}}/>
              <div className="flex items-center justify-between">
                <DatePicker
                  label={intl.formatMessage({id: "new_start_date"})}
                  labelClassNames={labelClassNames}
                  classNames="w-36"
                  value={new Date(localChanges.tms_start)}
                  onChange={(d: Date) => setLocalChanges({...localChanges, tms_start: dateToString(d)})}
                  minDate={subtractMonths(new Date(), 1)}
                  maxDate={addMonths(new Date(), 3)}/>
                <TimePicker
                  label={intl.formatMessage({id: "new_start_time"})}
                  value={{
                    hour: new Date(localChanges.tms_start).getHours(),
                    minute: new Date(localChanges.tms_start).getMinutes()
                  }}
                  onHourChange={(h) => {
                    setLocalChanges({
                      ...localChanges, tms_start: new Date(new Date(localChanges.tms_start).setHours(h)).toString()
                    })
                  }}
                  onMinuteChange={(m) => setLocalChanges({
                    ...localChanges, tms_start: new Date(new Date(localChanges.tms_start).setMinutes(m)).toString()
                  })}
                />
              </div>
              <div className="flex items-center justify-between">
                <DatePicker
                  label={intl.formatMessage({id: "new_end_date"})}
                  labelClassNames={labelClassNames}
                  classNames="w-36"
                  value={new Date(localChanges.tms_end)}
                  onChange={(d: Date) => setLocalChanges({...localChanges, tms_end: dateToString(d)})}
                  minDate={new Date(localChanges.tms_start)}
                  maxDate={addMonths(new Date(), 6)}/>
                <TimePicker
                  label={intl.formatMessage({id: "new_end_time"})}
                  value={{
                    hour: new Date(localChanges.tms_end).getHours(),
                    minute: new Date(localChanges.tms_end).getMinutes()
                  }}
                  onHourChange={(h) => setLocalChanges({
                    ...localChanges, tms_end: new Date(new Date(localChanges.tms_end).setHours(h)).toString()
                  })}
                  onMinuteChange={(m) => setLocalChanges({
                    ...localChanges, tms_end: new Date(new Date(localChanges.tms_end).setMinutes(m)).toString()
                  })}
                />
              </div>
            </div>
          )
        }
      </div>
    </Modal>
  )
}