import { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { api } from "@services/apiRequest";
import AuthenticationService from "../../../../services/AuthenticationService";
import { NotificationsContext } from "../../../Notifications";

const useRoles = () => {
  const intl = useIntl();

  const [userScopes] = useState(AuthenticationService.getUserScopes());
  const [roles, setRoles] = useState(null);
  const { push } = useContext(NotificationsContext);

  useEffect(() => {
    let closed = false;
    const fetchRoles = async () => {
      try {
        const { data } = await api.get("/roles");
        
        if (typeof data !== "object") {
          throw new Error("not object");
        }

        if (!closed) {
          setRoles(data);
        }
      } catch (error) {
        if (!closed) {
          push({
            title: "Errore durante il caricamento",
            type: "error",
          });
        }
      }
    };

    if (roles === null) {
      if (userScopes.indexOf("role:read") >= 0) {
        fetchRoles();
      } else {
        setRoles(false);
        push({
          title: intl.formatMessage({ id: "role_read_not_enabled" }),
          type: "error",
          fixed: true,
        });
      }
    }

    return () => {
      closed = true;
    };
  }, [userScopes, roles, push, intl]);

  return roles;
};

export default useRoles;
