const messages = {
  pwd_validator_pwd_must: "The password must",
  pwd_validator_err_min_length: "Be at least 8 characters long",
  pwd_validator_err_digit: "Contain at least one number",
  pwd_validator_err_uppercase: "Contain at least one uppercase letter",
  pwd_validator_err_lowercase: "Contain at least one lowercase letter",
  pwd_validator_err_symbol: "Contain at least one special character",
};

export default messages;
