import React from "react";
import Logo from "../../components/Logo";

const AuthHeader = ({ title }) => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <div className="flex justify-center w-full">
        <Logo height="h-20"/>
      </div>
      <h2 className="mt-6 text-center text-white text-3xl font-bold">
        {title}
      </h2>
      {/*<div className="mt-2 text-center text-sm text-white">*/}
      {/*  <p className="font-medium">TECNOFORM - ANAGRAM</p>*/}
      {/*</div>*/}
    </div>
  );
};

export default AuthHeader;
