import {GanttBlocksType} from "@feature/commons/gantt/types";
import React from "react";
import {TIMESTAMP_OPTIONS} from "@feature/commons/lib";

export function IntervalInfo({block}: { block: GanttBlocksType }) {
  return (
    <div className="flex flex-col items-center gap-y-5">
      <div>
        {
          block.f_orders.map(
            o => (
              <li key={o.cod_order} className="list-none">
                <ul><strong>Codice Ordine: </strong>{o.cod_order}</ul>
                <ul><strong>Tipo: </strong>{o.cod_order_type}</ul>
                <ul><strong>Codice Articolo: </strong>{o.cod_item}</ul>
                <ul><strong>Numero Pose: </strong>{o.dict_order_information.val_pose}</ul>
                <ul><strong>Codice Materia Prima: </strong>{o.dict_order_information.cod_materia_prima}</ul>
                <ul><strong>Data Arrivo Materia Prima: </strong>{o.dict_order_information.dat_arrivo_materia_prima}</ul>
                <ul><strong>Deadline: </strong>{o.dat_deadline}</ul>
                <ul><strong>Numero Pezzi Ordinati: </strong>{o.dict_order_information.val_qty}</ul>
              </li>
            )
          )
        }
      </div>
      {block.equipments.length > 0 && <span><strong>Attrezzatura</strong></span>}
      <div>
        {
          block.equipments.map(
            (e, i) => (
              <li key={i} className="list-none">
                <ul><strong>Codice Attrezzatura: </strong>{[e.cod_equipment, e.des_equipment].join(' - ')}</ul>
              </li>
            )
          )
        }
      </div>
      <div className="flex gap-x-3 items-center justify-between">
        <span><strong>Inizio: </strong>{new Intl.DateTimeFormat('it-IT', TIMESTAMP_OPTIONS).format(new Date(block.tms_start))}</span>
        <span><strong>Fine: </strong>{new Intl.DateTimeFormat('it-IT', TIMESTAMP_OPTIONS).format(new Date(block.tms_end))}</span>
        <span><strong>Battute/Ore: </strong>{block.dict_block_information.num_battute_ora}</span>
      </div>
    </div>
  )
}