import React from "react";
import Spinner from "./Spinner";
import Icon from "./Icon";

const buttonStyles = {
  default: "text-white bg-am-600 hover:bg-am-700",
  white: "text-gray-700 bg-white hover:bg-gray-50",
  secondary: "text-am-700 bg-indigo-100 hover:bg-indigo-200",
  danger: "text-white bg-red-600 hover:bg-red-700",
};

const IconButton = ({
                      full,
                      className = "",
                      children,
                      styleType = "default",
                      submitting = false,
                      iconName,
                      label,
                      ...rest
                    }) => {
  let defaultClasses =
    "relative flex-wrap content-center uppercase border border-transparent font-medium rounded-full shadow-sm focus:outline-none active:opacity-70 ";

  if (buttonStyles[styleType]) {
    defaultClasses += buttonStyles[styleType];
  } else {
    defaultClasses += buttonStyles.default;
  }

  return (
    <button
      disabled={submitting}
      className={`${defaultClasses} ${className} ${full ? "w-full" : ""}`}
      {...rest}
    >
      <div className="flex flex-col items-center">
        <Icon iconName={iconName}/>
        <h4 className="text-tiny text-center">{label}</h4>
      </div>
      {submitting ? (
        <Spinner classes={buttonStyles[styleType]} styleType={styleType}/>
      ) : null}
    </button>
  );
};

export default IconButton;
