import {DispatchStateProps, FormAction} from "@feature/commons/types";
import Badge from "@ui-components/Badge";
import {ArrowTopRightOnSquareIcon, EyeIcon, PencilIcon} from "@heroicons/react/24/outline";
import {useHistory} from "react-router";


const iconClassNames = "h-7 w-7 text-gray-500"

export function ValidateDataEntry({state, dispatch}: DispatchStateProps) {

  const history = useHistory();

  return (
    <>
      <h2 className="text-am-600 font-bold">Dati</h2>
      <table className="min-w-full divide-y divide-am-600">
        <tbody className="bg-white border border-am-200">
        {
          state.DEValidation.map(
            el => (
              <tr key={el.field} className="border border-am-200 border-opacity-70">
                <td className="w-1/2 px-4 py-1">{el.field}</td>
                <td className="w-1/3">
                  {
                    el.valid
                      ? <Badge text="Dati Aggiornati"/>
                      : <Badge text="Dati da aggiornare per procedere" type="error"/>
                  }
                </td>
                <td className="flex justify-around px-2">
                  {
                    el.valid
                      ? <EyeIcon className={iconClassNames}/>
                      : <PencilIcon className={iconClassNames}
                                    onClick={
                                      () => dispatch({
                                        type: FormAction.addDEValidation,
                                        payload: state.DEValidation.map(de => ({
                                          ...de,
                                          valid: true
                                        }))
                                      })
                                    }/>
                  }
                  <ArrowTopRightOnSquareIcon
                    className={iconClassNames}
                    onClick={ () => history.push("/data_entry", {tab: el.field})}/>
                </td>
              </tr>
            )
          )
        }
        </tbody>
      </table>
    </>
  )
}
