import React from "react";
import {useIntl} from "react-intl";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";

export function SearchPlaceholder() {

	const intl = useIntl();

	return (
		<div className="flex items-center justify-center rounded-2xl bg-gray-50 shadow-lg h-96 z-0">
			<div className="inline-flex items-center gap-x-4 text-gray-500 font-semibold">
				<MagnifyingGlassIcon className="h-7 w-7"/>
				<h3>{intl.formatMessage({ id: "select_working_center"})}</h3>
			</div>
		</div>
	)
}