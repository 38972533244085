import React, {useEffect, useReducer} from "react";
import {initialState, reducer} from "@feature/commons/lib";
import {AnagramParams, TitleAndDescription} from "@feature/commons";
import {EltType, FormAction} from "@feature/commons/types";
import {DataEntryErrors} from "@feature/data-entry";
import {useFetchDtnErrors} from "@feature/data-entry/hooks";
import {DataEntryUrlState} from "@feature/data-entry/types";

export function NewSchedulingRun({urlState}: { urlState?: DataEntryUrlState }) {

  const [state, dispatch] = useReducer(reducer, initialState);

  const {errors} = useFetchDtnErrors();

  useEffect(() => {
    if (urlState?.title)
      dispatch({
        type: FormAction.addTitle,
        payload: urlState.title
      })

    if (urlState?.description)
      dispatch({
        type: FormAction.addDescription,
        payload: urlState.description
      })
  }, [urlState]);

  return (
    <>
      <TitleAndDescription dispatch={dispatch} state={state} eltType={EltType.sched}/>
      {state.title && state.description && <DataEntryErrors state={state} showRedirect errors={errors}/>}
      <AnagramParams eltType={EltType.sched} runParams={state} allowLaunch/>
    </>
  )
}
