import React from "react";
import Input from "@ui-components/Input";
import {EltType, FormAction, TitleAndDescriptionProps} from "@feature/commons/types";
import {useIntl} from "react-intl";
import {capitalize} from "@utils/index";

export function TitleAndDescription({dispatch, state, eltType}: TitleAndDescriptionProps) {
	
	const intl = useIntl();
	
	const msg = (id: string) => intl.formatMessage({id});
	
	let label, placeholder;
	
	if (eltType === EltType.sched) {
		label = msg("sched_title");
		placeholder = msg("add_sched");
	} else {
		label = msg("plan_title");
		placeholder = msg("add_plan");
	}
	
	return (
		<div className="flex gap-x-5 h-42">
			<Input
				classNames="w-1/3"
				labelClassnames="text-am-600 font-bold capitalize"
				label={label}
				value={state.title}
				required
				onChange={(e: React.FormEvent<HTMLInputElement>) => dispatch({
					type: FormAction.addTitle,
					payload: e.currentTarget.value
				})}
				onClear={() => dispatch({type: FormAction.addTitle, payload: ''})}
				placeholder={capitalize(placeholder)}
			/>
			<Input
				label={msg("description")}
				labelClassnames="text-am-600 font-bold capitalize"
				classNames="w-2/3"
				value={state.description}
				onChange={(e: React.FormEvent<HTMLInputElement>) => dispatch({
					type: FormAction.addDescription,
					payload: e.currentTarget.value
				})}
				onClear={() => dispatch({type: FormAction.addDescription, payload: ''})}
				placeholder={`${msg('add')} ${msg('description')}`}
			/>
		</div>
	)
}
