import {IntlShape} from "react-intl";

export const subtractMonths = (date: Date, months: number): Date => {
  date.setMonth(date.getMonth() - months);
  return date
}

export const addMonths = (date: Date, months: number): Date => {
  date.setMonth(date.getMonth() + months);
  return date
}

export const dateToString = (date: Date): string => new Intl.DateTimeFormat("fr-CA").format(date);


export const getOneYearAgo = (date: Date): Date => {
  date.setFullYear(date.getFullYear() - 1)
  date.setHours(0, 0, 0, 0)
  return new Date(date)
}

export const createLookup = <T extends object, Key extends keyof T>(data: T[], field: Key, intl: IntlShape): {
  [k: string]: string
} => data.reduce<{ [k: string]: string }>((lookup, el: T) => {
  const value = el[field] as string;
  if (!(value in Object.keys(lookup)))
    lookup[value] = intl.formatMessage({id: value})
  return lookup
}, {})

export const customMTFilter = (filterValue: string, ...fields: Array<string | undefined>) => fields.some(
  field => field?.toLowerCase().includes(filterValue.toLowerCase())
)