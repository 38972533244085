/**
 * Abstract Syntax Tree Node
 * index is the index of the token in the original expression
 * value is the token
 */
type NodeContainer = {
  value: string;
  index: number;
};

/**
 * Expression node
 * It is needed to build a tree from the expression
 * every node has an optional left and right node
 * if the node is a leaf left and right are null
 * if the node is an operator left and right are not null
 * the value of the node is the operator
 */
export class ENode {
  node: NodeContainer;
  left: ENode | null;
  right: ENode | null;
  constructor(
    value: NodeContainer,
    left: ENode | null = null,
    right: ENode | null = null
  ) {
    this.node = value;
    this.left = left;
    this.right = right;
  }
}

/**
 * Parser class
 * It parses an expression and builds a tree
 * from the expression
 * The resulting tree is a parsed representation of the original textual expression
 * for example:
 * (a and b) or c
 * is parsed as
 * or
 * / \
 * and c
 * / \
 * a b
 */
export class Parser {
  original_expression: string | string[];
  tokens: string[];
  index: number;

  constructor(expression: string | string[]) {
    this.original_expression = expression;
    if (Array.isArray(expression)) {
      this.tokens = expression;
    } else {
      // add single space around parenthesis
      expression = expression.replace(/\(/g, " ( ");
      expression = expression.replace(/\)/g, " ) ");
      expression = expression.replace(/\s+/g, " ");

      this.tokens = expression.split(" ").filter((x) => x.length > 0);
    }
    this.index = 0;
  }

  parse() {
    return this.parseExpression();
  }

  parseExpression(): ENode {
    let left = this.parseTerm();

    while (this.tokens[this.index] === "or") {
      let op = { value: this.tokens[this.index], index: this.index };
      this.index++;
      let right = this.parseTerm();
      left = new ENode(op, left, right);
    }

    return left;
  }

  parseTerm(): ENode {
    let left = this.parseFactor();

    while (this.tokens[this.index] === "and") {
      let op = { value: this.tokens[this.index], index: this.index };
      this.index++;
      let right = this.parseFactor();
      left = new ENode(op, left, right);
    }

    return left;
  }

  parseFactor(): ENode {
    if (this.tokens[this.index] === "(") {
      this.index++;
      let node = this.parseExpression();
      if (this.tokens[this.index] !== ")") {
        throw new Error("Expected )");
      }
      this.index++;
      return node;
    }
    let value = this.tokens[this.index];
    const newnode = new ENode({ value, index: this.index });
    this.index++;
    return newnode;
  }
}
