import {Dispatch, useContext, useEffect, useState} from "react";
import {Action, EltType, FormAction} from "../types";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";
import {useAsyncError} from "@hooks/useAsyncError";
import {NotificationsContext} from "@ui-components/Notifications";
import {ConfigurationSchema} from "@feature/data-entry/types";

export const useConfiguration = (dispatch: Dispatch<Action>, eltType: EltType) => {
	
	// context
	const {push} = useContext(NotificationsContext);
	
	// hooks
	const intl = useIntl();
	const throwError = useAsyncError();
	
	// state
	const [loading, setLoading] = useState(false);
	
	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				const {data} = await api.get<ConfigurationSchema>(`/configurations/${eltType}`);
				dispatch({
					type: FormAction.massiveParameterUpdate,
					payload: data
				})
			} catch (e) {
				push({type: 'error', title: intl.formatMessage({id: 'generic_error'})});
				throwError(e);
			} finally {
				setLoading(false);
			}
		})();
	}, [dispatch, eltType, intl, push, throwError]);
	
	return loading;
}