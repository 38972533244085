import {useState} from "react";
import Button from "@ui-components/Button";
import {ChevronDownIcon} from "@heroicons/react/24/outline";
import Slider from "@ui-components/Slider";
import {ClientVsDeadlineType, EnergyVsWasteType, RunFullSchema, MapClientVsDeadlineTypeToInt, MapEnergyVsWasteTypeToInt} from "@feature/commons/types";
import {getMarks} from "@feature/commons/lib";
import {useIntl} from "react-intl";


function RunParamsPopup({state}: { state: Pick<RunFullSchema, 'client_vs_deadline_param' | 'energy_vs_waste_param'> }) {
	
	const intl = useIntl();
	
	const [open, setOpen] = useState<boolean>(false)
	
	return (
		<div style={{width: "440px"}}>
			<Button styleType="white" classNames="w-full p-2" onClick={() => setOpen(!open)}>
				{intl.formatMessage({id: "show_inserted_params"})}
				<ChevronDownIcon
					style={{transform: `rotate(${open ? -180 : 0}deg)`, display: "inline"}}
					className=" h-5 ml-2 transition-all ease-out duration-500"
				/>
			</Button>
			<div
				style={{width: "440px"}}
				className={`absolute bg-gray-200 z-20 flex flex-col items-center justify-evenly rounded-2xl ${open ? 'px-5 h-48' : 'h-0'} overflow-hidden transition-all duration-500`}
			>
				<Slider
					style={{width: 250, pointerEvents: "none"}}
					step={null}
					marks={getMarks(ClientVsDeadlineType, intl)}
					valueLabelDisplay="off"
					max={2}
					onChange={() => null}
					color={"secondary"}
					value={MapClientVsDeadlineTypeToInt[ClientVsDeadlineType[state.client_vs_deadline_param]]}
				/>
				<Slider
					style={{width: 250, pointerEvents: "none"}}
					step={null}
					marks={getMarks(EnergyVsWasteType, intl)}
					valueLabelDisplay="off"
					color={"secondary"}
					max={2}
					onChange={() => null}
					value={MapEnergyVsWasteTypeToInt[EnergyVsWasteType[state.energy_vs_waste_param]]}
				/>
			</div>
		</div>
	)
}

export default RunParamsPopup;