import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

function SingleFaq({ question, answer }) {
  const [showAnswer, setShowAnswer] = useState(true);
  const [firstRender, setFirstRender] = useState(true);
  const [answerHeight, setAnswerHeight] = useState();

  const ref = useRef(null);

  useEffect(() => {
    if (firstRender) {
      setAnswerHeight(ref.current?.clientHeight.toString() + 'px')
      setShowAnswer(false);
      setFirstRender(false);
    }
  }, [firstRender]);

  return (
    <div className="flex flex-col">
      <div>
        <div 
          className="cursor-pointer inline-block opacity-100 font-bold hover:opacity-50"
          onClick={() => {
            setShowAnswer(!showAnswer);
          }}
        >
          {question}
        </div>
      </div>
      <FaqAnimation
        ref={ref}
        height={`${showAnswer ? answerHeight : "0px"}`}
      >
        {answer}
      </FaqAnimation>
    </div>
  );
}

export default SingleFaq;

const FaqAnimation = styled.p`
  overflow: hidden;
  height: ${props => props.height};
  transition: height 0.95s cubic-bezier(0.075, 0.82, 0.165, 1);
`;
