import {useIntl} from "react-intl";
import {PageHeading} from "@ui-components/Container";
import {LogsTable} from "@feature/logging";


function Logging() {

  const intl = useIntl();

  return (
    <>
      <PageHeading title={intl.formatMessage({id: "data_registry"})} contentActions={undefined}/>
      <LogsTable/>
    </>
  );
}

export default Logging;
