import { SelectOptionsSchema } from "@feature/commons/types";

export enum AccatastamentoOptions {
	"A uno" = "A uno",
	"A due" = "A due",
	"A tre" = "A tre",
	"A quattro" = "A quattro",
}

export enum LaneTypeOptions {
	"Corsia Normale" = "Corsia Normale",
	"Scaffale" = "Scaffale",
}

export interface DataEntryMagSchema {
	flg_disabled: boolean,
	flg_modified: boolean,
}

export interface CapacitaSchema extends DataEntryMagSchema {
	cod_warehouse: string,
	des_warehouse?: string,
	cod_lane: string,
	des_lane?: string,
	num_max_pallet?: number,
}

export interface AccatastamentoSchema extends DataEntryMagSchema {
	cod_family: string,
	des_family?: string,
	cod_sub_family: string,
	des_sub_family?: string,
	des_accatastamento_default?: AccatastamentoOptions,
	des_accatastamento_manuale?: AccatastamentoOptions,
	flg_active: boolean,
}

export interface ConversioniSchema extends DataEntryMagSchema {
	cod_item: string,
	des_item?: string,
	des_um?: string,
	num_max_um_pallet?: number,
}

export interface PrioritaMagazziniSchema extends DataEntryMagSchema {
	cod_warehouse: string
	des_warehouse?: string,
	cod_family: string,
	des_family?: string,
	cod_sub_family: string,
	des_sub_family?: string,
	id_preference: number,
}

export interface SaturazioneSchema extends DataEntryMagSchema {
	cod_warehouse: string,
	des_warehouse?: string,
	perc_saturazione?: number,
	id: number,     // Unique but not Primary Key, used for deletion purposes.
}

export enum WarehouseMonitoringTabs {
	current_state = "current_state",
	forecast = "forecast",
	// consuntivo = "consuntivo",    "COULD" tab at the moment.
}

export enum WarehouseCurrentStateKpis {
	perc_saturation = "perc_saturation",
	val_pallet = "val_pallet",
	amt_stock = "amt_stock",
}

export enum WarehouseCurrentStateDimensionFilters {
	cod_warehouse = "cod_warehouse",
	cod_lane = "cod_lane",
	cod_item_type = "cod_item_type",
}

export enum WarehouseCurrentStateDimensionAggregation {
	cod_warehouse = "cod_warehouse",
	cod_lane = "cod_lane",
}

export enum WarehouseForecastKpis {
	val_pallet = "val_pallet",
	amt_stock = "amt_stock",
}

export enum WarehouseForecastTimeAggregation {
	week = "week",
	month = "month",
}

export enum WarehouseForecastDimensionAggregation {
	total = "total",
	cod_warehouse = "cod_warehouse",
	cod_lane = "cod_lane",
	cod_item = "cod_item",
}

export enum WarehouseForecastDimensionFilters {
	cod_warehouse = "cod_warehouse",
	cod_lane = "cod_lane",
	cod_item = "cod_item",
}

export const WarehouseForecastUnitMeasures = {
	val_pallet: {
		"valueLabel": "Pallet",
		"unitMeasure": "pallet",
	},
	amt_stock: {
		"valueLabel": "Valore",
		"unitMeasure": "€",
	},
}

export interface RunMagazzinoSchema {
	id_run: number,
	tms_start: string,
	tms_end: string,
}

export interface AnagraficaMagazzinoSchema {
	cod_warehouse: string,
	des_warehouse: string,
	perc_saturazione?: number,
}

export interface AnagraficaCorsiaSchema {
	cod_warehouse: string,
	cod_lane: string,
	des_lane?: string,
	num_max_pallet?: number,
}

export interface AnagraficaTipoArticoloSchema {
	cod_item_type: string,
	des_item_type?: string,
}

export interface AnagraficaArticoloSchema {
	cod_item: string
	des_item?: string, 
	cod_item_type: string
}

export interface CurrentStateProps {
	warehouseOptions: AnagraficaMagazzinoSchema[] | undefined,
	laneOptions: AnagraficaCorsiaSchema[] | undefined,
	itemTypesOptions?: AnagraficaTipoArticoloSchema[] | undefined,
	warehouseSelected: SelectOptionsSchema[],
	laneSelected: SelectOptionsSchema[],
	itemTypesSelected: SelectOptionsSchema[],
	setWarehouseSelected: React.Dispatch<React.SetStateAction<SelectOptionsSchema[]>>,
	setLaneSelected: React.Dispatch<React.SetStateAction<SelectOptionsSchema[]>>,
	setItemTypesSelected?: React.Dispatch<React.SetStateAction<SelectOptionsSchema[]>>,
	lastRunParameters: RunMagazzinoSchema | undefined,
}

export interface ForecastFiltersProps extends CurrentStateProps {
	kpiSelected: WarehouseForecastKpis,
	timeAggregationSelected: WarehouseForecastTimeAggregation,
	tableMode: boolean,
}

export interface WarehouseDimensionResponseSchema {
	cod_dimension: string,
	des_dimension: string,
	val_dimension: number,
}

export interface CurrentStateDataProps extends CurrentStateProps {
	dateSelected: Date,
	kpiSelected: WarehouseCurrentStateKpis,
	itemTypesSelected: SelectOptionsSchema[],
	lastRunParameters: RunMagazzinoSchema,  // Here is surely defined.
}

export interface WarehouseCurrentStateFilterValuesSchema {
	cod_dimension: (
		WarehouseCurrentStateDimensionFilters.cod_warehouse |
		WarehouseCurrentStateDimensionFilters.cod_lane |
		WarehouseCurrentStateDimensionFilters.cod_item_type
	),
	values: string[],
}

export interface GetWarehouseCurrentStatePayloadSchema {
	id_run: number,
	dat_reference: string,
	cod_aggregation: WarehouseCurrentStateDimensionAggregation.cod_warehouse | WarehouseCurrentStateDimensionAggregation.cod_lane,
	filter_values: WarehouseCurrentStateFilterValuesSchema[],
}

export interface GetWarehouseOptionsSchema {
	des_order_by_column: string,
	cod_filter_column?: string,
	filter_values?: string[],
}


export interface WarehouseForecastFilterValuesSchema {
	cod_dimension: WarehouseForecastDimensionFilters.cod_warehouse | WarehouseForecastDimensionFilters.cod_lane |  WarehouseForecastDimensionFilters.cod_item,
	values: string[],
}

export interface GetWarehouseForecastPayloadSchema {
	id_run: number,
	cod_aggregation_time: WarehouseForecastTimeAggregation.week | WarehouseForecastTimeAggregation.month,
	cod_aggregation_dimension: (
		WarehouseForecastDimensionAggregation.cod_warehouse | 
		WarehouseForecastDimensionAggregation.cod_lane |
		WarehouseForecastDimensionAggregation.cod_item |
		WarehouseForecastDimensionAggregation.total
	),
	filter_values: WarehouseForecastFilterValuesSchema[],
}

export interface WarehouseForecastResponseSchema {
	dat_forecast: string,
	dimensions: WarehouseDimensionResponseSchema[],
}

export interface WarehouseForecastDataContainerProps {
	kpiSelected: WarehouseForecastKpis,
  idRun: number,
  timeAggregationSelected: WarehouseForecastTimeAggregation,
  dimensionAggregationSelected: WarehouseForecastDimensionAggregation,
  warehouseSelected: SelectOptionsSchema[],
  laneSelected: SelectOptionsSchema[],
  itemSelected: SelectOptionsSchema[],
	tableMode: boolean,
}