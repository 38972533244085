import {ClientVsDeadlineType, DataValidationType, EnergyVsWasteType, NewRunType} from "@feature/commons/types";
import {subtractMonths} from "@utils/utils";

const dataValidation: DataValidationType[] = [
	{field: "Dati da sistema", valid: true},
	{field: "Data Entry", valid: true},
	{field: "Tipologia Dati 1", valid: false},
	{field: "Tipologia Dati 2", valid: false},
	{field: "Tipologia Dati 3", valid: false}
]

export const initialState: NewRunType = {
	title: '',
	description: '',
	client_vs_deadline_param: ClientVsDeadlineType.BALANCED,
	energy_vs_waste_param: EnergyVsWasteType.BALANCED,
	DEValidation: dataValidation,
}

export const QUEUE_NAME = "run-optimization"

export const LOG_FROM_DATE = subtractMonths(new Date(), 1)

export const TIMESTAMP_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit'
}