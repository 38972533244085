import React from "react";
import MaterialTable from "@material-table/core";
import {useIntl} from "react-intl";
import {BrokenRecordType, BrokenTablesTypesWithListSchema} from "../types";
import {getColumns} from "./params";
import TableStyle from "@ui-components/TableStyle";
import {getCommonProps} from "@feature/commons/lib";


export function GenericBrokenTable({data}: { data: BrokenTablesTypesWithListSchema }) {
	const intl = useIntl()
	const {data: listBrokenTables, table_name, descr} = data ?? {}

	const columns = getColumns(data.dict_types)
	const commonProps = getCommonProps<BrokenRecordType>(intl)
	
	
	return (
		<TableStyle>
			<MaterialTable
				columns={columns}
				data={listBrokenTables.map(el => ({...el.dict_information}))}
				isLoading={!listBrokenTables.length}
				title={`${descr}: ${table_name}`}
				{...commonProps}
			/>
		</TableStyle>
	)
}
