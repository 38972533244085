import {useParams} from "react-router";
import {useIntl} from "react-intl";
import {useState} from "react";
import {RunDetailsVisualizationsPlan as RDVP} from "@feature/plan/types";
import {PlanDetails} from "@feature/plan/plan-details";
import {HeatMap} from "@feature/plan/heatmap";
import {PageHeading} from "@ui-components/Container";
import {BrokenTable} from "@feature/commons/broken-tables";
import Button from "@ui-components/Button";
import {EltType} from "@feature/commons/types";

export function PlanSummary() {
	
	const {id_run} = useParams<{ id_run: string }>();
	
	const intl = useIntl();
	
	const title = intl.formatMessage({id: "plan_details"})
	
	const [currentVisualization, setCurrentVisualization] = useState<RDVP>(RDVP.plan_details);
	
	const renderVisualization = (currentViz: RDVP) => {
		switch (currentViz) {
			case RDVP.plan_details:
				return <PlanDetails/>
			case RDVP.orders_not_planned:
				return <BrokenTable elt_type={EltType.plan}/>
			case RDVP.heatmap:
				return <HeatMap/>
		}
	}
	
	return (
		<div className="flex flex-col gap-y-12 mb-10">
			<PageHeading title={`${title}: ${id_run}`} contentActions={undefined} historyBackPath="/runs_planning"/>
			<div className="flex 2xl:w-1/2 h-20 justify-evenly items-center">
				{
					Object.keys(RDVP).map(
						b => (
							<Button
								styleType={b === currentVisualization ? "default" : "white"}
								onClick={() => setCurrentVisualization(b as RDVP)}
								children={intl.formatMessage({id: b})}/>
						)
					)
				}
			</div>
			{
				renderVisualization(currentVisualization)
			}
		</div>
	)
}
