import React from "react";
import { useIntl } from "react-intl";
import { format_password_message } from "./lib";

export function PasswordErrors({ errors }) {
  const intl = useIntl();
  const passwordErrors = format_password_message(errors, intl, false);

  if (!passwordErrors || passwordErrors.length === 0) {
    return null;
  }

  return (
    <div className="my-3 text-xs text-red-600">
      <div className="text-xs text-red-500 mt-2 mb-4">
        <div>{intl.formatMessage({ id: `pwd_validator_pwd_must` })}:</div>
        <div className="mb-2 mt-1 flex flex-col pl-2 gap-1">
          {passwordErrors.map((m) => (
            <div key={m}>{m}</div>
          ))}
        </div>
      </div>
    </div>
  );
}
