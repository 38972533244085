import React, {useRef} from "react";
import MaterialTable from "@material-table/core";
import {useIntl} from "react-intl";
import TableStyle from "@ui-components/TableStyle";
import {useFetchLogs} from "@feature/logging/hooks";
import {getCommonProps} from "@feature/commons/lib";
import {LoggingSchema} from "@feature/logging/types";
import {columns} from "./params";

type T = LoggingSchema;

export function LogsTable() {

  const intl = useIntl();

  const ref = useRef<MaterialTable<T[]>>();


  const {fetch, loading} = useFetchLogs(columns);


  const commonProps = getCommonProps<T>(intl);

  return (
    <TableStyle>
      <MaterialTable
        tableRef={ref}
        columns={columns}
        {...commonProps}
        data={fetch!}
        isLoading={loading}
        title=""
      />
    </TableStyle>
  )
}