import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";
import { useIntl } from "react-intl";
// Model & Hooks
import {useFetchKPIs} from "./hooks";
import {GanttAndKpiProps} from "./types";
import {GanttBlocksType} from "@feature/commons/gantt/types";
import {useFetchGanttData} from "@feature/commons/hooks";
import {EltType} from "@feature/commons/types";
import { useFetchMachinesAndDateLimits } from "./hooks/useFetchMachinesAndDateLimits";
// Components
import {GanttAndKpiFilters} from "./gantt-and-kpi-filters";
import {GanttChart, GanttTable} from "@feature/commons/gantt";
import {GaugeChart} from "@feature/commons/gauge";
import Alert from "@ui-components/Alert";
import {BlurredPlaceholder} from "@feature/commons";
// Date Services
import {addMonths, dateToString} from "@utils/utils";

type T = GanttBlocksType;

export function GanttAndKpi({
  idRun,
  group,
  datesLimits,
  ganttAndKpiFilters,
  setDateLimits,
  setGanttAndKpiFilters,
  runSchedParams,
}: GanttAndKpiProps) {
  const intl = useIntl();

  const [refetchBlocks, setRefetchBlocks] = useState<boolean>(false);

  const {loading: machinesLoading, machinesAndDateLimits: options} = useFetchMachinesAndDateLimits(idRun, group, EltType.sched);

  const {loading: KpiLoading, kpi} = useFetchKPIs(
    idRun,
    EltType.sched,
    {
      machines: ganttAndKpiFilters.machines,
      dat_start: ganttAndKpiFilters.date_start,
      dat_end: ganttAndKpiFilters.date_end,
      dateLimitsFirstRender: ganttAndKpiFilters.dateLimitsFirstRender,
    }
  )

  const {loading: blocksLoading, blocks: blocksData, setBlocks: setBlocksData} = useFetchGanttData(
    idRun,
    EltType.sched,
    {...ganttAndKpiFilters, group},
    refetchBlocks
  )

  useEffect(() => {
    /* 
      This useEffect is used to set the max-start-date and max-end-date selectable of data available.
      By default, ganttFilters will be set with {date_start: datStart, date_end: datStart + 3 months}.
    */
    if (options && datesLimits.firstRender) {
      setDateLimits({
        dateStart: new Date(options.dateLimits.dateStart),
        dateEnd: new Date(options.dateLimits.dateEnd),
        firstRender: false,
      });
      setGanttAndKpiFilters({
        ...ganttAndKpiFilters,
        date_start: dateToString(new Date(options.dateLimits.dateStart)),
        date_end: dateToString(addMonths(new Date(options.dateLimits.dateStart), 3)),
        dateLimitsFirstRender: false,
      });
    }
  }, [options, datesLimits, ganttAndKpiFilters, setGanttAndKpiFilters, setDateLimits]);

  // start with all machines selected as default
  useEffect(() => {
      if (machinesLoading)
        return

      if (options && options.machines.length > 0) {
        setGanttAndKpiFilters(
          p => (
            {
              ...p,
              machines: options?.machines.map(el => el.value) ?? [],
            }
          )
        )
      }
    },
    [options, machinesLoading, setGanttAndKpiFilters]
  )

  // filter blocksData based on search_keyword (input search component which filters in all fields of gantt block tooltip)
  const categoriesData = useMemo(() => blocksData?.filter(
    bD => ganttAndKpiFilters.search_keyword
      ? (
        bD.des_block.toLowerCase().includes(ganttAndKpiFilters.search_keyword?.toLowerCase())
        || bD.f_orders.some(
          fO => fO.cod_order.toLowerCase().includes(ganttAndKpiFilters.search_keyword!.toLowerCase())
            || fO.dict_order_information.val_pose.toString() === ganttAndKpiFilters.search_keyword
            || fO.cod_order_type.toLowerCase().includes(ganttAndKpiFilters.search_keyword!.toLowerCase())
            || fO.cod_item.toLowerCase().includes(ganttAndKpiFilters.search_keyword!.toLowerCase()
            )
        )
      )
      : true
  ) ?? [], [blocksData, ganttAndKpiFilters.search_keyword])

  return (
    <BlurredPlaceholder loading={machinesLoading || !options || KpiLoading || !kpi || blocksLoading || !blocksData}>
      {
        ganttAndKpiFilters.machines && ganttAndKpiFilters.machines.length > 0
          ? (
            ganttAndKpiFilters.toggleTable ? 
              <GanttTable idRun={idRun} categoriesData={categoriesData} eltType={EltType.sched}/> :
              <GanttChart
                eltType={EltType.sched}
                categoriesData={categoriesData}
                setCategoriesData={setBlocksData as Dispatch<SetStateAction<T[]>>}
                id="hisMajestyTheGantt"
                editable={false}
                machineOptions={options!.machines}
                setRefetchBlocks={setRefetchBlocks}
                filterParams={{
                  idRun,
                  group,
                  ...ganttAndKpiFilters
                }}
              />
          )
          : <Alert title={intl.formatMessage({ id: "choose_a_machine"})} classNames="mx-auto my-20"/>
      }
      <GanttAndKpiFilters
        ganttAndKpiFilters={ganttAndKpiFilters}
        setGanttAndKpiFilters={setGanttAndKpiFilters}
        runSchedParams={runSchedParams}
        machineOptions={options?.machines ?? []}
      />

      <div className="grid gap-x-6 grid-cols-1 sm:grid-cols-2 xl:grid-cols-4">
        {
          kpi && kpi.map((el, i) => (
            <GaugeChart
              key={el.id_kpi}
              id={el.id_kpi.toString()}
              data={el.val_average_mean * 100}
              seriesLabel={el.name_kpi}
              tooltipText={el.des_kpi}
              colorIdx={i}
            />
          ))
        }
      </div>
    </BlurredPlaceholder>
  )
}
