import {useContext, useState} from "react";
import {useAsyncError} from "@hooks/useAsyncError";
import {NotificationsContext} from "@ui-components/Notifications";
import {fetchPagination, LOG_FROM_DATE} from "@feature/commons/lib";
import {LoggingSchema} from "@feature/logging/types";
import {Query} from "@material-table/core";
import {MTColumnsType} from "@utils/pagination";
import {dateToString} from "@utils/utils";

type T = LoggingSchema;
export const useFetchLogs = (
  columns: MTColumnsType<T>,
) => {

  // hooks
  const {push} = useContext(NotificationsContext);
  const throwError = useAsyncError();


  // state
  const [loading, setLoading] = useState<boolean>(false);

  const baseUrl = `/audit/requests/from/${dateToString(LOG_FROM_DATE)}/paginated`;

  const fetch = async (query: Query<T>) => fetchPagination(
    baseUrl,
    query,
    columns,
    undefined,
    undefined,
    push,
    setLoading,
    throwError,
  )
  return {fetch, loading};
}