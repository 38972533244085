import {Dispatch, SetStateAction} from "react";
import {DateFiltersType, RunFullSchema} from "@feature/commons/types";

export enum ManageSchedulingVisualizations {
	new_scheduling = 'new_scheduling',
	scenarios = 'scenarios',
	edit_plan = 'edit_plan'
}

export interface RunFiltersProps {
	dateRange: DateFiltersType
	setDateRange: Dispatch<SetStateAction<DateFiltersType>>
	selectedOrders: Array<string>
	setSelectedOrders: Dispatch<SetStateAction<Array<string>>>,
}

export type RunSchedParams = Pick<RunFullSchema, 'client_vs_deadline_param' | 'energy_vs_waste_param'>

export enum RunDetailsVisualizationsSched {
	schedule_details = "schedule_details",
	orders_not_scheduled = "orders_not_scheduled",

	// Tecnoform specific
	// product_value = "product_value",
}

export interface SchedulingDetailsProps {
	homeRunId?: string,
	client_vs_deadline_param?: RunSchedParams['client_vs_deadline_param'],
	energy_vs_waste_param?: RunSchedParams['energy_vs_waste_param'],
}

export interface SchedulingSummaryProps extends SchedulingDetailsProps {
	title: string,
}

export interface DateLimits {
	dateStart: Date,
	dateEnd: Date,
	firstRender: boolean,
}