import {BrokenImage} from '@material-ui/icons'
import {useParams} from "react-router";
import React, {useState} from "react";
import {IconButton} from "@material-ui/core";
import {BrokenTablesTypesWithListSchema} from "../types";
import {GenericBrokenTable} from "@feature/commons/broken-tables";
import {useBrokenTable} from "@feature/commons/hooks";
import {BlurredPlaceholder} from "@feature/commons";
import {EltType} from "@feature/commons/types";


export function BrokenTable({elt_type, homeRunId}: { elt_type: EltType, homeRunId?: string}) {
	const {id_run} = useParams<{ id_run: string }>();
	if (id_run === undefined && homeRunId === undefined) {
		throw new Error("id_run is undefined");
	}
	const definitiveIdRun = id_run ?? homeRunId;

	const {data: brokenTables, loading} = useBrokenTable(parseInt(definitiveIdRun), elt_type);
	const [showErrorBrokenTables, setShowErrorBrokenTables] = useState<BrokenTablesTypesWithListSchema>()
	
	return (
		<BlurredPlaceholder loading={loading}>
			<table className="min-w-full divide-y divide-am-600">
				<tbody className="bg-white border border-am-200">
				{
					brokenTables && brokenTables.map(
						errorBrokenTables => {
							const {id, descr, total} = errorBrokenTables
							return (
								<tr key={id}
								    className={`border border-am-200 font-semibold border-opacity-70 ${total && 'bg-am-400 text-white'}`}>
									<td className="w-1/2 px-4 py-1 capitalize">{descr}</td>
									<td className="w-1/3 text-center">
										<span className={`text-center ${total && 'rounded-full px-2 bg-red-600 text-white'}`}>
											{total}
										</span>
									</td>
									<td className={`text-center ${!total && 'opacity-60 pointer-events-none'}`}>
										<IconButton
											onClick={() => setShowErrorBrokenTables(
												showErrorBrokenTables?.id === errorBrokenTables.id ? undefined : errorBrokenTables
											)}>
											<BrokenImage
												className={`${showErrorBrokenTables?.id === errorBrokenTables.id ? "text-am-200" : ""} w-7 h-7 hover:text-white `}/>
										</IconButton>
									</td>
								</tr>
							)
						}
					)
				}
				</tbody>
			</table>
			{showErrorBrokenTables && <GenericBrokenTable data={showErrorBrokenTables!}/>}
		</BlurredPlaceholder>
	)
}
