import { createContext, useContext } from "react";
import { useApiRequestInterceptors } from "@hooks/useApiRequestInterceptors";

const ApiAuthContext = createContext(null);

export const useApiAuthContext = () => {
  const context = useContext(ApiAuthContext);

  if (!context) {
    throw new Error("useApiAuthContext must be used within a ApiAuthProvider");
  }

  return context;
};

export const ApiAuthProvider = ({ children }) => {
  const apiAuth = useApiRequestInterceptors();

  return (
    <ApiAuthContext.Provider value={apiAuth}>
      {children}
    </ApiAuthContext.Provider>
  );
};

export const useApiAuth = () => {
  const apiAuth = useApiAuthContext();

  return apiAuth;
};
