const messages = {
  pwd_validator_pwd_must: "La password deve",
  pwd_validator_err_min_length: "Essere lunga almeno 8 caratteri",
  pwd_validator_err_digit: "Contenere almeno un numero",
  pwd_validator_err_uppercase: "Contenere almeno una lettera maiuscola",
  pwd_validator_err_lowercase: "Contenere almeno una lettera minuscola",
  pwd_validator_err_symbol: "Contenere almeno un carattere speciale",
};

export default messages;
