import { useContext, useCallback } from "react";
import { NotificationsContext } from "@ui-components/Notifications";
import { useIntl } from "react-intl";

export const useToast = () => {
  const { push } = useContext(NotificationsContext);
  const intl = useIntl();

  if (push == null) {
    throw Error(
      'Notification context unavailable – this component needs to be a child of <Notifications>.',
    );
  }

  const toast = useCallback(
    (id, type, timeout, ...rest) => {
      push({
        title: intl.formatMessage({ id }),
        type,
        timeout,
        ...rest,
      });
    },
    [push, intl]
  );

  return toast;
};
