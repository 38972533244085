import React from "react";
import moment from "moment";

export const COLUMNS = [
  {
    title: "Timestamp",
    field: "timestamp",
    type: "datetime",
    defaultSort: "desc",
    render: rowData => <>{(moment(rowData['timestamp']).format("YYYY-MM-DD HH:mm:ss"))}</>
  },
  {
    title: "Endpoint",
    field: "route",
    type: "string",
  },
  {
    title: "Route URL",
    field: "url",
    type: "string",
  },
  {
    title: "Payload",
    field: "URLSearchParams",
    type: "string",
  },
  {
    title: "Error",
    field: "error",
    type: "string",
    render: rowData => <div
      style={{maxHeight: 100, maxWidth: 200,  overflow: "scroll", fontSize: 10}}
    >
      {rowData['error']}
    </div>
  },
  {
    title: "Exception",
    field: "exception",
    type: "string",       // Numeric
  },
]

export const OPTIONS = {
  filtering: true,
  toolbar: false,
  paging: true,
  sorting: true,
  headerStyle: {fontWeight: "bold", fontSize: 15},
  filterCellStyle: {paddingTop: 8, maxWidth: 200},
  cellStyle: {fontSize: 12},
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} righe",
    labelRowsSelect: "Righe",
  },
  body: {
    emptyDataSourceMessage: <h1>Non ci sono errori nella data selezionata (Yay!)😊</h1>
  }
};