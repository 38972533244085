import {useContext, useEffect, useState} from "react";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";
import {useAsyncError} from "@hooks/useAsyncError";
import {NotificationsContext} from "@ui-components/Notifications";
import {DtnTableSchema} from "@feature/data-entry/types";

type T = DtnTableSchema[];
export const useFetchTabs = (
  schema: string = 'dtn'
) => {

  // context
  const {push} = useContext(NotificationsContext);

  // hooks
  const intl = useIntl();
  const throwError = useAsyncError();

  // state
  const [loading, setLoading] = useState<boolean>(false);
  const [tabs, setTabs] = useState<T>();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const {data} = await api.get<T>(`/data_entry/${schema}/tabs`);
        setTabs(data)
      } catch (e) {
        push({type: 'error', title: intl.formatMessage({id: 'generic_error'})});
        throwError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [intl, push, schema, throwError]);

  return {loading, tabs};
}