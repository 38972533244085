import {useHistory} from "react-router";
import MaterialTable, {Query} from "@material-table/core";

import {ArrowTopRightOnSquareIcon, BookmarkIcon} from "@heroicons/react/24/outline";
import {BookmarkIcon as BookmarkFull} from "@heroicons/react/24/solid";
import {getCommonProps, setDefaultRun} from "@feature/commons/lib";
import {useIntl} from "react-intl";
import TableStyle from "@ui-components/TableStyle";
import {getColumns, getRunMapping} from "./params";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import {DateFiltersType, EltType, Status} from "@feature/commons/types";
import {RunFullSchema} from "../types";
import {NotificationsContext} from "@ui-components/Notifications";
import {usePaginatedRuns} from "@feature/commons/hooks/usePaginatedRuns";

type T = RunFullSchema;

const classMedium: HTMLElement['className'] = "flex-shrink-0 h-6 w-6 text-am-600 group-hover:text-am-700";

export function RunsTable({dateFilters, eltType}: { dateFilters: DateFiltersType, eltType: EltType }) {

  const {push} = useHistory();

  const intl = useIntl();

  const notification = useContext(NotificationsContext);

  const ref = useRef<MaterialTable<T[]>>();

  const didMount = useRef(false);

  const [refetch, setRefetch] = useState<number>(0);
  const [query, setQuery] = useState<Query<T>>();

  const commonProps = getCommonProps<T>(intl);

  const refreshTrigger = () => setRefetch(p => p + 1);

  useEffect(() => {
    if (didMount.current)
      refreshTrigger();
    else didMount.current = true;
  }, [eltType, dateFilters]);

  const dynamicData = eltType === EltType.plan
    ? {
      successStatus: Status.PRS_PLAN_ENDED_SUCCESS,
      redirectLink: 'runs_planning/',
      downloadName: 'Run Plan'
    }
    : {
      successStatus: Status.PRS_SCHED_ENDED_SUCCESS,
      redirectLink: 'runs_scheduling/',
      downloadName: 'Run Sched'
    }

  const columns = useMemo(() => getColumns(intl, eltType), [intl, eltType])

  const {fetch, loading, download} = usePaginatedRuns(eltType, dateFilters, columns);

  return (
    <TableStyle>
      <MaterialTable
        tableRef={ref}
        key={refetch}
        {...commonProps}
        columns={columns}
        data={fetch!}
        isLoading={loading}
        onFilterChange={() => setQuery((ref.current?.state as any)?.query)}
        title=""
        actions={[
          rowData => ({
            icon: () => <ArrowTopRightOnSquareIcon className={classMedium}/>,
            onClick: () => push(dynamicData.redirectLink + rowData.id, {
              client_vs_deadline_param: rowData.client_vs_deadline_param,
              energy_vs_waste_param: rowData.energy_vs_waste_param
            }),
            isFreeAction: false,
            hidden: rowData.tms_end === null,
          }),
          rowData => ({
            hidden: rowData.tms_end === null,
            icon: () => rowData.flg_default_run ? <BookmarkFull className={classMedium}/> :
              <BookmarkIcon className={classMedium}/>,
            onClick: () => setDefaultRun(rowData.id, eltType, intl, notification.push, refreshTrigger),
            isFreeAction: false,
            disabled: rowData.flg_default_run
          }),
          {
            ...commonProps.downloadAction,
            onClick: () => download!("RunExport", query, getRunMapping(eltType, intl)),
            hidden: true // FIXME: currently hidden since returns error
          },
        ]}
      />
    </TableStyle>
  )
}