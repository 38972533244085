import {useContext, useEffect, useState} from "react";
import {BrokenTablesTypesWithListSchema} from "../types";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";
import {useAsyncError} from "@hooks/useAsyncError";
import {NotificationsContext} from "@ui-components/Notifications";
import {EltType} from "@feature/commons/types";

type T = BrokenTablesTypesWithListSchema[];
export const useBrokenTable = (idRun: number, elt_type: EltType) => {
	
	// context
	const {push} = useContext(NotificationsContext);
	
	// hooks
	const intl = useIntl();
	const throwError = useAsyncError();
	
	// state
	const [data, setData] = useState<T>();
	const [loading, setLoading] = useState<boolean>(false);
	
	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				const {data} = await api.get<T>(`/broken_tables/${elt_type}/${idRun}`);
				setData(data);
			} catch (e) {
				push({type: 'error', title: intl.formatMessage({id: 'generic_error'})});
				throwError(e);
			} finally {
				setLoading(false);
			}
		})();
	}, [elt_type, idRun, intl, push, throwError]);
	
	return {loading, data};
}