import {useContext, useEffect, useState} from "react";
import {EltType} from "../types";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";
import {useAsyncError} from "@hooks/useAsyncError";
import {NotificationsContext} from "@ui-components/Notifications";

export const useFetchLastRun = (eltType: EltType) => {

  // context
  const {push} = useContext(NotificationsContext);

  // hooks
  const intl = useIntl();
  const throwError = useAsyncError();

  // state
  const [loading, setLoading] = useState(false);
  const [lastRun, setLastRun] = useState<Date>();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const {data} = await api.get<string>(`/runs/${eltType}/last_run`);
        setLastRun(new Date(data));
      } catch (e) {
        push({type: 'error', title: intl.formatMessage({id: 'generic_error'})});
        throwError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [eltType, intl, push, throwError]);

  return {loading, lastRun}
}