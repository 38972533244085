import {Color} from "./theme.types";

export const chartGenericPalette: Array<Color> = [
  "rgb(0, 60, 80)",
  "rgb(8, 127, 140)",
  "rgb(90, 170, 149)",
  "rgb(134, 168, 115)",
  "rgb(152, 151, 102)",
  "rgb(187, 159, 6)",
  "rgb(222, 164, 54)",
  "rgb(234, 151, 59)",
  "rgb(234, 120, 59)",
  "rgb(206, 96, 76)",
  "rgb(238, 130, 168)",
  "rgb(232, 131, 219)",
  "rgb(166, 101, 183)",
  "rgb(109, 97, 150)",
  "rgb(80, 103, 121)",
  "rgb(67, 80, 107)",
  "rgb(89, 97, 115)"
]
export const palette: Array<Color> = [
  "#00475C",
  "#9CA79D",
  "rgb(0, 71, 92)",
  "rgb(0, 108, 141)",
  "rgb(0, 54, 70)",
  "#2C2A26",
  "#00475C",
  "#9CA79D",
  "rgb(0, 71, 92)",
  "rgb(0, 108, 141)",
  "rgb(0, 54, 70)",
  "#2C2A26",
  "#00475C",
  "#9CA79D",
  "rgb(0, 71, 92)",
  "rgb(0, 108, 141)",
  "rgb(0, 54, 70)",
  "#2C2A26",
]


export const okColor = "#7aea87";
export const warningColor = "#f7e87a";
export const errorColor = "#f77a7a";


export const colorBlindFriendlyPalette: Array<Color> = [
  "#E69F00", // Orange
  "#56B4E9", // Sky Blue
  "#009E73", // Bluish Green
  "#F0E442", // Yellow
  "#0072B2", // Blue
  "#D55E00", // Vermillion
  "#CC79A7", // Reddish Purple
  "#999999", // Grey
  "#6A3D9A", // Purple
  "#FFB5B8", // Light Pink
  "#F0027F", // Magenta
  "#000000", // Black
  "#D8B365", // Pale Orange
  "#5AB4AC", // Aqua
  "#1B9E77", // Greenish
  "#A6761D", // Bronze
  "#666666", // Dark Grey
  "#1D91C0", // Cerulean
  "#B2DF8A", // Pale Green
  "#B15928", // Brown
  "#66A61E", // Lime Green
];