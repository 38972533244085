import {LoggingSchema} from "@feature/logging/types";
import {MTColumnsType} from "@utils/pagination";
import { timestampStringConverter } from "@utils/pagination/filters";
import moment from "moment";

type T = LoggingSchema;

export const columns: MTColumnsType<T> = [
  {
    title: "id",
    field: "id",
    cellStyle: {minWidth: 150},
    pagination: {
      spech: {
        column_name: ["id"],
        filter_operator: "like",
        value_parser: (value) => `%${value}%`
      }
    }
  },
  {
    title: "data",
    field: "timestamp",
    type: "string",
    pagination: {
      spech: {
        column_name: ["timestamp"],
        filter_operator: "ilike",
        value_parser: (value) => timestampStringConverter(value),
      }
    },
    filterCellStyle: {minWidth: 200},
    render: (rowData) => moment(rowData.timestamp).format('DD/MM/YYYY HH:mm:ss'),
  },
  {
    title: "utente",
    field: "user_full_name",
    cellStyle: {minWidth: 150},
    pagination: {
      spech: {
        column_name: ["user_rel.full_name"],
        filter_operator: "ilike",
        value_parser: (value) => `%${value}%`
      }
    }
  },
  {
    title: "metodo",
    field: "method",
    pagination: {
      spech: {
        column_name: ["method"],
        filter_operator: "ilike",
        value_parser: (value) => `%${value}%`
      }
    }
  },
  {
    title: "rotta",
    field: "route",
    pagination: {
      spech: {
        column_name: ["route"],
        filter_operator: "ilike",
        value_parser: (value) => `%${value}%`
      }
    }
  },
  {
    title: "path params",
    field: "path_params",
    filtering: false,
    sorting: false,
    render: (rowData) =>
      rowData.path_params && JSON.stringify(rowData.path_params)
  },
  {
    title: "query params",
    field: "query_params",
    filtering: false,
    sorting: false,
    render: (rowData) =>
      rowData.query_params && JSON.stringify(rowData.query_params)
  },
  {
    title: "body",
    field: "body",
    filtering: false,
    sorting: false,
    cellStyle: {maxWidth: 300, whiteSpace: 'pre-wrap', overflowWrap: 'break-word'},
    render: (rowData) =>
      rowData.body && JSON.stringify(rowData.body)
  },
  {
    title: "status",
    field: "status_code",
    type: "string",     // Numeric
    pagination: {
      spech: {
        column_name: ["status_code"],
        filter_operator: "=",
      }
    }
  }
]