export function format_password_message(messages, intl, format = true) {
  let errors = [];
  if (messages && messages.length > 0) {
    errors = messages.map((message) =>
      intl.formatMessage({ id: `pwd_validator_${message.toLowerCase()}` })
    );
  }
  if (format) {
    return errors.join(", ");
  }
  return errors;
}
