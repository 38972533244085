import {useContext, useEffect, useState} from "react";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";
import {useAsyncError} from "@hooks/useAsyncError";
import {NotificationsContext} from "@ui-components/Notifications";
import {EltType, SelectOptionsSchema} from "@feature/commons/types";
import { GanttMachinesAndDateLimits } from "../types";

type T = {
	machines: SelectOptionsSchema[];
	date_from: string;
	date_to: string;
};

export const useFetchMachinesAndDateLimits = (
	runId: number,
	group: string,
	eltType: EltType,
) => {
	
	// context
	const {push} = useContext(NotificationsContext);
	
	// hooks
	const intl = useIntl();
	const throwError = useAsyncError();
	
	// state
	const [loading, setLoading] = useState<boolean>(false);
	const [machinesAndDateLimits, setMachinesAndDateLimits] = useState<GanttMachinesAndDateLimits>();
	
	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				const {data} = await api.get<T>(`/gantt_blocks/${runId}/${eltType}/machines_and_dates?gantt_group=${group}`);
				setMachinesAndDateLimits({
					machines: data.machines,
					dateLimits: {
						dateStart: new Date(data.date_from),
						dateEnd: new Date(data.date_to),
						firstRender: false,
					}
				});
			} catch (e) {
				push({type: 'error', title: intl.formatMessage({id: 'generic_error'})});
				throwError(e);
			} finally {
				setLoading(false);
			}
		})();
	}, [eltType, group, intl, push, runId, throwError]);
	
	return {loading, machinesAndDateLimits};
}