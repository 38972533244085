import {useContext, useEffect, useState} from "react";
import {api} from "@services/apiRequest";
import {useIntl} from "react-intl";
import {useAsyncError} from "@hooks/useAsyncError";
import {NotificationsContext} from "@ui-components/Notifications";
import {DtnErrorsSchema} from "@feature/data-entry/types";

type T = DtnErrorsSchema;
export const useFetchDtnErrors = () => {

    // context
    const {push} = useContext(NotificationsContext);

    // hooks
    const intl = useIntl();
    const throwError = useAsyncError();

    // state
    const [loading, setLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<T>();
    const [refetch, setRefetch] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const {data} = await api.get<T>('/data_entry/errors');
                setErrors(data)
            } catch (e) {
                push({type: 'error', title: intl.formatMessage({id: 'generic_error'})});
                throwError(e);
            } finally {
                setLoading(false);
            }
        })();
    }, [intl, push, throwError, refetch]);

    return {loading, errors, errorTrigger: () => setRefetch(p => !p)};
}