import React, { Fragment, useCallback, useState, useRef } from "react";
import debounce from "lodash.debounce";
import Input from "../Input";
import { useIntl, FormattedMessage } from "react-intl";
import { api } from "@services/apiRequest";
import { CancelToken } from "axios";
import { PasswordErrors } from "./PasswordErrors";

const UpdatePassword = ({
  setPassword,
  setConfirmPassword,
  password,
  confirmPassword,
  onValid,
}) => {
  const intl = useIntl();
  const [confirmError, setConfirmError] = useState("");
  const [passwordError, setPasswordError] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [isValid, setIsValid] = useState({
    password: false,
    confirmPassword: false,
  });

  const lastQuery = useRef();

  const handleValidChange = useCallback((field, valid) => {
      const updated = { ...isValid, [field]: valid };
      setIsValid(updated);
      const callback = Object.values(updated).every((v) => v === true);
      onValid(callback);
  }, [isValid, onValid]);

  const handlePassword = useCallback(async (value) => {
      if (lastQuery.current) {
        lastQuery.current();
      }
      const data = {
        password: value,
      };

      try {
      const { data: response } = await api.post("/users/password/check", data, {
            publicRequest: true,
            cancelToken: new CancelToken(function executor(c) {
              lastQuery.current = c;
            }),
      });
        lastQuery.current = null;
        if (response.errors && response.errors.length > 0) {
          setPasswordError(() => response.errors);
          handleValidChange("password", false);
          return false;
        } else {
          handleValidChange("password", true);
          setPasswordError(() => []);
        }
      } catch (e) {
      console.log(e)
        if (lastQuery.current && e.message === "canceled") {
          return;
        }
      }
      return true;
  }, [handleValidChange]);

  const handleConfirmPassword = useCallback(
    (value) => {
      if (value !== password) {
        setConfirmError(intl.formatMessage({ id: "passwords_not_matching" }));
        handleValidChange("confirmPassword", false);
        return false;
      }
      setConfirmError();
      handleValidChange("confirmPassword", true);
      return true;
    },
    [password, intl, handleValidChange]
  );

  return (
    <Fragment>
      <div>
        <Input
          value={password}
          onInput={(e) => {
            debounce(() => handlePassword(e.target.value), 100)();
            setPassword(e.target.value);
          }}
          label={intl.formatMessage({
            id: "new_password",
          })}
          type={showPassword ? "text" : "password"}
          required
          data-cy="new-password"
        />
      </div>
      <PasswordErrors errors={passwordError} />
      <div>
        <Input
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
          validation={handleConfirmPassword}
          label={intl.formatMessage({ id: "confirm_new_password" })}
          type={showPassword ? "text" : "password"}
          errorMessage={confirmError}
          required
          data-cy="confirm-password"
        />
      </div>
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <input
            id="show-passwd"
            name="show-passwd"
            type="checkbox"
            className="h-4 w-4 text-am-600 focus:ring-am-500 border-gray-300 rounded"
            onChange={(e) => {
              setShowPassword(e.target.checked);
            }}
          />
          <label
            htmlFor="show-passwd"
            className="ml-2 block text-sm text-gray-900"
          >
            <FormattedMessage id="show_password" />
          </label>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdatePassword;
